import { takeLatest, put } from 'redux-saga/effects';
import { getStudentCommentsSuccess, getStudentCommentsFail } from '../actions';
import httpClient from 'utils/http-client';
import configActionTypes from '../types';
import { Notify } from '@flexisaf/flexibull2';
import { handleError } from 'utils/utils';

export function* getStudentTraitComments({ payload }) {
  try {
    const response = yield httpClient.get(`/term-comments/eot-comments`, { params: payload });
    yield put(getStudentCommentsSuccess(response.data));
  } catch (error) {
    yield put(getStudentCommentsFail(error?.message));
    const err = handleError(error);
  }
}

export function* onGetStudentComments() {
  yield takeLatest(configActionTypes.GET_STUDENT_COMMENTS_START, getStudentTraitComments);
}
