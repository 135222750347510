const config = {
  development: {
    API_BASE_URL: 'http://safsimsapi.safsims-dev.com',
    MEASUREMENT_ID: `G-06H2L1L13D`,
    GTM_ID: `GTM-NR9P7GF`,
  },
  staging: {
    API_BASE_URL: 'https://api.safsims-stage.com',
    MEASUREMENT_ID: `G-06H2L1L13D`,
    GTM_ID: `GTM-NR9P7GF`,
  },
  production: {
    API_BASE_URL: 'https://api.production.safsims.com',
    MEASUREMENT_ID: `G-06H2L1L13D`,
    GTM_ID: `GTM-NR9P7GF`,
  },
};

export default config[process.env.REACT_APP_ENV || process.env.NODE_ENV];
