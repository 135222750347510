import gradesActionTypes from './types';

export const fetchGradeFormatsStart = () => ({
  type: gradesActionTypes.FETCH_GRADE_FORMATS_START,
});

export const fetchGradeFormatsSuccess = (data) => ({
  type: gradesActionTypes.FETCH_GRADE_FORMATS_SUCCESS,
  payload: data,
});

export const fetchGradeFormatsFail = (data) => ({
  type: gradesActionTypes.FETCH_GRADE_FORMATS_FAIL,
  payload: data,
});

export const updateGradeFormatsStart = (data, callBack) => ({
  type: gradesActionTypes.UPDATE_GRADE_FORMATS_START,
  payload: data,
  callBack,
});

export const updateGradeFormatsSuccess = (data) => ({
  type: gradesActionTypes.UPDATE_GRADE_FORMATS_SUCCESS,
  payload: data,
});

export const updateGradeFormatsFail = (data) => ({
  type: gradesActionTypes.UPDATE_GRADE_FORMATS_FAIL,
  payload: data,
});

export const addGradeFormatsStart = (data, callBack) => ({
  type: gradesActionTypes.ADD_GRADE_FORMATS_START,
  payload: data,
  callBack,
});

export const addGradeFormatsSuccess = (data) => ({
  type: gradesActionTypes.ADD_GRADE_FORMATS_SUCCESS,
  payload: data,
});

export const addGradeFormatsFail = (data) => ({
  type: gradesActionTypes.ADD_GRADE_FORMATS_FAIL,
  payload: data,
});

export const deleteGradeFormatsStart = (id) => ({
  type: gradesActionTypes.DELETE_GRADE_FORMATS_START,
  payload: id,
});

export const deleteGradeFormatsSuccess = (data) => ({
  type: gradesActionTypes.DELETE_GRADE_FORMATS_SUCCESS,
  payload: data,
});

export const deleteGradeFormatsFail = (error) => ({
  type: gradesActionTypes.DELETE_GRADE_FORMATS_FAIL,
  payload: error,
});

export const handleGradeFormatsModal = (payload) => ({
  type: gradesActionTypes.HANDLE_GRADE_FORMATS_MODAL,
  payload,
});

export const handleDeleteGradeFormatsModal = (payload) => ({
  type: gradesActionTypes.HANDLE_DELETE_GRADE_FORMAT_MODAL,
  payload,
});
