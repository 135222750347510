import { put, takeLatest } from 'redux-saga/effects';
import userActionTypes from '../types';
import { Notify } from '@flexisaf/flexibull2';
import { createProfileEditFailure, createProfileEditSuccess } from '../actions';
import httpClient from '../../../utils/http-client';
import { handleError } from 'utils/utils';

export function* createRequest({ payload }) {
  try {
    const response = yield httpClient.post('/staff/profile-edit', payload);
    yield put(createProfileEditSuccess(response.data));
    Notify('Your profile changes was submitted successfully.', { position: 'top-right' });
  } catch (error) {
    yield put(createProfileEditFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onCreateEditRequest() {
  yield takeLatest(userActionTypes.CREATE_EDIT_REQUEST_START, createRequest);
}
