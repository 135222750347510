import { takeLatest, put } from 'redux-saga/effects';
import { saveStudentTraitAssessmentSuccess, saveStudentTraitAssessmentFail } from '../actions';
import httpClient from 'utils/http-client';
import configActionTypes from '../types';
import { Notify } from '@flexisaf/flexibull2';
import { handleError } from 'utils/utils';

export function* saveStudentTraitAssessment({ payload }) {
  const { data, callback } = payload;
  try {
    const response = yield httpClient.post(`/student-trait-assessments`, data);
    yield put(saveStudentTraitAssessmentSuccess(response.data));
    callback();
    Notify('Student Trait Assessments saved succesfully', { position: 'top-right' });
  } catch (error) {
    yield put(saveStudentTraitAssessmentFail(error?.message));
    const err = handleError(error);
  }
}

export function* onSaveStudentTraitAssessment() {
  yield takeLatest(
    configActionTypes.SAVE_STUDENT_TRAIT_ASSESSMENT_START,
    saveStudentTraitAssessment,
  );
}
