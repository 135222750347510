import { put, takeLatest } from 'redux-saga/effects';
import assessmentActionTypes from '../types';
import { createAssessmentSuccess, createAssessmentFailure } from '../actions';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';
import { Notify } from '@flexisaf/flexibull2';
import { setDisableNext } from 'redux/users/actions';

export function* createAssessment({ payload }) {
  try {
    let callback = () => {};
    if (payload.hasOwnProperty('callback')) {
      callback = payload.callback;
      delete payload.callback;
    }
    const response = yield httpClient.post('assessment-config', payload);
    yield put(createAssessmentSuccess(response.data));
    Notify('Assessment created successfully', { position: 'top-right' });
    callback();
  } catch (error) {
    yield put(createAssessmentFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onCreateAssessmentStart() {
  yield takeLatest(assessmentActionTypes.CREATE_ASSESSMENT_START, createAssessment);
}
