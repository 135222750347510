import { persistReducer } from 'redux-persist';
import storage from 'localforage';
import earlyYearsActionTypes from './types';
import { findIndexInArray } from 'utils/utils';
import { FIX_ANY } from 'utils/types';

export interface IEarlyYears {
  buttonLoader: boolean;
  addModal: boolean;
  error: any;
  groupOfTraits: FIX_ANY[];
  dataLoader: boolean;
  saved: boolean;
  ratingModal: boolean;
  allRatings: FIX_ANY[];
  edit: boolean;
  deleteRatingModal: boolean;
  editButton: boolean;
  deleteLoader: boolean;
  idToDelete: string | null;
}

const INITIAL_STATE: IEarlyYears = {
  buttonLoader: false,
  addModal: false,
  error: null,
  groupOfTraits: [],
  dataLoader: false,
  saved: false,
  ratingModal: false,
  allRatings: [],
  edit: false,
  deleteRatingModal: false,
  editButton: false,
  deleteLoader: false,
  idToDelete: null,
};

interface actionParams {
  type: earlyYearsActionTypes;
  payload: any;
}

function earlyYearsReducer(state = INITIAL_STATE, { type, payload }: actionParams) {
  switch (type) {
    case earlyYearsActionTypes.ADD_TRAIT_GROUP_START:
      return {
        ...state,
        buttonLoader: true,
      };
    case earlyYearsActionTypes.ADD_TRAIT_GROUP_SUCCESS:
      return {
        ...state,
        buttonLoader: false,
        addModal: false,
      };
    case earlyYearsActionTypes.ADD_TRAIT_GROUP_FAIL:
      return {
        ...state,
        buttonLoader: false,
        error: payload,
      };
    case earlyYearsActionTypes.FETCH_TRAIT_GROUP_START:
      return {
        ...state,
        dataLoader: true,
      };
    case earlyYearsActionTypes.FETCH_TRAIT_GROUP_SUCCESS:
      return {
        ...state,
        groupOfTraits: payload,
        dataLoader: false,
      };
    case earlyYearsActionTypes.FETCH_TRAIT_GROUP_FAIL:
      return {
        ...state,
        error: payload,
        dataLoader: false,
      };
    case earlyYearsActionTypes.ADD_SINGLE_TRAIT_START:
      return {
        ...state,
        buttonLoader: true,
      };
    case earlyYearsActionTypes.ADD_SINGLE_TRAIT_SUCCESS:
      return {
        ...state,
        buttonLoader: false,
      };
    case earlyYearsActionTypes.ADD_SINGLE_TRAIT_FAIL:
      return {
        ...state,
        buttonLoader: false,
        error: payload,
      };
    case earlyYearsActionTypes.EDIT_SINGLE_TRAIT_START:
      return {
        ...state,
        buttonLoader: true,
      };
    case earlyYearsActionTypes.EDIT_SINGLE_TRAIT_SUCCESS:
      return {
        ...state,
        buttonLoader: false,
      };
    case earlyYearsActionTypes.EDIT_SINGLE_TRAIT_FAIL:
      return {
        ...state,
        buttonLoader: false,
        error: payload,
      };
    case earlyYearsActionTypes.DELETE_SINGLE_TRAIT_START:
      return {
        ...state,
        buttonLoader: true,
      };
    case earlyYearsActionTypes.DELETE_SINGLE_TRAIT_SUCCESS:
      return {
        ...state,
        buttonLoader: false,
      };
    case earlyYearsActionTypes.DELETE_SINGLE_TRAIT_FAIL:
      return {
        ...state,
        buttonLoader: false,
        error: payload,
      };
    case earlyYearsActionTypes.HANDLE_ADD_TRAIT_MODAL:
      return {
        ...state,
        addModal: payload,
      };
    case earlyYearsActionTypes.DELETE_SINGLE_DEFINITION_START:
      return {
        ...state,
        buttonLoader: true,
      };
    case earlyYearsActionTypes.DELETE_SINGLE_DEFINITION_SUCCESS:
      return {
        ...state,
        buttonLoader: false,
      };
    case earlyYearsActionTypes.DELETE_SINGLE_DEFINITION_FAIL:
      return {
        ...state,
        buttonLoader: false,
        error: payload,
      };
    case earlyYearsActionTypes.EDIT_SINGLE_DEFINITION_START:
      return {
        ...state,
        buttonLoader: true,
      };
    case earlyYearsActionTypes.EDIT_SINGLE_DEFINITION_SUCCESS:
      return {
        ...state,
        buttonLoader: false,
      };
    case earlyYearsActionTypes.EDIT_SINGLE_DEFINITION_FAIL:
      return {
        ...state,
        buttonLoader: false,
        error: payload,
      };
    case earlyYearsActionTypes.ADD_SINGLE_DEFINITION_START:
      return {
        ...state,
        buttonLoader: true,
      };
    case earlyYearsActionTypes.ADD_SINGLE_DEFINITION_SUCCESS:
      return {
        ...state,
        buttonLoader: false,
      };
    case earlyYearsActionTypes.ADD_SINGLE_DEFINITION_FAIL:
      return {
        ...state,
        buttonLoader: false,
        error: payload,
      };
    case earlyYearsActionTypes.ADD_RATING_START:
      return {
        ...state,
        buttonLoader: true,
      };
    case earlyYearsActionTypes.ADD_RATING_SUCCESS:
      state.allRatings.push(payload);
      return {
        ...state,
        buttonLoader: false,
        ratingModal: false,
      };
    case earlyYearsActionTypes.ADD_RATING_FAIL:
      return {
        ...state,
        buttonLoader: false,
        error: payload,
        ratingModal: true,
      };
    case earlyYearsActionTypes.HANDLE_ADD_RATING_MODAL:
      return {
        ...state,
        ratingModal: payload,
      };
    case earlyYearsActionTypes.HANDLE_DELETE_RATING_MODAL:
      return {
        ...state,
        deleteRatingModal: payload,
      };
    case earlyYearsActionTypes.HANDLE_EDIT_RATING_MODAL:
      return {
        ...state,
        edit: payload,
      };
    case earlyYearsActionTypes.DELETE_RATING_START:
      return {
        ...state,
        buttonLoader: true,
        idToDelete: payload.id,
      };
    case earlyYearsActionTypes.DELETE_RATING_SUCCESS:
      const filteredData = state.allRatings.filter((data: FIX_ANY) => {
        return data.trait_rating.id === state.idToDelete;
      })[0];
      const index = findIndexInArray(state.allRatings, filteredData, 'trait_rating');
      state.allRatings.splice(index, 1);
      return {
        ...state,
        buttonLoader: false,
        deleteRatingModal: false,
        idToDelete: null,
      };
    case earlyYearsActionTypes.DELETE_RATING_FAIL:
      return {
        ...state,
        buttonLoader: false,
        error: payload,
        deleteRatingModal: true,
      };
    case earlyYearsActionTypes.EDIT_RATING_START:
      return {
        ...state,
        buttonLoader: true,
      };
    case earlyYearsActionTypes.EDIT_RATING_SUCCESS:
      return {
        ...state,
        buttonLoader: false,
      };
    case earlyYearsActionTypes.EDIT_RATING_FAIL:
      return {
        ...state,
        buttonLoader: false,
        error: payload,
      };

    case earlyYearsActionTypes.FETCH_RATING_START:
      return {
        ...state,
        dataLoader: true,
      };
    case earlyYearsActionTypes.FETCH_RATING_SUCCESS:
      return {
        ...state,
        dataLoader: false,
        allRatings: payload,
      };
    case earlyYearsActionTypes.FETCH_RATING_FAIL:
      return {
        ...state,
        dataLoader: false,
        error: payload,
      };
    case earlyYearsActionTypes.EDIT_SINGLE_RATE_START:
      return {
        ...state,
        editButton: true,
      };
    case earlyYearsActionTypes.EDIT_SINGLE_RATE_SUCCESS:
      return {
        ...state,
        editButton: false,
      };
    case earlyYearsActionTypes.EDIT_SINGLE_RATE_FAIL:
      return {
        ...state,
        editButton: false,
      };
    case earlyYearsActionTypes.DELETE_SINGLE_RATING_START:
      return {
        ...state,
        deleteLoader: true,
      };
    case earlyYearsActionTypes.DELETE_SINGLE_RATING_SUCCESS:
      return {
        ...state,
        deleteLoader: false,
      };
    case earlyYearsActionTypes.DELETE_SINGLE_RATING_FAIL:
      return {
        ...state,
        deleteLoader: false,
      };

    default:
      return state;
  }
}

const persistConfig = {
  key: 'earlyYears',
  storage,
  blacklist: [
    'buttonLoader',
    'error',
    'addModal',
    'dataLoader',
    'saved',
    'ratingModal',
    'deleteRatingModal',
    'edit',
    'editButton',
    'deleteLoader',
    'idToDelete',
  ],
};

export default persistReducer(persistConfig, earlyYearsReducer);
