import { put, takeLatest } from 'redux-saga/effects';
import assessmentActionTypes from '../types';
import { fetchAssessmentsSuccess, fetchAssessmentsFailure } from '../actions';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';

export function* fetchAssessment({ payload }) {
  try {
    const response = yield httpClient.get('/assessment-config', { params: payload });
    yield put(fetchAssessmentsSuccess(response.data));
  } catch (error) {
    yield put(fetchAssessmentsFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onfetchAssessmentStart() {
  yield takeLatest(assessmentActionTypes.FETCH_LIST_OF_ASSESSMENTS_START, fetchAssessment);
}
