import { put, takeLatest } from 'redux-saga/effects';
import staffActionTypes from '../types';
import { Notify } from '@flexisaf/flexibull2';
import { editStaffFailure, editStaffSuccess } from '../actions';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';
import { editCurrentUserDetails } from 'redux/users/actions';

export function* editStaff({ payload }) {
  const isCurrentUser = payload.isCurrentUser;
  delete payload.isCurrentUser;

  try {
    const response = yield httpClient.put('/staff', payload);
    yield put(editStaffSuccess(response.data));
    Notify('Staff Details Edited successfully', { position: 'top-right' });
    if (isCurrentUser) {
      yield put(editCurrentUserDetails(payload));
    }
  } catch (error) {
    yield put(editStaffFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onEditStaffStart() {
  yield takeLatest(staffActionTypes.EDIT_STAFF_START, editStaff);
}
