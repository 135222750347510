import { Notify } from '@flexisaf/flexibull2';
import { AuthenticationRestControllerService } from 'generated';
import localforage from 'localforage';
import { put, takeLatest } from 'redux-saga/effects';
import { setSiteConfig } from 'redux/global-store/actions';
import { setSchoolAdmin, setSchoolDirector, setSuperAdmin } from 'redux/privileges/actions';
import { school_id } from 'utils/constants';
import { hotjarAttribute } from 'utils/Hotjar';
import { ADMIN_ROLE, DIRECTOR_ROLE, handleError, ONBOARDING_ROLE } from 'utils/utils';
import httpClient, { apiWrapper } from '../../../utils/http-client';
import {
  handleUserTypeModal,
  setAccessToken,
  setActiveUserType,
  setCurrentStaffItems,
  setLoginAttempts,
  setRefreshToken,
  setUserTypes,
  updateAppUserState,
  userLoginFailure,
  userLoginSuccess,
} from '../actions';
import userActionTypes from '../types';

export function* userLogIn({ payload }) {
  const config = payload.config;
  const callBack = payload.callBack;
  const history = payload.history;
  const checkOnboardingStatus = payload.checkOnboardingStatus;
  const loginAttempts = payload.loginAttempts;
  const { username } = payload;
  delete payload.config;
  delete payload.callBack;
  delete payload.history;
  delete payload.checkOnboardingStatus;
  delete payload.loginAttempts;

  try {
    const response = yield httpClient.post('/auth/login', payload);
    const ref = response.data;
    const ums = ref.ums_login_response;
    const roles = ums.user.roles;
    const isSuperAdmin = !!roles.find((item) => item.role.name === ONBOARDING_ROLE);
    const isAdmin = !!roles.find((item) => item.role.name === ADMIN_ROLE);
    const isDirector = !!roles.find((item) => item.role.name === DIRECTOR_ROLE);

    yield put(setSuperAdmin(isSuperAdmin));
    yield put(setSchoolDirector(isDirector));
    yield put(setSchoolAdmin(isAdmin));

    let userTypes = [];
    ref.user_types.map((type) => {
      let str = type.toLowerCase();
      userTypes.push({ user_type: str, ...ref[str] });
    });

    callBack(roles);
    yield put(setAccessToken(ums.access_token));
    yield put(setRefreshToken(ums.refresh_token));

    yield localforage.setItem('access_token', ums.access_token);
    yield localforage.setItem('refresh_token', ums.refresh_token);
    httpClient.defaults.headers.common.Authorization = `Bearer ${ums.access_token}`;

    yield put(setSiteConfig({ ...config, accessToken: ums.access_token }));
    yield put(setUserTypes(userTypes));

    if (ref.user_types.find((x) => x.toLowerCase() === 'staff')) {
      const form_teachers = ref.form_teachers || [];
      const subject_teachers = ref.subject_teachers || [];

      const currentStaffClassView = [
        ...form_teachers.map((x) => ({
          class_level: x.class_level.id,
          arm: x.arm.id,
        })),
        ...subject_teachers.map((x) => ({
          class_level: x.class_information.class_level.id,
          arm: x.class_information.arm.id,
        })),
      ];
      yield put(
        setCurrentStaffItems({
          currentStaffClasses: form_teachers,
          currentStaffSubjects: subject_teachers,
          currentStaffClassView,
        }),
      );
    }
    if (userTypes.length < 2) {
      yield put(userLoginSuccess(userTypes[0]));
      yield put(setActiveUserType(userTypes[0].user_type));
      yield put(setLoginAttempts(0));
      if (isSuperAdmin && userTypes[0].user_type.toLowerCase() === 'staff') {
        checkOnboardingStatus();
      } else {
        history(isDirector ? '/director-dashboard' : '/user-profile');
      }
    } else {
      yield put(handleUserTypeModal(true));
    }

    // HotJar User Attribute taging
    hotjarAttribute({
      userId: ums.user.username,
      attributeParams: {
        login_user: `${ums.user.first_name} ${ums.user.last_name}`,
        login_organisation: ums.user.organisation,
        login_date: new Date().toISOString(),
      },
    });

    // GTM data taging.
    window.dataLayer.push({
      event: 'login',
      username: ums.user.username,
      organisation: ums.user.organisation,
      login_date: new Date().toISOString(),
      user_role: ums.user.roles.map((item) => item.role.name),
    });
  } catch (error) {
    yield put(userLoginFailure(error?.message));
    const err = handleError(error, { code: 403, message: 'Account not verified' });

    if (err?.statusCode === 403) {
      try {
        yield apiWrapper(() =>
          AuthenticationRestControllerService.resendVerificationUsingPost({
            request: { username, verification_type: 'PINCODE' },
            xTenantId: school_id,
          }),
        );
      } catch (err) {}
      yield put(
        updateAppUserState({
          accountVerificationModalOpen: true,
        }),
      );
    } else if (err?.statusCode === 401) {
      Notify(err?.message, { status: 'error' });
    }
    yield put(setLoginAttempts(loginAttempts + 1));
  }
}

export function* onSigninStart() {
  yield takeLatest(userActionTypes.USER_LOGIN_START, userLogIn);
}
