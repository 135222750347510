import storage from 'localforage';
import { persistReducer } from 'redux-persist';
import { FIX_ANY } from 'utils/types';
import broadSheetTypes from './types';

export interface IBroadsheetState {
  loader: boolean;
  armBroadSheet: FIX_ANY;
  cumulativeArmBroadsheet: FIX_ANY;
  cumulativeLevelBroadsheet: FIX_ANY;
  cumulativeArmLoader: FIX_ANY;
  cumulativeLevelLoader: FIX_ANY;
  error: boolean;
  levelBroadsheet: FIX_ANY;
  levelSheetLoader: boolean;
  buttonLoader: boolean;
  reportTemplate: FIX_ANY;
  studentData: FIX_ANY;
  listOfstudentsData: FIX_ANY;
}

const INITIAL_STATE: IBroadsheetState = {
  loader: false,
  armBroadSheet: {},
  error: false,
  levelBroadsheet: {},
  levelSheetLoader: false,
  buttonLoader: false,
  cumulativeArmBroadsheet: {},
  cumulativeLevelBroadsheet: {},
  cumulativeArmLoader: false,
  cumulativeLevelLoader: false,
  reportTemplate: [
    {
      template_data: {
        template: '',
        tags: {
          text: [],
          image: [],
          table: [],
        },
      },
    },
  ],
  studentData: {
    term_comments: [],
    school_information: {},
    term_result: {
      group_assessment_definition: {
        assessment_definitions: [],
      },
    },
    skill_assessments: [],
    term: {},
    attendance: {},
    student: {},
    class_information: {},
    student_trait_assessments: [],
    grades: [],
    skill_rating_definitions: [],
  },
  listOfstudentsData: [],
};

interface Params {
  type: broadSheetTypes;
  payload: any;
}

function broadSheetReducer(state = INITIAL_STATE, { type, payload }: Params) {
  switch (type) {
    case broadSheetTypes.FETCH_CLASS_ARM_BROADSHEET_START:
      return {
        ...state,
        loader: true,
        armBroadSheet: {},
      };
    case broadSheetTypes.FETCH_CLASS_ARM_BROADSHEET_SUCCESS:
      return {
        ...state,
        loader: false,
        armBroadSheet: payload,
        reportTemplate: payload.report_templates,
      };
    case broadSheetTypes.FETCH_CLASS_ARM_BROADSHEET_FAIL:
      return {
        ...state,
        loader: false,
        error: payload,
        reportTemplate: [],
      };
    case broadSheetTypes.SET_DEFAULT_TEMPLATE_CLASS_START:
      return {
        ...state,
        loader: true,
      };
    case broadSheetTypes.SET_DEFAULT_TEMPLATE_CLASS_SUCCESS:
      return {
        ...state,
        loader: false,
        reportTemplate: payload.from
          ? [{ template_data: payload.data.template_data }]
          : [payload.data],
      };
    case broadSheetTypes.SET_DEFAULT_TEMPLATE_CLASS_FAIL:
      return {
        ...state,
        loader: false,
        error: payload,
        reportTemplate: [],
      };
    case broadSheetTypes.FETCH_CLASS_LEVEL_BROADSHEET_START:
      return {
        ...state,
        levelSheetLoader: true,
        levelBroadsheet: {},
      };
    case broadSheetTypes.FETCH_CLASS_LEVEL_BROADSHEET_SUCCESS:
      return {
        ...state,
        levelSheetLoader: false,
        levelBroadsheet: payload,
        reportTemplate: payload.report_templates,
      };
    case broadSheetTypes.FETCH_CLASS_LEVEL_BROADSHEET_FAIL:
      return {
        ...state,
        levelSheetLoader: false,
        error: payload,
      };
    case broadSheetTypes.SINGLE_STUDENT_RESULT_APPROVAL_START:
      return {
        ...state,
        buttonLoader: true,
      };
    case broadSheetTypes.SINGLE_STUDENT_RESULT_APPROVAL_SUCCESS:
      return {
        ...state,
        buttonLoader: false,
      };
    case broadSheetTypes.SINGLE_STUDENT_RESULT_APPROVAL_FAIL:
      return {
        ...state,
        buttonLoader: false,
      };

    case broadSheetTypes.FETCH_CUMMULATIVE_ARM_BROADSHEET_START:
      return {
        ...state,
        cumulativeArmLoader: true,
      };
    case broadSheetTypes.FETCH_CUMMULATIVE_ARM_BROADSHEET_SUCCESS:
      return {
        ...state,
        cumulativeArmLoader: false,
        cumulativeArmBroadsheet: payload,
      };
    case broadSheetTypes.FETCH_CUMMULATIVE_ARM_BROADSHEET_FAIL:
      return {
        ...state,
        cumulativeArmLoader: false,
        error: payload,
        cumulativeArmBroadsheet: {},
      };
    case broadSheetTypes.FETCH_CUMULATIVE_LEVEL_BROADSHEET_START:
      return {
        ...state,
        cumulativeLevelLoader: true,
      };
    case broadSheetTypes.FETCH_CUMULATIVE_LEVEL_BROADSHEET_SUCCESS:
      return {
        ...state,
        cumulativeLevelLoader: false,
        cumulativeLevelBroadsheet: payload,
      };
    case broadSheetTypes.FETCH_CUMULATIVE_LEVEL_BROADSHEET_FAIL:
      return {
        ...state,
        cumulativeLevelLoader: false,
        error: payload,
        cumulativeLevelBroadsheet: {},
      };
    case broadSheetTypes.FETCH_STUDENT_RESULT_DATA_START:
      return {
        ...state,
        loader: true,
      };
    case broadSheetTypes.FETCH_STUDENT_RESULT_DATA_SUCCESS:
      return {
        ...state,
        loader: false,
        studentData: payload,
      };
    case broadSheetTypes.FETCH_STUDENT_RESULT_DATA_FAIL:
      return {
        ...state,
        loader: false,
      };
    case broadSheetTypes.FETCH_BULK_STUDENTS_RESULT_DATA_START:
      return {
        ...state,
        loader: true,
      };
    case broadSheetTypes.FETCH_BULK_STUDENTS_RESULT_DATA_SUCCESS:
      return {
        ...state,
        loader: false,
        listOfstudentsData: payload,
      };
    case broadSheetTypes.FETCH_BULK_STUDENTS_RESULT_DATA_FAIL:
      return {
        ...state,
        loader: false,
      };
    default:
      return state;
  }
}

const persistConfig = {
  key: 'broadsheet',
  storage,
  blacklist: ['error', 'loader', 'studentData', 'listOfstudentsData'],
};

export default persistReducer(persistConfig, broadSheetReducer);
