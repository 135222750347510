import { takeLatest, put } from 'redux-saga/effects';
import { getStudentTraitAssessmentSuccess, getStudentTraitAssessmentFail } from '../actions';
import httpClient from 'utils/http-client';
import configActionTypes from '../types';
import { Notify } from '@flexisaf/flexibull2';
import { handleError } from 'utils/utils';

export function* getStudentTraitAssessment({ payload }) {
  try {
    const response = yield httpClient.get(`/student-trait-assessments`, { params: payload });
    yield put(getStudentTraitAssessmentSuccess(response.data, payload.traitId));
  } catch (error) {
    yield put(getStudentTraitAssessmentFail(error?.message));
    const err = handleError(error);
  }
}

export function* onGetStudentTraitAssessment() {
  yield takeLatest(configActionTypes.GET_STUDENT_TRAIT_ASSESSMENT_START, getStudentTraitAssessment);
}
