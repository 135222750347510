import configActionTypes from './types';

export const manageSchoolInfoStart = (data) => ({
  type: configActionTypes.MANAGE_SCHOOL_INFO_START,
  payload: data,
});

export const manageSchoolInfoSuccess = (data) => ({
  type: configActionTypes.MANAGE_SCHOOL_INFO_SUCCESS,
  payload: data,
});

export const manageSchoolInfoFail = (error) => ({
  type: configActionTypes.MANAGE_SCHOOL_INFO_FAIL,
  payload: error,
});

export const createClassLevelStart = (data) => ({
  type: configActionTypes.CREATE_CLASS_LEVEL_START,
  payload: data,
});

export const createClassLevelSuccess = (data) => ({
  type: configActionTypes.CREATE_CLASS_LEVEL_SUCCESS,
  payload: data,
});

export const createClassLevelFail = (error) => ({
  type: configActionTypes.CREATE_CLASS_LEVEL_FAIL,
  payload: error,
});

export const fetchClassLevelStart = (data) => ({
  type: configActionTypes.FETCH_CLASS_LEVEL_START,
  payload: data,
});

export const fetchClassLevelSuccess = (data) => ({
  type: configActionTypes.FETCH_CLASS_LEVEL_SUCCESS,
  payload: data,
});

export const fetchClassLevelFail = (error) => ({
  type: configActionTypes.FETCH_CLASS_LEVEL_FAIL,
  payload: error,
});

export const handleCreateClassLevelAction = (createModal) => ({
  type: configActionTypes.HANDLE_CREATE_CLASS_LEVEL_MODAL,
  payload: createModal,
});

export const handleDeleteClassLevelAction = (deleteModal) => ({
  type: configActionTypes.HANDLE_DELETE_CLASS_LEVEL_MODAL,
  payload: deleteModal,
});

export const handleDeactivateClassLevelModal = (deactivateModal) => ({
  type: configActionTypes.HANDLE_DEACTIVATE_CLASS_LEVEL_MODAL,
  payload: deactivateModal,
});

export const deleteClassLevelStart = (id, isProfile, callback) => ({
  type: configActionTypes.DELETE_CLASS_LEVEL_START,
  payload: { id, isProfile, callback },
});

export const deleteClassLevelSuccess = (data) => ({
  type: configActionTypes.DELETE_CLASS_LEVEL_SUCCESS,
  payload: data,
});

export const deleteClassLevelFail = (error) => ({
  type: configActionTypes.DELETE_CLASS_LEVEL_FAIL,
  payload: error,
});

export const editClassLevelStart = (data) => ({
  type: configActionTypes.EDIT_CLASS_LEVEL_START,
  payload: data,
});

export const editClassLevelSuccess = (data) => ({
  type: configActionTypes.EDIT_CLASS_LEVEL_SUCCESS,
  payload: data,
});

export const editClassLevelFail = (error) => ({
  type: configActionTypes.EDIT_CLASS_LEVEL_FAIL,
  payload: error,
});

export const handleClassLevelDataAction = (classLevelData) => ({
  type: configActionTypes.HANDLE_CLASS_LEVEL_DATA,
  payload: classLevelData,
});

export const handleEditLevelAction = (editData) => ({
  type: configActionTypes.HANDLE_EDIT_CLASS_LEVEL,
  payload: editData,
});

export const resetModalAction = () => ({
  type: configActionTypes.RESET_MODAL,
  payload: {},
});

export const createArmStart = (data) => ({
  type: configActionTypes.CREATE_ARM_START,
  payload: data,
});

export const createArmSuccess = (data) => ({
  type: configActionTypes.CREATE_ARM_SUCCESS,
  payload: data,
});

export const createArmFail = (error) => ({
  type: configActionTypes.CREATE_ARM_FAIL,
  payload: error,
});

export const fetchAllArmsStart = (data) => ({
  type: configActionTypes.FETCH_ALL_ARMS_START,
  payload: data,
});

export const fetchAllArmsSuccess = (data) => ({
  type: configActionTypes.FETCH_ALL_ARMS_SUCCESS,
  payload: data,
});

export const fetchAllArmsFail = (error) => ({
  type: configActionTypes.FETCH_ALL_ARMS_FAIL,
  payload: error,
});

export const deleteArmStart = (id) => ({
  type: configActionTypes.DELETE_ARM_START,
  payload: id,
});

export const deleteArmSuccess = (data) => ({
  type: configActionTypes.DELETE_ARM_SUCCESS,
  payload: data,
});

export const deleteArmFail = (error) => ({
  type: configActionTypes.DELETE_ARM_FAIL,
  payload: error,
});

export const editArmStart = (data) => ({
  type: configActionTypes.EDIT_ARM_START,
  payload: data,
});

export const editArmSuccess = (data) => ({
  type: configActionTypes.EDIT_ARM_SUCCESS,
  payload: data,
});

export const editArmFail = (error) => ({
  type: configActionTypes.EDIT_ARM_FAIL,
  payload: error,
});

export const handleCreateArmAction = (modal) => ({
  type: configActionTypes.HANDLE_CREATE_ARM_MODAL,
  payload: modal,
});

export const handleDeleteArmModalAction = (deleteArmModal) => ({
  type: configActionTypes.HANDLE_DELETE_ARM_MODAL,
  payload: deleteArmModal,
});

export const handleArmDataAction = (classArmData) => ({
  type: configActionTypes.HANDLE_ARM_DATA,
  payload: classArmData,
});

export const handleEditArmAction = (classArmData) => ({
  type: configActionTypes.HANDLE_EDIT_ARM,
  payload: classArmData,
});

export const fetchSchoolInfoStart = (id) => ({
  type: configActionTypes.FETCH_SCHOOL_INFO_START,
  payload: id,
});

export const fetchSchoolInfoSuccess = (data) => ({
  type: configActionTypes.FETCH_SCHOOL_INFO_SUCCESS,
  payload: data,
});

export const fetchSchoolInfoFail = (error) => ({
  type: configActionTypes.FETCH_SCHOOL_INFO_FAIL,
  payload: error,
});

export const fetchClassInfoStart = (termId, sessionId, classLevelId, data) => ({
  type: configActionTypes.FETCH_CLASS_INFO_START,
  payload: { termId, sessionId, classLevelId, data },
});

export const fetchClassInfoSuccess = (data) => ({
  type: configActionTypes.FETCH_CLASS_INFO_SUCCESS,
  payload: data,
});

export const fetchClassInfoFail = (error) => ({
  type: configActionTypes.FETCH_CLASS_INFO_FAIL,
  payload: error,
});

export const fetchPayableItemsStart = (payload) => ({
  type: configActionTypes.FETCH_PAYABLE_ITEMS_START,
  payload,
});

export const fetchPayableItemsSuccess = (items) => ({
  type: configActionTypes.FETCH_PAYABLE_ITEMS_SUCCESS,
  payload: items,
});

export const fetchPayableItemsFail = (error) => ({
  type: configActionTypes.FETCH_PAYABLE_ITEMS_FAIL,
  payload: error,
});

export const addPayableItemStart = (payload) => ({
  type: configActionTypes.ADD_PAYABLE_ITEM_START,
  payload,
});

export const addPayableItemSuccess = (item) => ({
  type: configActionTypes.ADD_PAYABLE_ITEM_SUCCESS,
  payload: item,
});

export const addPayableItemFail = (error) => ({
  type: configActionTypes.ADD_PAYABLE_ITEM_FAIL,
  payload: error,
});

export const filterPayableItemsStart = (payload) => ({
  type: configActionTypes.FILTER_PAYABLE_ITEMS_START,
  payload,
});

export const filterPayableItemsSuccess = (items) => ({
  type: configActionTypes.FILTER_PAYABLE_ITEMS_SUCCESS,
  payload: items,
});

export const filterPayableItemsFail = (error) => ({
  type: configActionTypes.FILTER_PAYABLE_ITEMS_FAIL,
  payload: error,
});

export const handlePayableItem = (item) => ({
  type: configActionTypes.HANDLE_PAYABLE_ITEM,
  payload: item,
});

export const handlePayableItemModal = (item) => ({
  type: configActionTypes.HANDLE_PAYABLE_ITEM_MODAL,
  payload: item,
});

export const handleDeletePayableItem = (payload) => ({
  type: configActionTypes.HANDLE_DELETE_PAYABLE_ITEM,
  payload,
});

export const deletePayableItemStart = (id) => ({
  type: configActionTypes.DELETE_PAYABLE_ITEM_START,
  payload: id,
});

export const deletePayableItemSuccess = (payload) => ({
  type: configActionTypes.DELETE_PAYABLE_ITEM_SUCCESS,
  payload,
});

export const deletePayableItemFail = (error) => ({
  type: configActionTypes.DELETE_PAYABLE_ITEM_FAIL,
  payload: error,
});

export const editPayableItemStart = (payload) => ({
  type: configActionTypes.EDIT_PAYABLE_ITEM_START,
  payload,
});

export const editPayableItemSuccess = (payload) => ({
  type: configActionTypes.EDIT_PAYABLE_ITEM_SUCCESS,
  payload,
});

export const editPayableItemFail = (error) => ({
  type: configActionTypes.EDIT_PAYABLE_ITEM_FAIL,
  payload: error,
});
export const getTermStart = (data) => ({
  type: configActionTypes.GET_TERM_START,
  payload: data,
});

export const getTermSuccess = (data) => ({
  type: configActionTypes.GET_TERM_SUCCESS,
  payload: data,
});

export const getTermFail = (error) => ({
  type: configActionTypes.GET_TERM_FAIL,
  payload: error,
});

export const getClassMemberStart = (classLevelId, armId, sessionId, termId) => ({
  type: configActionTypes.GET_CLASS_MEMBERS_START,
  payload: { classLevelId, armId, sessionId, termId },
});

export const getClassMemberSuccess = (data) => ({
  type: configActionTypes.GET_CLASS_MEMBERS_SUCCESS,
  payload: data,
});

export const getClassMemberFail = (error) => ({
  type: configActionTypes.GET_CLASS_MEMBERS_FAIL,
  payload: error,
});

export const assignStudentsStart = (data) => ({
  type: configActionTypes.ASSIGN_STUDENTS_TO_CLASS_START,
  payload: data,
});

export const assignStudentsSuccess = (data) => ({
  type: configActionTypes.ASSIGN_STUDENTS_TO_CLASS_SUCCESS,
  payload: data,
});

export const assignStudentsFail = (data) => ({
  type: configActionTypes.ASSIGN_STUDENTS_TO_CLASS_FAIL,
  payload: data,
});

export const handleAssignStudentModalAction = (studentModal) => ({
  type: configActionTypes.HANDLE_ADD_STUDENT_MODAL,
  payload: studentModal,
});
export const getSessionStart = (data) => ({
  type: configActionTypes.GET_SESSION_START,
  payload: data,
});

export const getSessionSuccess = (data) => ({
  type: configActionTypes.GET_SESSION_SUCCESS,
  payload: data,
});

export const getSessionFail = (error) => ({
  type: configActionTypes.GET_SESSION_FAIL,
  payload: error,
});

export const removeStudentFromClassStart = ({
  id,
  classLevelId,
  armId,
  sessionId,
  termId,
  refetchClassMembers,
}) => ({
  type: configActionTypes.REMOVE_STUDENT_FROM_CLASS_START,
  payload: { id, classLevelId, armId, sessionId, termId, refetchClassMembers },
});

export const removeStudentFromClassSuccess = (data) => ({
  type: configActionTypes.REMOVE_STUDENT_FROM_CLASS_SUCCESS,
  payload: data,
});

export const removeStudentFromClassFail = (error) => ({
  type: configActionTypes.REMOVE_STUDENT_FROM_CLASS_FAIL,
  payload: error,
});

export const handleRemoveStudentModalAction = (removeStudentModal) => ({
  type: configActionTypes.HANDLE_REMOVE_STUDENT_MODAL,
  payload: removeStudentModal,
});

export const uploadSchoolLogoStart = (logo) => ({
  type: configActionTypes.UPLOAD_SCHOOL_LOGO_START,
  payload: logo,
});

export const uploadSchoolLogoSuccess = (data) => ({
  type: configActionTypes.UPLOAD_SCHOOL_LOGO_SUCCESS,
  payload: data,
});

export const uploadSchoolLogoFail = (error) => ({
  type: configActionTypes.UPLOAD_SCHOOL_LOGO_FAIL,
  payload: error,
});

export const viewClassProfile = (classInfo) => ({
  type: configActionTypes.VIEW_CLASS_PROFILE,
  payload: classInfo,
});

export const fetchHomePageStart = () => ({
  type: configActionTypes.FETCH_HOMEPAGE_START,
});

export const fetchHomePageSuccess = (data) => ({
  type: configActionTypes.FETCH_HOMEPAGE_SUCCESS,
  payload: data,
});

export const fetchHomePageFail = (error) => ({
  type: configActionTypes.FETCH_HOMEPAGE_FAIL,
  payload: error,
});

export const getCurrentTermStart = () => ({
  type: configActionTypes.GET_CURRENT_TERM_START,
});

export const getCurrentTermSuccess = (data) => ({
  type: configActionTypes.GET_CURRENT_TERM_SUCCESS,
  payload: data,
});

export const getCurrentTermFail = (error) => ({
  type: configActionTypes.GET_CURRENT_TERM_FAIL,
  payload: error,
});

export const fetchActiveArmsStart = () => ({
  type: configActionTypes.FETCH_ACTIVE_ARMS_START,
  payload: {},
});

export const fetchActiveArmsSuccess = (data) => ({
  type: configActionTypes.FETCH_ACTIVE_ARMS_SUCCESS,
  payload: data,
});

export const fetchActiveArmsFail = (error) => ({
  type: configActionTypes.FETCH_ACTIVE_ARMS_FAIL,
  payload: error,
});

export const createClassInformationStart = (data) => ({
  type: configActionTypes.CREATE_CLASS_INFORMATION_START,
  payload: data,
});

export const createClassInformationSuccess = (data) => ({
  type: configActionTypes.CREATE_CLASS_INFORMATION_SUCCESS,
  payload: data,
});

export const createClassInformationFail = (error) => ({
  type: configActionTypes.CREATE_CLASS_INFORMATION_FAIL,
  payload: error,
});

export const classLevelProfileAction = (data) => ({
  type: configActionTypes.HANDLE_CLASS_LEVEL_PROFILE,
  payload: data,
});
export const createTermDefinitionStart = (data) => ({
  type: configActionTypes.CREATE_TERM_DEF_START,
  payload: data,
});

export const createTermDefinitionSuccess = (data) => ({
  type: configActionTypes.CREATE_TERM_DEF_SUCCESS,
  payload: data,
});

export const createTermDefinitionFail = (error) => ({
  type: configActionTypes.CREATE_TERM_DEF_FAIL,
  payload: error,
});

export const fetchAllTermDefStart = (data) => ({
  type: configActionTypes.FETCH_ALL_TERM_DEF_START,
  payload: data,
});

export const fetchAllTermDefSuccess = (data) => ({
  type: configActionTypes.FETCH_ALL_TERM_DEF_SUCCESS,
  payload: data,
});

export const fetchAllTermDefFail = (error) => ({
  type: configActionTypes.FETCH_ALL_TERM_DEF_FAIL,
  payload: error,
});

export const handleAddTermModalAction = (addTermModal) => ({
  type: configActionTypes.HANDLE_ADD_TERM_MODAL,
  payload: addTermModal,
});

export const editTermDefinitionStart = (data) => ({
  type: configActionTypes.EDIT_TERM_DEF_START,
  payload: data,
});

export const editTermDefinitionSuccess = (data) => ({
  type: configActionTypes.EDIT_TERM_DEF_SUCCESS,
  payload: data,
});

export const editTermDefinitionFail = (error) => ({
  type: configActionTypes.EDIT_TERM_DEF_FAIL,
  payload: error,
});

export const handleEditTermAction = (editTerm) => ({
  type: configActionTypes.HANDLE_EDIT_TERM,
  payload: editTerm,
});

export const deleteTermDefinitionStart = (id) => ({
  type: configActionTypes.DELETE_TERM_DEF_START,
  payload: id,
});

export const deleteTermDefinitionSuccess = (id) => ({
  type: configActionTypes.DELETE_TERM_DEF_SUCCESS,
  payload: id,
});

export const deleteTermDefinitionFail = (error) => ({
  type: configActionTypes.DELETE_TERM_DEF_FAIL,
  payload: error,
});

export const handleTermDataAction = (data) => ({
  type: configActionTypes.HANDLE_TERM_DATA,
  payload: data,
});

export const fetchClassInformationByArmIDStart = (data, path) => ({
  type: configActionTypes.FETCH_CLASS_INFORMATION_ARM_ID_START,
  payload: { data, path },
});

export const fetchClassInformationByArmIDSuccess = (data) => ({
  type: configActionTypes.FETCH_CLASS_INFORMATION_ARM_ID_SUCCESS,
  payload: data,
});

export const fetchClassInformationByArmIDFail = (error) => ({
  type: configActionTypes.FETCH_CLASS_INFORMATION_ARM_ID_FAIL,
  payload: error,
});

export const handleDeleteTermModalAction = (deleteTermModal) => ({
  type: configActionTypes.HANDLE_DELETE_TERM_MODAL,
  payload: deleteTermModal,
});

export const createNewSessionStart = (data) => ({
  type: configActionTypes.CREATE_NEW_SESSION_START,
  payload: data,
});

export const createNewSessionSuccess = (data) => ({
  type: configActionTypes.CREATE_NEW_SESSION_SUCCESS,
  payload: data,
});

export const createNewSessionFail = (error) => ({
  type: configActionTypes.CREATE_NEW_SESSION_FAIL,
  payload: error,
});

export const handleCreateSessionModal = (data) => ({
  type: configActionTypes.HANDLE_CREATE_SESSION_MODAL,
  payload: data,
});

export const fetchAllExistingTermsStart = () => ({
  type: configActionTypes.FETCH_ALL_EXISTING_TERMS_START,
});

export const fetchAllExistingTermsSuccess = (data) => ({
  type: configActionTypes.FETCH_ALL_EXISTING_TERMS_SUCCESS,
  payload: data,
});

export const fetchAllExistingTermsFail = (error) => ({
  type: configActionTypes.FETCH_ALL_EXISTING_TERMS_FAIL,
  payload: error,
});

export const setCurrentTermStart = (data) => ({
  type: configActionTypes.SET_CURRENT_TERM_START,
  payload: data,
});

export const setCurrentTermSuccess = (data) => ({
  type: configActionTypes.SET_CURRENT_TERM_SUCCESS,
  payload: data,
});

export const setCurrentTermFail = (error) => ({
  type: configActionTypes.SET_CURRENT_TERM_FAIL,
  payload: error,
});

export const getClassLevelTraitsStart = (data) => ({
  type: configActionTypes.GET_CLASS_LEVEL_TRAITS_START,
  payload: data,
});

export const getClassLevelTraitsSuccess = (data) => ({
  type: configActionTypes.GET_CLASS_LEVEL_TRAITS_SUCCESS,
  payload: data,
});

export const getClassLevelTraitsFail = (error) => ({
  type: configActionTypes.GET_CLASS_LEVEL_TRAITS_FAIL,
  payload: error,
});

export const getStudentTraitAssessmentStart = (data) => ({
  type: configActionTypes.GET_STUDENT_TRAIT_ASSESSMENT_START,
  payload: data,
});

export const getStudentTraitAssessmentSuccess = (data, traitId) => ({
  type: configActionTypes.GET_STUDENT_TRAIT_ASSESSMENT_SUCCESS,
  payload: { data, traitId },
});

export const getStudentTraitAssessmentFail = (error) => ({
  type: configActionTypes.GET_STUDENT_TRAIT_ASSESSMENT_FAIL,
  payload: error,
});

export const saveStudentTraitAssessmentStart = (data) => ({
  type: configActionTypes.SAVE_STUDENT_TRAIT_ASSESSMENT_START,
  payload: data,
});

export const saveStudentTraitAssessmentSuccess = (data) => ({
  type: configActionTypes.SAVE_STUDENT_TRAIT_ASSESSMENT_SUCCESS,
  payload: data,
});

export const saveStudentTraitAssessmentFail = (error) => ({
  type: configActionTypes.SAVE_STUDENT_TRAIT_ASSESSMENT_FAIL,
  payload: error,
});

export const handleEditClassInfo = (data) => ({
  type: configActionTypes.HANDLE_EDIT_CLASS_INFO,
  payload: data,
});

export const termProfileSetDateRangeStart = (data) => ({
  type: configActionTypes.TERM_PROFILE_SET_DATE_RANGE_START,
  payload: data,
});

export const termProfileSetDateRangeSuccess = (data) => ({
  type: configActionTypes.TERM_PROFILE_SET_DATE_RANGE_SUCCESS,
  payload: data,
});

export const termProfileSetDateRangeFail = (error) => ({
  type: configActionTypes.TERM_PROFILE_SET_DATE_RANGE_FAIL,
  payload: error,
});

export const termProfileSetClassInfoStart = (data) => ({
  type: configActionTypes.TERM_PROFILE_SET_CLASS_INFO_START,
  payload: data,
});

export const termProfileSetClassInfoSuccess = (data) => ({
  type: configActionTypes.TERM_PROFILE_SET_CLASS_INFO_SUCCESS,
  payload: data,
});

export const termProfileSetClassInfoFail = (error) => ({
  type: configActionTypes.TERM_PROFILE_SET_CLASS_INFO_FAIL,
  payload: error,
});

export const termProfileSetGradeFormatStart = (data) => ({
  type: configActionTypes.TERM_PROFILE_SET_GRADE_FORMAT_START,
  payload: data,
});

export const termProfileSetGradeFormatSuccess = (data) => ({
  type: configActionTypes.TERM_PROFILE_SET_GRADE_FORMAT_SUCCESS,
  payload: data,
});

export const termProfileSetGradeFormatFail = (error) => ({
  type: configActionTypes.TERM_PROFILE_SET_GRADE_FORMAT_FAIL,
  payload: error,
});

export const termProfileSetAssessmentFormatStart = (data) => ({
  type: configActionTypes.TERM_PROFILE_SET_ASSESSMENT_FORMAT_START,
  payload: data,
});

export const termProfileSetAssessmentFormatSuccess = (data) => ({
  type: configActionTypes.TERM_PROFILE_SET_ASSESSMENT_FORMAT_SUCCESS,
  payload: data,
});

export const termProfileSetAssessmentFormatFail = (error) => ({
  type: configActionTypes.TERM_PROFILE_SET_ASSESSMENT_FORMAT_FAIL,
  payload: error,
});

export const termProfileSetEarlyYearsStart = (data) => ({
  type: configActionTypes.TERM_PROFILE_SET_EARLY_YEARS_START,
  payload: data,
});

export const termProfileSetEarlyYearsSuccess = (data) => ({
  type: configActionTypes.TERM_PROFILE_SET_EARLY_YEARS_SUCCESS,
  payload: data,
});

export const termProfileSetEarlyYearsFail = (error) => ({
  type: configActionTypes.TERM_PROFILE_SET_EARLY_YEARS_FAIL,
  payload: error,
});

export const termProfileSetRatingsStart = (data) => ({
  type: configActionTypes.TERM_PROFILE_SET_RATINGS_START,
  payload: data,
});

export const termProfileSetRatingsSuccess = (data) => ({
  type: configActionTypes.TERM_PROFILE_SET_RATINGS_SUCCESS,
  payload: data,
});

export const termProfileSetRatingsFail = (error) => ({
  type: configActionTypes.TERM_PROFILE_SET_RATINGS_FAIL,
  payload: error,
});

export const getTermProfileDateRangeStart = (data) => ({
  type: configActionTypes.GET_TERM_PROFILE_DATE_RANGE_START,
  payload: data,
});

export const getTermProfileDateRangeSuccess = (data) => ({
  type: configActionTypes.GET_TERM_PROFILE_DATE_RANGE_SUCCESS,
  payload: data,
});

export const getTermProfileDateRangeFail = (error) => ({
  type: configActionTypes.GET_TERM_PROFILE_DATE_RANGE_FAIL,
  payload: error,
});

export const getTermProfileClassInfoStart = (data) => ({
  type: configActionTypes.GET_TERM_PROFILE_CLASS_INFO_START,
  payload: data,
});

export const getTermProfileClassInfoSuccess = (data) => ({
  type: configActionTypes.GET_TERM_PROFILE_CLASS_INFO_SUCCESS,
  payload: data,
});

export const getTermProfileClassInfoFail = (error) => ({
  type: configActionTypes.GET_TERM_PROFILE_CLASS_INFO_FAIL,
  payload: error,
});

export const getTermProfileGradeFormatStart = (data) => ({
  type: configActionTypes.GET_TERM_PROFILE_GRADE_FORMAT_START,
  payload: data,
});

export const getTermProfileGradeFormatSuccess = (data) => ({
  type: configActionTypes.GET_TERM_PROFILE_GRADE_FORMAT_SUCCESS,
  payload: data,
});

export const getTermProfileGradeFormatFail = (error) => ({
  type: configActionTypes.GET_TERM_PROFILE_GRADE_FORMAT_FAIL,
  payload: error,
});

export const getTermProfileSkillGroupStart = (data) => ({
  type: configActionTypes.GET_TERM_PROFILE_SKILL_GROUP_START,
  payload: data,
});

export const getTermProfileSkillGroupSuccess = (data) => ({
  type: configActionTypes.GET_TERM_PROFILE_SKILL_GROUP_SUCCESS,
  payload: data,
});

export const getTermProfileSkillGroupFail = (error) => ({
  type: configActionTypes.GET_TERM_PROFILE_SKILL_GROUP_FAIL,
  payload: error,
});

export const getTermProfileSkillRatingsStart = (data) => ({
  type: configActionTypes.GET_TERM_PROFILE_SKILL_RATINGS_START,
  payload: data,
});

export const getTermProfileSkillRatingsSuccess = (data) => ({
  type: configActionTypes.GET_TERM_PROFILE_SKILL_RATINGS_SUCCESS,
  payload: data,
});

export const getTermProfileSkillRatingsFail = (error) => ({
  type: configActionTypes.GET_TERM_PROFILE_SKILL_RATINGS_FAIL,
  payload: error,
});

export const getTermProfileAssessmentFormatStart = (data) => ({
  type: configActionTypes.GET_TERM_PROFILE_ASSESSMENT_FORMAT_START,
  payload: data,
});

export const getTermProfileAssessmentFormatSuccess = (data) => ({
  type: configActionTypes.GET_TERM_PROFILE_ASSESSMENT_FORMAT_SUCCESS,
  payload: data,
});

export const getTermProfileAssessmentFormatFail = (error) => ({
  type: configActionTypes.GET_TERM_PROFILE_ASSESSMENT_FORMAT_FAIL,
  payload: error,
});

export const getTermProfileEarlyYearsStart = (data) => ({
  type: configActionTypes.GET_TERM_PROFILE_EARLY_YEARS_START,
  payload: data,
});

export const getTermProfileEarlyYearsSuccess = (data) => ({
  type: configActionTypes.GET_TERM_PROFILE_EARLY_YEARS_SUCCESS,
  payload: data,
});

export const getTermProfileEarlyYearsFail = (error) => ({
  type: configActionTypes.GET_TERM_PROFILE_EARLY_YEARS_FAIL,
  payload: error,
});

export const getTermProfileRatingsStart = (data) => ({
  type: configActionTypes.GET_TERM_PROFILE_RATINGS_START,
  payload: data,
});

export const getTermProfileRatingsSuccess = (data) => ({
  type: configActionTypes.GET_TERM_PROFILE_RATINGS_SUCCESS,
  payload: data,
});

export const getTermProfileRatingsFail = (error) => ({
  type: configActionTypes.GET_TERM_PROFILE_RATINGS_FAIL,
  payload: error,
});

export const getStudentCommentsStart = (data) => ({
  type: configActionTypes.GET_STUDENT_COMMENTS_START,
  payload: data,
});

export const getStudentCommentsSuccess = (data) => ({
  type: configActionTypes.GET_STUDENT_COMMENTS_SUCCESS,
  payload: data,
});

export const getStudentCommentsFail = (error) => ({
  type: configActionTypes.GET_STUDENT_COMMENTS_FAIL,
  payload: error,
});

export const getApproveResultsStart = (data) => ({
  type: configActionTypes.FETCH_APPROVE_RESULTS_START,
  payload: data,
});

export const getApproveResultsSuccess = (data) => ({
  type: configActionTypes.FETCH_APPROVE_RESULTS_SUCCESS,
  payload: data,
});

export const getApproveResultsFail = (error) => ({
  type: configActionTypes.FETCH_APPROVE_RESULTS_FAIL,
  payload: error,
});

export const submitApprovalResultsStart = (data) => ({
  type: configActionTypes.SUBMIT_APPROVAL_RESULTS_START,
  payload: data,
});

export const submitApprovalResultsSuccess = (data) => ({
  type: configActionTypes.SUBMIT_APPROVAL_RESULTS_SUCCESS,
  payload: data,
});

export const submitApprovalResultsFail = (error) => ({
  type: configActionTypes.SUBMIT_APPROVAL_RESULTS_FAIL,
  payload: error,
});

export const addStudentCommentStart = (data) => ({
  type: configActionTypes.ADD_STUDENT_COMMENT_START,
  payload: data,
});

export const addStudentCommentSuccess = (data) => ({
  type: configActionTypes.ADD_STUDENT_COMMENT_SUCCESS,
  payload: data,
});

export const addStudentCommentFail = (error) => ({
  type: configActionTypes.ADD_STUDENT_COMMENT_FAIL,
  payload: error,
});

export const setupTermProfile = (data) => ({
  type: configActionTypes.SETUP_TERM_PROFILE,
  payload: data,
});

export const setupTermProfileComplete = () => ({
  type: configActionTypes.SETUP_TERM_PROFILE_COMPLETE,
});

export const fetchLevelsAndArmsStart = (termId) => ({
  type: configActionTypes.FETCH_CLASS_LEVEL_WITH_ARMS_START,
  payload: termId,
});

export const fetchLevelsAndArmsSuccess = (data) => ({
  type: configActionTypes.FETCH_CLASS_LEVEL_WITH_ARMS_SUCCESS,
  payload: data,
});

export const fetchLevelsAndArmsFail = (error) => ({
  type: configActionTypes.FETCH_CLASS_LEVEL_WITH_ARMS_FAIL,
  payload: error,
});

export const fetchChildResultStart = (data) => ({
  type: configActionTypes.FETCH_CHILD_RESULT_START,
  payload: data,
});

export const fetchChildResultSuccess = (data) => ({
  type: configActionTypes.FETCH_CHILD_RESULT_SUCCESS,
  payload: data,
});

export const fetchChildResultFail = (error) => ({
  type: configActionTypes.FETCH_CHILD_RESULT_FAIL,
  payload: error,
});

export const fetchChildAttendanceSummaryStart = (data) => ({
  type: configActionTypes.GET_CHILD_ATTENDANCE_SUMMARY_START,
  payload: data,
});

export const fetchChildAttendanceSummarySuccess = (data) => ({
  type: configActionTypes.GET_CHILD_ATTENDANCE_SUMMARY_SUCCESS,
  payload: data,
});

export const fetcChildAttendanceSummarytFail = (error) => ({
  type: configActionTypes.GET_CHILD_ATTENDANCE_SUMMARY_FAIL,
  payload: error,
});

export const fetchChildSkillsResultStart = (data) => ({
  type: configActionTypes.FETCH_CHILD_SKILLS_RESULT_START,
  payload: data,
});

export const fetchChildSkillsResultSuccess = (data) => ({
  type: configActionTypes.FETCH_CHILD_SKILLS_RESULT_SUCCESS,
  payload: data,
});

export const fetchChildSkillsResultFail = (error) => ({
  type: configActionTypes.FETCH_CHILD_SKILLS_RESULT_FAIL,
  payload: error,
});
export const getStudentResultsStart = (data) => ({
  type: configActionTypes.GET_STUDENT_RESULTS_START,
  payload: data,
});

export const getStudentResultsSuccess = (data) => ({
  type: configActionTypes.GET_STUDENT_RESULTS_SUCCESS,
  payload: data,
});

export const getStudentResultsFail = (error) => ({
  type: configActionTypes.GET_STUDENT_RESULTS_FAIL,
  payload: error,
});

export const transferStudentsAnotherArmStart = (data, callback) => ({
  type: configActionTypes.TRANSFER_STUDENTS_ANOTHER_ARM_START,
  payload: data,
  callback,
});

export const transferStudentsAnotherArmSuccess = (data) => ({
  type: configActionTypes.TRANSFER_STUDENTS_ANOTHER_ARM_SUCCESS,
  payload: data,
});

export const transferStudentsAnotherArmFail = (error) => ({
  type: configActionTypes.TRANSFER_STUDENTS_ANOTHER_ARM_FAIL,
  payload: error,
});

export const openStudentTransferModal = (transferModal) => ({
  type: configActionTypes.OPEN_STUDENT_TRANSFER_MODAL,
  payload: transferModal,
});

export const setOnboardingInfo = (data) => ({
  type: configActionTypes.SET_ONBOARDING_INF0,
  payload: data,
});

export const setIsOnboarding = (data) => ({
  type: configActionTypes.SET_IS_ONBOARDING,
  payload: data,
});

export const setRefetchAttendance = (payload) => ({
  type: configActionTypes.SET_REFETCH_ATTENDANCE,
  payload: payload,
});

export const handleBulkStudentModal = (payload) => ({
  type: configActionTypes.HANDLE_BULK_STUDENT_MODAL,
  payload: payload,
});

export const uploadSignatureStart = (signature) => ({
  type: configActionTypes.UPLOAD_SIGNATURE_START,
  payload: signature,
});

export const uploadSignatureSuccess = (data) => ({
  type: configActionTypes.UPLOAD_SIGNATURE_SUCCESS,
  payload: data,
});

export const uploadSignatureFail = (error) => ({
  type: configActionTypes.UPLOAD_SIGNATURE_FAIL,
  payload: error,
});

export const handleActivityModal = (data) => ({
  type: configActionTypes.HANDLE_ACTIVITY_MODAL,
  payload: data,
});

export const handleDeleteActivityModal = (data) => ({
  type: configActionTypes.HANDLE_DELETE_ACTIVITY_MODAL,
  payload: data,
});

export const handleActivityData = (data) => ({
  type: configActionTypes.HANDLE_ACTIVITY_DATA,
  payload: data,
});

export const handleNoGradingFormat = (status) => ({
  type: configActionTypes.HANDLE_NO_GRADING_FORMAT,
  payload: status,
});

export const updateAppConfigState = (payload) => ({
  type: configActionTypes.UPDATE_APP_CONFIG_STATE,
  payload,
});
