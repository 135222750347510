import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';

import { Layout } from '@flexisaf/flexibull2';
import { default as ReportTemplate, default as ResultPreview } from '@flexisaf/report-template';
import UsersManagement from '@flexisaf/ums-package';
import PrivateDashboard from 'components/RouteHelpers/PrivateDashboard';
import PublicRoute from 'components/RouteHelpers/PublicRoute';
import Skeletonloader from 'components/SkeletonLoader';
import PermissionRoute from 'components/permissionChecker/routes';
import { Theme } from 'config/theme';
import useSchoolInfoGet from 'general-hooks/useSchoolInfoGet';
import moment from 'moment';
import ScoreEntryTimeframe from 'pages/safsims-configuration/session-terms/session-initialization/scoreEntryTimeframe';
import { Fragment, Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { selectSiteConfig } from 'redux/global-store/selectors';
import {
  select_ns_email_view,
  select_ns_sms_view,
  select_safsims_arm_view,
  select_safsims_broadsheet_view,
  select_safsims_classes_view,
  select_safsims_classlevel_view,
  select_safsims_fees_view,
  select_safsims_parent_view,
  select_safsims_roles_create,
  select_safsims_roles_delete,
  select_safsims_roles_edit,
  select_safsims_roles_view,
  select_safsims_settings_academic,
  select_safsims_settings_fees,
  select_safsims_settings_general,
  select_safsims_staff_edit,
  select_safsims_staff_view,
  select_safsims_student_profile_view,
  select_safsims_student_view,
  select_safsims_users_create,
  select_safsims_users_delete,
  select_safsims_users_edit,
  select_safsims_users_view,
  select_safsims_view_parent_profile,
  select_super_admin,
} from 'redux/privileges/selectors';
import {
  selectActiveUserType,
  selectCurrentStaffClassView,
  selectIsLoggedIn,
  selectOnboarded,
} from 'redux/users/selectors';
import { Lazy, Loadable } from 'utils/ComponentLoader';
import { googleAnalyticsMonitorPages } from 'utils/GoogleAnalytics';
import './App.css';
import { reportData } from './config/reportData';
import AppConfig from './utils/config';

import AppServiceWorker from './AppServiceWorker';

const GoogleAuth = Loadable(() => import('pages/login/googleAuth'));
const LoginScreen = Loadable(() => import('pages/login'));
const InternalUsers = Loadable(() => import('pages/login/internalUsers'));
const NotFound = Loadable(() => import('pages/error-pages/404'));
const LandingPage = Loadable(() => import('pages/landingPage'));
const Unauthorized = Loadable(() => import('pages/error-pages/Unauthorized'));
const DirectPay = Loadable(() => import('pages/directPay'));
const DirectResult = Loadable(() => import('pages/directResult'));
const ConfirmPasswordReset = Loadable(() => import('pages/login/confirmPasswordReset'));
const ResetLink = Loadable(() => import('pages/login/SendResetLink'));
const VerifyPage = Loadable(() => import('pages/user-management/verify'));
const StudentReport = Loadable(() => import('pages/broadsheet/broadsheets/student-report'));

const InfoCenter = Loadable(() => import('pages/student/cbt/info-center'));
const ExamInstructions = Loadable(() => import('pages/student/cbt/exam-instructions'));
const ExamQuestions = Loadable(() => import('pages/student/cbt/exam-questions'));

const ClassLevelBroadsheet = Loadable(() => import('pages/broadsheet/broadsheets/class-level'));
const ClassArmBroadsheet = Loadable(() => import('pages/broadsheet/broadsheets/class-arm'));
const MultiplePay = Loadable(() => import('pages/parents/school-fees/multiple-pay'));
const TermConfigProfile = Lazy(
  () =>
    import('pages/safsims-configuration/session-terms/session-initialization/termConfigProfile'),
);
const StudentResults = Lazy(() => import('pages/parents/results'));

const UserProfile = Lazy(() => import('pages/user-management'));
const ClassArmProfile = Lazy(() => import('pages/safsims-configuration/classArmProfile/index'));
const ViewRequest = Lazy(() => import('pages/user-management/viewRequest'));

const CognitiveAssessment = Lazy(() => import('pages/safsims-configuration/cognitive'));
const AssessmentProfile = Lazy(
  () =>
    import('pages/safsims-configuration/session-terms/session-initialization/assessmentProfile'),
);

const BroadhSheet = Lazy(() => import('pages/broadsheet'));
const Plans = Lazy(() => import('pages/plans'));
const SchoolFees = Lazy(() => import('pages/parents/school-fees'));
const MakePayment = Lazy(() => import('pages/parents/school-fees/makePayment'));

const PaymentSuccess = Lazy(() => import('pages/parents/school-fees/payment-success'));
const PaymentFail = Lazy(() => import('pages/parents/school-fees/payment-fail'));
const PaymentPending = Lazy(() => import('pages/parents/school-fees/payment-pending'));
const PaymentVerification = Lazy(() => import('pages/parents/school-fees/payment-verification'));

const SchoolInfo = Lazy(() => import('pages/safsims-configuration/schoolInformation'));
const SchoolInvoice = Lazy(() => import('pages/safsims-configuration/schoolFees'));
const Arms = Lazy(() => import('pages/safsims-configuration/arms'));
const BankAccount = Lazy(() => import('pages/safsims-configuration/banks'));
const SessionsAndTerms = Lazy(() => import('pages/safsims-configuration/session-terms'));
const Subjects = Lazy(() => import('pages/safsims-configuration/subjects'));
const AssignArm = Lazy(() => import('pages/safsims-configuration/assignArm'));
const GradingFormat = Lazy(() => import('pages/safsims-configuration/grading'));
const Levels = Lazy(() => import('pages/safsims-configuration/levels'));

const FeesConfig = Lazy(() => import('pages/safsims-configuration/tabs/fees'));
const Classes = Lazy(() => import('pages/classes'));

const EarlyYearsGradingFormat = Lazy(() => import('pages/safsims-configuration/early-years'));
const Assessments = Lazy(() => import('pages/safsims-configuration/assessment'));
const StaffManagement = Lazy(() => import('pages/staff-management'));
const StaffProfile = Lazy(() => import('pages/staff-management/staffProfile'));
const RequestReview = Lazy(() => import('pages/staff-management/requestReview'));

const StudentManagement = Lazy(() => import('pages/student-management'));
const StudentProfile = Lazy(() => import('pages/student-management/studentProfile'));

const NotificationConfiguration = Lazy(() => import('pages/notification-configuration'));

const NotificationStatus = Lazy(() => import('pages/notification-status'));

const ParentManagement = Lazy(() => import('pages/parent-management'));
const ParentProfile = Lazy(() => import('pages/parent-management/parentProfile'));

const Fees = Lazy(() => import('pages/fees-management'));
const AccountStudentProfile = Lazy(() => import('pages/fees-management/student-profile-page'));

const ClassLevelProfile = Lazy(() => import('pages/safsims-configuration/classLevelProfile'));

const Templates = Lazy(() => import('pages/report-templates/index'));

const LoginHistory = Lazy(() => import('pages/login-history'));

const CBT = Lazy(() => import('pages/cbt'));
const SubjectBank = Lazy(() => import('pages/cbt/tabs/pages/subjectBank'));
const QuestionShowcase = Lazy(() => import('pages/cbt/tabs/pages/questionShowcase'));
const CreateExams = Lazy(() => import('pages/cbt/tabs/pages/createExams'));
const EditExam = Lazy(() => import('pages/cbt/tabs/pages/editExam'));

const LessonPlanConfig = Lazy(() => import('pages/lesson-plan/lesson-plan-config'));
const LessonPlanView = Lazy(() => import('pages/lesson-plan/lesson-plan-config/lesson-plan-view'));
const ConfigureTemplate = Lazy(
  () => import('pages/lesson-plan/lesson-plan-config/configure-template'),
);

const PromotionLevel = Lazy(() => import('pages/safsims-configuration/promotions'));
const PromotionPanel = Lazy(() => import('pages/safsims-configuration/promotions/promotion-panel'));

const StudentLogin = Loadable(() => import('pages/login/studentLogin'));

const OtherAttendance = Loadable(() => import('pages/safsims-configuration/notification'));
const TransactionHistory = Loadable(() => import('pages/parents/transaction-history'));
const SingleStudentResultView = Loadable(
  () => import('pages/parents/results/mobile-view/single-student-view'),
);
const InvoiceHistory = Loadable(() => import('pages/parents/invoice-history'));

const DirectorDashboard = Loadable(() => import('pages/director/dashboard'));
const SignUp = Loadable(() => import('pages/parents/sign-up'));
const SignupApproval = Loadable(() => import('pages/parent-management/signup-approval'));
const AdminDashboard = Loadable(() => import('pages/admin-dashboard'));
const RecordBankPayment = Loadable(() => import('pages/parents/record-bank-payment'));

moment.locale('en');

ReactGA.initialize(AppConfig.MEASUREMENT_ID);

function App() {
  useEffect(() => {
    googleAnalyticsMonitorPages();
    TagManager.initialize({ gtmId: AppConfig.GTM_ID });
  }, []);

  const { schoolInfo } = useSchoolInfoGet();

  const siteConfig: any = useSelector(selectSiteConfig);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const smsView = useSelector(select_ns_sms_view);
  const emailView = useSelector(select_ns_email_view);
  const staffView = useSelector(select_safsims_staff_view);
  const studentView = useSelector(select_safsims_student_view);
  const studentProfileView = useSelector(select_safsims_student_profile_view);
  const parentView = useSelector(select_safsims_parent_view);
  const classlevelView = useSelector(select_safsims_classlevel_view);
  const classArmView = useSelector(select_safsims_arm_view);
  const staffEdit = useSelector(select_safsims_staff_edit);
  const usersView = useSelector(select_safsims_users_view);
  const usersEdit = useSelector(select_safsims_users_edit);
  const usersCreate = useSelector(select_safsims_users_create);
  const usersDelete = useSelector(select_safsims_users_delete);
  const rolesView = useSelector(select_safsims_roles_view);
  const rolesEdit = useSelector(select_safsims_roles_edit);
  const rolesCreate = useSelector(select_safsims_roles_create);
  const rolesDelete = useSelector(select_safsims_roles_delete);
  const viewSettings = useSelector(select_safsims_settings_general);
  const viewFeeSettings = useSelector(select_safsims_settings_fees);
  const viewAcademicSettings = useSelector(select_safsims_settings_academic);
  const parentProfileView = useSelector(select_safsims_view_parent_profile);
  const accountDashboardView = useSelector(select_safsims_fees_view);
  const classesView = useSelector(select_safsims_classes_view);
  const broadsheetView = useSelector(select_safsims_broadsheet_view);
  const currentStaffClassView: any = useSelector(selectCurrentStaffClassView);
  const activeUserType = useSelector(selectActiveUserType);
  const onboarded = useSelector(selectOnboarded);

  const config = { ...siteConfig };
  const siteUserType = activeUserType;
  const superAdmin = useSelector(select_super_admin);

  const onboardCheck = superAdmin ? onboarded : true;
  const isPWA = window.matchMedia('(display-mode: standalone)').matches;

  return (
    <>
      <Fragment>
        <Layout theme={Theme}>
          <Router>
            <Routes>
              <Route
                path="/login"
                element={
                  <PublicRoute>
                    <LoginScreen />
                  </PublicRoute>
                }
              />
              <Route
                path="safstar-signup"
                element={
                  <PublicRoute>
                    <InternalUsers />
                  </PublicRoute>
                }
              />

              <Route
                path="/student-login"
                element={
                  <PublicRoute>
                    <StudentLogin />
                  </PublicRoute>
                }
              />

              <Route path="/director-dashboard" element={<DirectorDashboard />} />

              <Route
                path="/sign-up-parent"
                element={
                  <PublicRoute>
                    <SignUp />
                  </PublicRoute>
                }
              />

              <Route
                path="/record-bank-payment"
                element={
                  <PublicRoute>
                    <RecordBankPayment />
                  </PublicRoute>
                }
              />

              <Route
                path="/direct-multiple-payment"
                element={
                  <Suspense fallback={<Skeletonloader />}>
                    <MultiplePay />
                  </Suspense>
                }
              />

              <Route
                path="/direct-payment-verification/:id"
                element={
                  <Suspense fallback={<Skeletonloader />}>
                    <PaymentVerification />
                  </Suspense>
                }
              />

              <Route
                path="/direct-payment-success"
                element={
                  <Suspense fallback={<Skeletonloader />}>
                    <PaymentSuccess />
                  </Suspense>
                }
              />
              <Route
                path="/direct-payment-fail"
                element={
                  <Suspense fallback={<Skeletonloader />}>
                    <PaymentFail />
                  </Suspense>
                }
              />
              <Route
                path="/direct-payment-pending"
                element={
                  <Suspense fallback={<Skeletonloader />}>
                    <PaymentPending />
                  </Suspense>
                }
              />

              <Route path="/send-reset-link" element={<ResetLink config={config} />} />
              <Route path="/confirm" element={<ConfirmPasswordReset config={config} />} />
              <Route path="/reset-request" element={<ConfirmPasswordReset config={config} />} />
              <Route path="/report-templates" element={<ReportTemplate tags={reportData} />} />
              <Route path="/student-report" element={<StudentReport />} />
              <Route path="/preview-template" element={<ResultPreview />} />
              <Route path="/auth" element={<GoogleAuth />} />
              <Route path="/verify" element={<VerifyPage />} />
              <Route path="/404" element={<NotFound />} />
              <Route path="/unauthorized" element={<Unauthorized />} />
              <Route path="/direct-pay/:id" element={<DirectPay />} />
              <Route path="/direct-result/:id" element={<DirectResult />} />
              <Route
                path="/make-direct-pay/:id"
                element={
                  <Suspense fallback={<Skeletonloader />}>
                    <MakePayment />
                  </Suspense>
                }
              />

              <Route
                path="/single-result/:id"
                element={
                  <Suspense fallback={<Skeletonloader />}>
                    <SingleStudentResultView />
                  </Suspense>
                }
              />
              <Route path="/exam-info" element={<InfoCenter schoolInfo={schoolInfo} />} />
              <Route path="/paper-instructions/:id" element={<ExamInstructions />} />
              <Route path="/paper-questions/:id" element={<ExamQuestions />} />

              <Route
                path="/"
                element={
                  <PublicRoute>
                    <LoginScreen />
                  </PublicRoute>
                }
              />

              <Route path="/*" element={<PrivateDashboard />}>
                <Route
                  path="dashboard"
                  element={
                    <Suspense fallback={<Skeletonloader />}>
                      <AdminDashboard />
                    </Suspense>
                  }
                />
                <Route
                  path="school-information"
                  element={
                    <Suspense fallback={<Skeletonloader />}>
                      <SchoolInfo />
                    </Suspense>
                  }
                />
                <Route
                  path="users"
                  element={
                    <PermissionRoute
                      permission={usersView || rolesView}
                      component={
                        <Suspense fallback={<Skeletonloader />}>
                          <UsersManagement config={config} />
                        </Suspense>
                      }
                    />
                  }
                />
                {/* <PermissionRoute
                permission={false}
                
                path="/notification"
                component={<Notification  config={config} />}
                /> */}
                <Route
                  path="staff-profile/:id"
                  element={
                    <PermissionRoute
                      permission={staffView && siteUserType === 'staff'}
                      component={
                        <Suspense fallback={<Skeletonloader />}>
                          <StaffProfile />
                        </Suspense>
                      }
                    />
                  }
                />
                <Route
                  path="request-review/:id"
                  element={
                    <PermissionRoute
                      permission={staffEdit && siteUserType === 'staff'}
                      component={
                        <Suspense fallback={<Skeletonloader />}>
                          <RequestReview />
                        </Suspense>
                      }
                    />
                  }
                />
                <Route
                  path="student-management"
                  element={
                    <PermissionRoute
                      permission={studentProfileView && siteUserType === 'staff' && onboardCheck}
                      component={
                        <Suspense fallback={<Skeletonloader />}>
                          <StudentManagement />
                        </Suspense>
                      }
                    />
                  }
                />
                <Route
                  path="notification-configuration"
                  element={
                    <PermissionRoute
                      permission={studentProfileView && siteUserType === 'staff' && onboardCheck}
                      component={
                        <Suspense fallback={<Skeletonloader />}>
                          <NotificationConfiguration />
                        </Suspense>
                      }
                    />
                  }
                />

                <Route
                  path="payment-verification/:id"
                  element={
                    <Suspense fallback={<Skeletonloader />}>
                      <PaymentVerification />
                    </Suspense>
                  }
                />

                <Route
                  path="student-profile/:id"
                  element={
                    <PermissionRoute
                      permission={true}
                      component={
                        <Suspense fallback={<Skeletonloader />}>
                          <StudentProfile />
                        </Suspense>
                      }
                    />
                  }
                />

                <Route
                  path="parent-management"
                  element={
                    <PermissionRoute
                      permission={parentView && siteUserType === 'staff' && onboardCheck}
                      component={
                        <Suspense fallback={<Skeletonloader />}>
                          <ParentManagement />
                        </Suspense>
                      }
                    />
                  }
                />

                <Route
                  path="early-years-grading"
                  element={
                    <Suspense fallback={<Skeletonloader />}>
                      <EarlyYearsGradingFormat />
                    </Suspense>
                  }
                />

                <Route
                  path="staff-management"
                  element={
                    <PermissionRoute
                      permission={staffView && siteUserType === 'staff' && onboardCheck}
                      component={
                        <Suspense fallback={<Skeletonloader />}>
                          <StaffManagement />
                        </Suspense>
                      }
                    />
                  }
                />

                <Route
                  path="signup-approval"
                  element={
                    <Suspense fallback={<Skeletonloader />}>
                      <SignupApproval />
                    </Suspense>
                  }
                />

                <Route
                  path="assessments"
                  element={
                    <Suspense fallback={<Skeletonloader />}>
                      <Assessments />
                    </Suspense>
                  }
                />

                <Route
                  path="parent-profile/:id"
                  element={
                    <PermissionRoute
                      permission={parentView && siteUserType === 'staff'}
                      component={
                        <Suspense fallback={<Skeletonloader />}>
                          <ParentProfile />
                        </Suspense>
                      }
                    />
                  }
                />

                <Route
                  path="class-level/:id"
                  element={
                    <PermissionRoute
                      permission={viewSettings}
                      component={
                        <Suspense fallback={<Skeletonloader />}>
                          <ClassLevelProfile />
                        </Suspense>
                      }
                    />
                  }
                />

                <Route
                  path="user-profile"
                  element={
                    <Suspense fallback={<Skeletonloader />}>
                      <UserProfile />
                    </Suspense>
                  }
                />

                <Route
                  path="class-arm"
                  element={
                    <PermissionRoute
                      permission={
                        viewSettings ||
                        classesView ||
                        (currentStaffClassView.length && siteUserType === 'staff')
                      }
                      component={
                        <Suspense fallback={<Skeletonloader />}>
                          <ClassArmProfile />
                        </Suspense>
                      }
                    />
                  }
                />

                <Route
                  path="view-request/:id"
                  element={
                    <Suspense fallback={<Skeletonloader />}>
                      <ViewRequest />
                    </Suspense>
                  }
                />

                <Route
                  path="fees"
                  element={
                    <PermissionRoute
                      permission={accountDashboardView && siteUserType === 'staff' && onboardCheck}
                      component={
                        <Suspense fallback={<Skeletonloader />}>
                          <Fees />
                        </Suspense>
                      }
                    />
                  }
                />

                <Route
                  path="plans"
                  element={
                    <PermissionRoute
                      permission={siteUserType === 'staff' && onboardCheck}
                      component={
                        <Suspense fallback={<Skeletonloader />}>
                          <Plans />
                        </Suspense>
                      }
                    />
                  }
                />

                <Route
                  path="fees/student/:id"
                  element={
                    <Suspense fallback={<Skeletonloader />}>
                      <AccountStudentProfile />
                    </Suspense>
                  }
                />

                <Route
                  path="school-fees"
                  element={
                    <PermissionRoute
                      permission={siteUserType === 'parent'}
                      component={
                        <Suspense fallback={<Skeletonloader />}>
                          <SchoolFees />
                        </Suspense>
                      }
                    />
                  }
                />

                <Route
                  path="student-result"
                  element={
                    <PermissionRoute
                      permission={siteUserType === 'parent'}
                      component={
                        <Suspense fallback={<Skeletonloader />}>
                          <StudentResults />
                        </Suspense>
                      }
                    />
                  }
                />

                <Route
                  path="fees-configuration"
                  element={
                    <PermissionRoute
                      permission={viewFeeSettings}
                      component={
                        <Suspense fallback={<Skeletonloader />}>
                          <FeesConfig />
                        </Suspense>
                      }
                    />
                  }
                />

                <Route
                  path="school-fees/:id"
                  element={
                    <PermissionRoute
                      permission={siteUserType === 'parent'}
                      component={
                        <Suspense fallback={<Skeletonloader />}>
                          <MakePayment />
                        </Suspense>
                      }
                    />
                  }
                />

                <Route
                  path="broadsheet/class-level/:id"
                  element={
                    <PermissionRoute
                      permission={broadsheetView && siteUserType === 'staff'}
                      component={
                        <Suspense fallback={<Skeletonloader />}>
                          <ClassLevelBroadsheet />
                        </Suspense>
                      }
                    />
                  }
                />

                <Route
                  path="broadsheet/class-arm/:id"
                  element={
                    <PermissionRoute
                      permission={broadsheetView && siteUserType === 'staff'}
                      component={
                        <Suspense fallback={<Skeletonloader />}>
                          <ClassArmBroadsheet />
                        </Suspense>
                      }
                    />
                  }
                />

                <Route
                  path="payment-success"
                  element={
                    <Suspense fallback={<Skeletonloader />}>
                      <PaymentSuccess />
                    </Suspense>
                  }
                />
                <Route
                  path="payment-fail"
                  element={
                    <Suspense fallback={<Skeletonloader />}>
                      <PaymentFail />
                    </Suspense>
                  }
                />
                <Route
                  path="payment-pending"
                  element={
                    <Suspense fallback={<Skeletonloader />}>
                      <PaymentPending />
                    </Suspense>
                  }
                />
                <Route
                  path="school-invoice"
                  element={
                    <Suspense fallback={<Skeletonloader />}>
                      <SchoolInvoice />
                    </Suspense>
                  }
                />
                <Route
                  path="class-level"
                  element={
                    <PermissionRoute
                      permission={viewSettings}
                      component={
                        <Suspense fallback={<Skeletonloader />}>
                          <Levels />
                        </Suspense>
                      }
                    />
                  }
                />
                <Route
                  path="templates"
                  element={
                    <PermissionRoute
                      permission={viewSettings}
                      component={
                        <Suspense fallback={<Skeletonloader />}>
                          <Templates />
                        </Suspense>
                      }
                    />
                  }
                />
                <Route
                  path="arms"
                  element={
                    <PermissionRoute
                      permission={viewSettings}
                      component={
                        <Suspense fallback={<Skeletonloader />}>
                          <Arms />
                        </Suspense>
                      }
                    />
                  }
                />
                <Route
                  path="bank-accounts"
                  element={
                    <Suspense fallback={<Skeletonloader />}>
                      <BankAccount />
                    </Suspense>
                  }
                />
                <Route
                  path="sessions-and-terms"
                  element={
                    <Suspense fallback={<Skeletonloader />}>
                      <SessionsAndTerms />
                    </Suspense>
                  }
                />
                <Route
                  path="subjects"
                  element={
                    <Suspense fallback={<Skeletonloader />}>
                      <Subjects />
                    </Suspense>
                  }
                />
                <Route
                  path="assign-Arm"
                  element={
                    <Suspense fallback={<Skeletonloader />}>
                      <AssignArm />
                    </Suspense>
                  }
                />
                <Route
                  path="grading-format"
                  element={
                    <Suspense fallback={<Skeletonloader />}>
                      <GradingFormat />
                    </Suspense>
                  }
                />

                <Route
                  path="classes"
                  element={
                    <PermissionRoute
                      permission={onboardCheck}
                      component={
                        <Suspense fallback={<Skeletonloader />}>
                          <Classes />
                        </Suspense>
                      }
                    />
                  }
                />

                <Route
                  path="term-profile/:id"
                  element={
                    <Suspense fallback={<Skeletonloader />}>
                      <TermConfigProfile />
                    </Suspense>
                  }
                />
                <Route
                  path="skill-assessment"
                  element={
                    <Suspense fallback={<Skeletonloader />}>
                      <CognitiveAssessment />
                    </Suspense>
                  }
                />
                <Route
                  path="assessment/:id"
                  element={
                    <Suspense fallback={<Skeletonloader />}>
                      <AssessmentProfile />
                    </Suspense>
                  }
                />

                <Route
                  path="broadsheet"
                  element={
                    <PermissionRoute
                      permission={onboardCheck}
                      component={
                        <Suspense fallback={<Skeletonloader />}>
                          <BroadhSheet />
                        </Suspense>
                      }
                    />
                  }
                />

                <Route
                  path="term-profile/score-entry-timeframe/:id"
                  element={
                    <Suspense fallback={<Skeletonloader />}>
                      <ScoreEntryTimeframe />
                    </Suspense>
                  }
                />
                <Route
                  path="multiple-payment"
                  element={
                    <Suspense fallback={<Skeletonloader />}>
                      <MultiplePay />
                    </Suspense>
                  }
                />
                <Route
                  path="notification-status"
                  element={
                    <Suspense fallback={<Skeletonloader />}>
                      <NotificationStatus />
                    </Suspense>
                  }
                />
                <Route
                  path="login-history"
                  element={
                    <Suspense fallback={<Skeletonloader />}>
                      <LoginHistory />
                    </Suspense>
                  }
                />
                <Route
                  path="cbt"
                  element={
                    <Suspense fallback={<Skeletonloader />}>
                      <CBT />
                    </Suspense>
                  }
                />
                <Route
                  path="cbt/subject/:id"
                  element={
                    <Suspense fallback={<Skeletonloader />}>
                      <SubjectBank />
                    </Suspense>
                  }
                />
                <Route
                  path="cbt/question/:id"
                  element={
                    <Suspense fallback={<Skeletonloader />}>
                      <QuestionShowcase />
                    </Suspense>
                  }
                />
                <Route
                  path="cbt/exams/create"
                  element={
                    <Suspense fallback={<Skeletonloader />}>
                      <CreateExams />
                    </Suspense>
                  }
                />
                <Route
                  path="cbt/exams/:id"
                  element={
                    <Suspense fallback={<Skeletonloader />}>
                      <EditExam />
                    </Suspense>
                  }
                />
                <Route
                  path="lesson-plan-config"
                  element={
                    <Suspense fallback={<Skeletonloader />}>
                      <LessonPlanConfig />
                    </Suspense>
                  }
                />
                <Route
                  path="lesson-plan-view"
                  element={
                    <Suspense fallback={<Skeletonloader />}>
                      <LessonPlanView />
                    </Suspense>
                  }
                />
                <Route
                  path="configure-template"
                  element={
                    <Suspense fallback={<Skeletonloader />}>
                      <ConfigureTemplate />
                    </Suspense>
                  }
                />
                <Route
                  path="promotions"
                  element={
                    <Suspense fallback={<Skeletonloader />}>
                      <PromotionLevel />
                    </Suspense>
                  }
                />
                <Route
                  path="promotions-panel/:id"
                  element={
                    <Suspense fallback={<Skeletonloader />}>
                      <PromotionPanel />
                    </Suspense>
                  }
                />
                <Route
                  path="other-configs"
                  element={
                    <Suspense fallback={<Skeletonloader />}>
                      <OtherAttendance />
                    </Suspense>
                  }
                />
                <Route
                  path="transaction-history"
                  element={
                    <Suspense fallback={<Skeletonloader />}>
                      <TransactionHistory />
                    </Suspense>
                  }
                />
                <Route
                  path="invoice-history"
                  element={
                    <Suspense fallback={<Skeletonloader />}>
                      <InvoiceHistory />
                    </Suspense>
                  }
                />
              </Route>
            </Routes>
          </Router>
          {!isPWA && <AppServiceWorker />}
        </Layout>
      </Fragment>
    </>
  );
}

export default App;
