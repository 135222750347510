import { Notify } from '@flexisaf/flexibull2';
import { put, takeLatest } from 'redux-saga/effects';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';
import {
  deleteInvoiceDiscountFail,
  deleteInvoiceDiscountSuccess,
  fetchInvoiceDiscountStart,
  fetchStudentIncoiceSummaryStart,
  fetchStudentInvoicesStart,
} from '../actions';
import invoiceActionTypes from '../types';

export function* deleteDiscount({ payload }) {
  let payload2 = {
    'session-id': payload['session-id'],
    'term-id': payload['term-id'],
    student_id: payload['student-id'],
  };
  try {
    const response = yield httpClient.delete(`/school-fees-discounts`, { params: payload });
    Notify('Invoice discount deleted successfully.', { position: 'top-right' });
    yield put(deleteInvoiceDiscountSuccess(response.data));
    yield put(fetchInvoiceDiscountStart(payload2));
    yield put(fetchStudentIncoiceSummaryStart(payload['student-id']));
    yield put(fetchStudentInvoicesStart(payload));
  } catch (error) {
    yield put(deleteInvoiceDiscountFail(error?.message));
    const err = handleError(error);
  }
}

export function* onDeleteInvoiceDiscount() {
  yield takeLatest(invoiceActionTypes.DELETE_INVOICE_DISCOUNT_START, deleteDiscount);
}
