import storage from 'localforage';
import { persistReducer } from 'redux-persist';
import { FIX_ANY } from 'utils/types';
import userActionTypes from './types';
export interface IUserState {
  isLoading?: boolean;
  buttonLoading?: boolean;
  saveButtonLoading?: boolean;
  currentUser?: FIX_ANY;
  userBio?: FIX_ANY;
  error?: any;
  loading?: boolean;
  wizard?: boolean;
  edit?: boolean;
  editRequests?: FIX_ANY[];
  documents?: FIX_ANY[];
  access_token?: string | null;
  refresh_token?: string | null;
  token_error?: any;
  activeUserType?: string;
  userTypes?: FIX_ANY[];
  userTypeModal?: boolean;
  isLoggedIn?: boolean;
  tokenExpired?: boolean;
  resetPassword?: boolean;
  isRefreshingToken?: boolean;
  currentStaffSubjects?: FIX_ANY[];
  currentStaffClasses?: FIX_ANY[];
  currentStaffClassView?: FIX_ANY[];
  activeChildPayment?: FIX_ANY;
  view?: number;
  getStarted?: boolean;
  doneSteps?: FIX_ANY[];
  showWizard?: boolean;
  run?: boolean;
  onboarded?: boolean;
  // onboardingSteps?: FIX_ANY[]
  currentOnboardingStep?: FIX_ANY;
  disableNext?: boolean;
  feesActiveTab?: number;
  earlyYearsActiveTab?: number;
  skillsActiveTab?: number;
  sessionsActiveTab?: number;
  finishModal?: boolean;
  totalEditRequests?: number | string;
  viewEditRequest?: any;
  loginAttempts?: number;
  disableLogin?: boolean;
  disableLoginTime?: number;
  isHalfwayModalOpen?: boolean;
  accountVerificationModalOpen?: boolean;
}

const INITIAL_STATE: IUserState = {
  isLoading: false,
  buttonLoading: false,
  saveButtonLoading: false,
  currentUser: {
    linked_students: [],
  },
  userBio: {},
  error: null,
  loading: false,
  wizard: false,
  edit: false,
  editRequests: [],
  documents: [],
  access_token: null,
  refresh_token: null,
  token_error: null,
  activeUserType: '',
  userTypes: [],
  userTypeModal: false,
  isLoggedIn: false,
  tokenExpired: false,
  resetPassword: false,
  isRefreshingToken: false,
  currentStaffSubjects: [],
  currentStaffClasses: [],
  currentStaffClassView: [],
  activeChildPayment: {},
  view: 0,
  getStarted: false,
  doneSteps: [],
  showWizard: false,
  run: true,
  onboarded: false,
  // onboardingSteps: []
  currentOnboardingStep: {},
  disableNext: true,
  feesActiveTab: 0,
  earlyYearsActiveTab: 0,
  skillsActiveTab: 0,
  sessionsActiveTab: 0,
  finishModal: false,
  totalEditRequests: 0,
  loginAttempts: 0,
  disableLogin: false,
  disableLoginTime: 0,
  isHalfwayModalOpen: false,
  accountVerificationModalOpen: false,
};
interface actionParams {
  type: userActionTypes;
  payload: any;
}

function userReducer(state = INITIAL_STATE, { type, payload }: actionParams) {
  switch (type) {
    case userActionTypes.USER_LOGIN_START:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case userActionTypes.USER_LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoggedIn: true,
        currentUser: payload,
      };
    case userActionTypes.USER_LOGIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
        isLoggedIn: false,
      };
    case userActionTypes.SET_CURRENT_ONBOARDING_STEP:
      return {
        ...state,
        currentOnboardingStep: payload,
      };
    case userActionTypes.SET_ONBOARDED:
      return {
        ...state,
        onboarded: payload,
      };
    case userActionTypes.SET_DISABLE_NEXT:
      return {
        ...state,
        disableNext: payload,
      };
    case userActionTypes.SET_GET_STARTED:
      return {
        ...state,
        getStarted: payload,
      };
    case userActionTypes.SET_DONE_STEPS:
      const current = state.doneSteps || [];
      return {
        ...state,
        doneSteps: [...current, payload],
      };
    case userActionTypes.HANDLE_SHOW_WIZARD:
      return {
        ...state,
        showWizard: payload,
      };
    case userActionTypes.SET_FEES_ACTIVE_TAB:
      return {
        ...state,
        feesActiveTab: payload,
      };
    case userActionTypes.SET_EARLY_YEARS_ACTIVE_TAB:
      return {
        ...state,
        earlyYearsActiveTab: payload,
      };
    case userActionTypes.SET_SKILLS_ACTIVE_TAB:
      return {
        ...state,
        skillsActiveTab: payload,
      };
    case userActionTypes.SET_SESSIONS_ACTIVE_TAB:
      return {
        ...state,
        sessionsActiveTab: payload,
      };
    case userActionTypes.SET_RUN:
      return {
        ...state,
        run: payload,
      };
    case userActionTypes.HANDLE_FINISH_MODAL:
      return {
        ...state,
        finishModal: payload,
      };
    case userActionTypes.FETCH_CURRENT_USER_DETAILS_START:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case userActionTypes.FETCH_CURRENT_USER_DETAILS_SUCCESS:
      const cur_user = state.currentUser;
      const details = payload;
      return {
        ...state,
        isLoading: false,
        currentUser: { ...cur_user, ...details },
      };
    case userActionTypes.FETCH_CURRENT_USER_DETAILS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case userActionTypes.EDIT_USER_START:
      return {
        ...state,
        buttonLoading: true,
        error: null,
      };
    case userActionTypes.EDIT_USER_SUCCESS:
      return {
        ...state,
        buttonLoading: false,
        edit: false,
        wizard: false,
        error: null,
      };
    case userActionTypes.HANDLE_EDIT_USER:
      return {
        ...state,
        currentUser: { ...state.currentUser, ...payload },
        edit: true,
        wizard: true,
      };
    case userActionTypes.CREATE_EDIT_REQUEST_START:
      return {
        ...state,
        staffProfileRequest: payload,
        loading: true,
        saveButtonLoading: true,
        edit: true,
        wizard: false,
      };
    case userActionTypes.CREATE_EDIT_REQUEST_SUCCESS:
      return {
        ...state,
        editRequests: [...(state.editRequests || []), payload],
        loading: false,
        saveButtonLoading: false,
        edit: false,
        wizard: false,
      };
    case userActionTypes.CREATE_EDIT_REQUEST_FAILURE:
      return {
        ...state,
        edit: false,
        wizard: false,
        loading: false,
        saveButtonLoading: false,
        error: payload,
      };
    case userActionTypes.FETCH_STAFF_EDIT_REQUESTS_START:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case userActionTypes.FETCH_STAFF_EDIT_REQUESTS_SUCCESS:
      return {
        ...state,
        loading: false,
        editRequests: payload,
      };
    case userActionTypes.FETCH_STAFF_EDIT_REQUESTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case userActionTypes.OPEN_WIZARD_USER:
      return {
        ...state,
        wizard: payload,
      };
    case userActionTypes.RESET_EVERYTHING_USER:
      return {
        ...state,
        userBio: {},
        edit: false,
        wizard: false,
      };
    case userActionTypes.EDIT_USER_BIO:
      return {
        ...state,
        userBio: { ...state.userBio, ...payload },
        wizard: true,
      };
    case userActionTypes.VIEW_EDIT_REQUEST_ACTION:
      return {
        ...state,
        viewEditRequest: payload,
      };
    case userActionTypes.FETCH_USER_DOCS_START:
      return {
        ...state,
        loading: true,
      };
    case userActionTypes.FETCH_USER_DOCS_SUCCESS:
      return {
        ...state,
        loading: false,
        documents: payload,
      };
    case userActionTypes.FETCH_USER_DOCS_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case userActionTypes.DOWNLOAD_USER_DOCS_START:
      return {
        ...state,
        error: null,
      };
    case userActionTypes.DOWNLOAD_USER_DOCS_SUCCESS:
      return {
        ...state,
      };
    case userActionTypes.DOWNLOAD_USER_DOCS_FAIL:
      return {
        ...state,
        error: payload,
      };
    case userActionTypes.EDIT_CURRENT_USER_DETAILS:
      return {
        ...state,
        currentUser: { ...state.currentUser, ...payload },
      };
    case userActionTypes.SET_ACCESS_TOKEN:
      return {
        ...state,
        access_token: payload,
      };
    case userActionTypes.SET_REFRESH_TOKEN:
      return {
        ...state,
        refresh_token: payload,
      };
    case userActionTypes.SET_IS_REFRESHING_TOKEN:
      return {
        ...state,
        isRefreshingToken: payload,
      };
    case userActionTypes.SET_CURRENT_USER_DETAILS:
      return {
        ...state,
        currentUser: payload,
      };
    case userActionTypes.SET_ACTIVE_USER_TYPE:
      return {
        ...state,
        activeUserType: payload,
      };
    case userActionTypes.SET_USER_TYPES:
      return {
        ...state,
        userTypes: payload,
      };
    case userActionTypes.LOGOUT_USER:
      return {
        ...state,
        isLoggedIn: false,
        userTypes: [],
        activeUserType: '',
        currentUser: {},
        access_token: null,
        refresh_token: null,
        userTypeModal: false,
        isRefreshingToken: false,
      };
    case userActionTypes.SWITCH_USER_TYPE:
      return {
        ...state,
        isLoggedIn: true,
        activeUserType: payload?.user_type,
        currentUser: payload,
      };
    case userActionTypes.HANDLE_USER_TYPE_MODAL:
      return {
        ...state,
        userTypeModal: payload,
        isLoading: false,
      };

    case userActionTypes.RESET_SET_PASSWORD_START:
      return {
        ...state,
        isLoading: true,
      };
    case userActionTypes.RESET_SET_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tokenExpired: true,
        resetPassword: false,
      };
    case userActionTypes.RESET_SET_PASSWORD_FAILURE:
      return {
        ...state,
        isLoading: false,
        tokenExpired: true,
        resetPassword: true,
      };

    case userActionTypes.GOOGLE_AUTH_LOGIN_START:
      return {
        ...state,
      };
    case userActionTypes.GOOGLE_AUTH_LOGIN_SUCCESS:
      return {
        ...state,
      };
    case userActionTypes.GOOGLE_AUTH_LOGIN_FAILURE:
      return {
        ...state,
      };

    case userActionTypes.GET_USER_DETAILS_START:
      return {
        ...state,
        buttonLoading: true,
      };
    case userActionTypes.GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        buttonLoading: false,
        userDetails: payload,
      };
    case userActionTypes.GET_USER_DETAILS_FAILURE:
      return {
        ...state,
        buttonLoading: false,
      };

    case userActionTypes.CHANGE_USERNAME_START:
      return {
        ...state,
        buttonLoading: true,
      };
    case userActionTypes.CHANGE_USERNAME_SUCCESS:
      return {
        ...state,
        buttonLoading: false,
      };
    case userActionTypes.CHANGE_USERNAME_FAILURE:
      return {
        ...state,
        buttonLoading: false,
      };

    case userActionTypes.SEND_OTP_START:
      return {
        ...state,
        buttonLoading: true,
      };
    case userActionTypes.SEND_OTP_SUCCESS:
      return {
        ...state,
        buttonLoading: false,
      };
    case userActionTypes.SEND_OTP_FAILURE:
      return {
        ...state,
        buttonLoading: false,
      };

    case userActionTypes.SET_CURRENT_STAFF_SUBJECTS:
      return {
        ...state,
        currentStaffSubjects: payload,
      };
    case userActionTypes.SET_CURRENT_STAFF_ITEMS:
      return {
        ...state,
        currentStaffClasses: payload.currentStaffClasses,
        currentStaffSubjects: payload.currentStaffSubjects,
        currentStaffClassView: payload.currentStaffClassView,
      };
    case userActionTypes.SET_ACTIVE_CHILD_PAYMENT:
      return {
        ...state,
        activeChildPayment: payload,
      };
    case userActionTypes.UPDATE_ONBOARDING_STEPS_START:
      return {
        ...state,
        isLoading: true,
      };
    case userActionTypes.UPDATE_ONBOARDING_STEPS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case userActionTypes.UPDATE_ONBOARDING_STEPS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case userActionTypes.SET_LOGIN_ATTEMPTS:
      return {
        ...state,
        loginAttempts: payload,
        disableLogin: payload === 5 ? true : false,
        disableLoginTime: payload === 5 ? new Date().getTime() : null,
      };
    case userActionTypes.SET_DISABLE_LOGIN:
      return {
        ...state,
        disableLogin: payload,
      };
    case userActionTypes.SET_DISABLE_LOGIN_TIME:
      return {
        ...state,
        disableLoginTime: payload,
      };
    case userActionTypes.UPDATE_APPUSER_STATE:
      return {
        ...state,
        ...payload,
      };

    case userActionTypes.UPDATE_USER_DATA:
      return {
        ...state,
        currentUser: payload,
      };
    default:
      return state;
  }
}

const persistConfig = {
  key: 'users',
  storage,
  blacklist: [
    'error',
    'isLoading',
    'loading',
    'tokenExpired',
    'resetPassword',
    'userTypeModal',
    'accountVerificationModalOpen',
  ],
};

export default persistReducer(persistConfig, userReducer);
