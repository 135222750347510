import { put, takeLatest } from 'redux-saga/effects';
import invoiceSummariesActionTypes from '../types';
import { fetchInvoicesSummariesSuccess, fetchInvoicesSummariesFailure } from '../actions';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';

export function* fetchInvoicesSummaries({ payload }) {
  let endpoint = '/invoice-summaries';

  if (payload['class-level-id']) {
    endpoint = '/invoice-summaries/class-levels';
  }

  if (payload['arm-id']) {
    endpoint = '/invoice-summaries/class-arms';
  }
  try {
    const response = yield httpClient.get(endpoint, { params: payload });
    yield put(fetchInvoicesSummariesSuccess(response.data));
  } catch (error) {
    yield put(fetchInvoicesSummariesFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onfetchInvoicesSummariesStart() {
  yield takeLatest(
    invoiceSummariesActionTypes.FETCH_STUDENT_INVOICES_SUMMARIES_START,
    fetchInvoicesSummaries,
  );
}
