import storage from 'localforage';
import { persistReducer } from 'redux-persist';
import { findIndexInArray, InsertBeginning } from 'utils/utils';
import reportTemplateActionTypes from './types';

const INITIAL_STATE = {
  isLoading: false,
  buttonLoading: false,
  listOfReportTemplates: [],
};

function reportTemplateReducer(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case reportTemplateActionTypes.FETCH_REPORT_TEMPLATE_START:
      return {
        ...state,
        isLoading: true,
      };

    case reportTemplateActionTypes.FETCH_REPORT_TEMPLATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listOfReportTemplates: payload,
      };
    case reportTemplateActionTypes.FETCH_REPORT_TEMPLATE_FAIL:
      return {
        ...state,
        isLoading: false,
        error: null,
      };

    case reportTemplateActionTypes.CREATE_REPORT_TEMPLATE_START:
      return {
        ...state,
        buttonLoading: true,
      };

    case reportTemplateActionTypes.CREATE_REPORT_TEMPLATE_SUCCESS:
      const modifiedEntities = InsertBeginning(state.listOfReportTemplates, payload);
      return {
        ...state,
        buttonLoading: false,
        listOfReportTemplates: modifiedEntities,
      };

    case reportTemplateActionTypes.CREATE_REPORT_TEMPLATE_FAIL:
      return {
        ...state,
        buttonLoading: false,
        error: null,
      };

    case reportTemplateActionTypes.DELETE_REPORT_TEMPLATE_START:
      return {
        ...state,
        buttonLoading: true,
      };

    case reportTemplateActionTypes.DELETE_REPORT_TEMPLATE_SUCCESS:
      const index = findIndexInArray(state.listOfReportTemplates, { id: payload }, 'id');
      state.listOfReportTemplates.splice(index, 1);
      return {
        ...state,
        buttonLoading: false,
      };
    case reportTemplateActionTypes.DELETE_REPORT_TEMPLATE_FAIL:
      return {
        ...state,
        buttonLoading: false,
        error: null,
      };

    default:
      return state;
  }
}

const persistConfig = {
  key: 'report-template',
  storage,
  blacklist: ['error', 'isLoading', 'buttonLoading'],
};

export default persistReducer(persistConfig, reportTemplateReducer);
