export const TOP_RIGHT = 'top-right';
export const SUCCESS = 'success';
export const ERROR = 'error';

export const HOTJAR_SESSION = 'hotjar_session';

const url = window.location.href;
const id = url.replace(window.location.protocol + '//', '').split('.')[0];

const school_id =
  id.substring(0, 10) === 'localhost:'
    ? id.substring(0, 14) === 'localhost:5000'
      ? 'safsims'
      : 'ICS'
    : id;

export { school_id };
