import moment from 'moment';

export const reportData = {
  text: {
    school_name: 'Savannah College of Art and Design',
    motto: 'The university for Creative Careers',
    address: 'Savannah, GA 31402-2072 USA',
    email: 'scadatl@scad.edu',
    phone_number: '30357-1300',
    principal_name: 'Jon Snow',
    principal_signture: "PRINCIPAL'S SIGNTURE:",
    main_form_teacher: 'Makinwa Oladele',
    assistant_form_teacher: 'Faisal Saddiq',
    session: '2021-2022',
    term: 'First Term',
    class_level_name: 'JSS 1',
    full_class_name: 'JSS 1 Gold',
    arm_name: 'Gold',
    student_name: 'Taylor Manixa',
    student_id: 'STU/09/09',
    dob: moment('1999-11-01T23:00:00.000+00:00').format('YYYY-MM-DD'),
    age: 18,
    gender: 'Male or Female',
    height: '43',
    weight: '43',
    current_year: '2022',
    end_date: moment('2021-07-28T23:00:00.000+00:00').format('YYYY-MM-DD'),
    start_date: moment('2021-07-25T23:00:00.000+00:00').format('YYYY-MM-DD'),
    next_term_start_date: moment('2021-08-10T23:00:00.000+00:00').format('YYYY-MM-DD'),
    form_teacher_comment: 'This is a great result',
    principal_comment: 'Very good',
    grade: 'C',
    average_score: 74.86,
    highest_score: 89.25,
    lowest_score: 51.66,
    final_average: 70.3,
    total_score: '614.8',
    position: 5,
    out_of: 6,
    grade_details:
      'A1 = 80-100, B2 = 75-79, B3 = 70-74, C4 = 65-69, C5 = 60-64, C6 = 50-59, D7 = 45-49, E8 = 40.44, F9 = 0-39',
    no_of_subjects: 9,
    days_school_opened: '60',
    total_days_absent: '5',
    total_days_late: '5',
    total_days_present: '55',
    total_days_not_recorded: '55',
  },
  image: {
    logo: 'https://www.seekpng.com/png/full/267-2679652_scad-best-school-logo-designs.png',
    profile_picture:
      'https://safsims.s3.us-east-2.amazonaws.com/ICS/students/profile-pictures/STU_09_09.jpg',
    principal_signature:
      'https://res.cloudinary.com/alameen-ak/image/upload/v1654257035/principal_signature_ohdtce.jpg',
  },
  table: {
    result: {
      headers: [
        {
          name: 'Subjects',
          key: 'subject_name',
        },
        {
          name: 'CA 1',
          key: 'assessment1',
        },
        {
          name: 'CA 2',
          key: 'assessment2',
        },
        {
          name: 'CA 3',
          key: 'assessment3',
        },
        {
          name: 'CA 4',
          key: 'assessment4',
        },
        {
          name: 'Percentage',
          key: 'percentage',
        },
        {
          name: 'Total',
          key: 'final_average',
        },
        {
          name: 'POS',
          key: 'pos',
        },
        {
          name: 'Out Of',
          key: 'out_of',
        },
        {
          name: 'Low. In Class',
          key: 'lowest_score',
        },
        {
          name: 'High. In Class',
          key: 'highest_score',
        },
        {
          name: 'Class Average',
          key: 'average_score',
        },
        {
          name: 'Grade',
          key: 'grade',
        },
        {
          name: 'Comment',
          key: 'subject_comment',
        },
        {
          name: 'Remark',
          key: 'remark',
        },
      ],
      tableData: [
        {
          subject_name: 'Mathematics',
          assessment1: 10.22,
          assessment2: 13.5,
          assessment3: 10.22,
          assessment4: 25.41,
          percentage: '100%',
          final_average: 63.68,
          pos: '8',
          grade: 'D',
          out_of: 10,
          lowest_score: 3,
          highest_score: 89,
          average_score: 74,
          subject_comment: 'great',
          remark: 'great',
        },
        {
          subject_name: 'English',
          assessment1: 10.22,
          assessment2: 13.5,
          assessment3: 10.22,
          assessment4: 25.41,
          percentage: '100%',
          final_average: 63.68,
          pos: '8',
          grade: 'A',
          out_of: 10,
          lowest_score: 3,
          highest_score: 89,
          average_score: 74,
          subject_comment: 'Good',
          remark: 'great',
        },
        {
          subject_name: 'Biology',
          assessment1: 10.22,
          assessment2: 13.5,
          assessment3: 10.22,
          assessment4: 25.41,
          percentage: '100%',
          final_average: 63.68,
          pos: '8',
          grade: 'D',
          out_of: 10,
          lowest_score: 3,
          highest_score: 89,
          average_score: 74,
          subject_comment: 'great',
          remark: 'great',
        },
        {
          subject_name: 'Hausa',
          assessment1: 10.22,
          assessment2: 13.5,
          assessment3: 10.22,
          assessment4: 25.41,
          percentage: '100%',
          final_average: 63.68,
          pos: '8',
          grade: 'D',
          out_of: 10,
          lowest_score: 3,
          highest_score: 89,
          average_score: 74,
          subject_comment: 'great',
          remark: 'great',
        },
        {
          subject_name: 'Physics',
          assessment1: 10.22,
          assessment2: 13.5,
          assessment3: 10.22,
          assessment4: 25.41,
          percentage: '100%',
          final_average: 63.68,
          pos: '8',
          grade: 'A',
          out_of: 10,
          lowest_score: 3,
          highest_score: 89,
          average_score: 74,
          subject_comment: 'Good',
          remark: 'great',
        },
        {
          subject_name: 'Geography',
          assessment1: 10.22,
          assessment2: 13.5,
          assessment3: 10.22,
          assessment4: 25.41,
          percentage: '100%',
          final_average: 63.68,
          pos: '-',
          grade: 'A',
          out_of: 10,
          lowest_score: 3,
          highest_score: 89,
          average_score: 74,
          subject_comment: 'Good',
          remark: 'great',
        },
      ],
    },

    cumulative_report_sheet: {
      headers: [
        {
          name: 'Subjects',
        },
        {
          name: '1st Term',
        },
        {
          name: '2nd Term',
        },
        {
          name: '3rd Term',
        },
        {
          name: 'Total',
        },
        {
          name: 'Grade',
        },
        {
          name: 'POS',
        },
        {
          name: 'Out Of',
        },
        {
          name: 'Low. In Class',
        },
        {
          name: 'High. In Class',
        },
        {
          name: 'Class Average',
        },

        {
          name: 'Comment',
        },
      ],
      tableData: [
        {
          subject_name: 'Mathematics',
          assessment1: 70,
          assessment2: 79,
          assessment3: 90,
          final_average: 63.68,
          grade: 'D',
          pos: '8',
          out_of: 10,
          lowest_score: 3,
          highest_score: 89,
          average_score: 74,
          comment: 'great',
        },
        {
          subject_name: 'English',
          assessment1: 70,
          assessment2: 79,
          assessment3: 90,
          final_average: 63.68,
          grade: 'D',
          pos: '8',
          out_of: 10,
          lowest_score: 3,
          highest_score: 89,
          average_score: 74,
          comment: 'great',
        },
        {
          subject_name: 'Biology',
          assessment1: 70,
          assessment2: 79,
          assessment3: 90,
          final_average: 63.68,
          grade: 'D',
          pos: '8',
          out_of: 10,
          lowest_score: 3,
          highest_score: 89,
          average_score: 74,
          comment: 'great',
        },
        {
          subject_name: 'Hausa',
          assessment1: 70,
          assessment2: 79,
          assessment3: 90,
          final_average: 63.68,
          grade: 'D',
          pos: '8',
          out_of: 10,
          lowest_score: 3,
          highest_score: 89,
          average_score: 74,
          comment: 'great',
        },
        {
          subject_name: 'Physics',
          assessment1: 70,
          assessment2: 79,
          assessment3: 90,
          final_average: 63.68,
          grade: 'D',
          pos: '8',
          out_of: 10,
          lowest_score: 3,
          highest_score: 89,
          average_score: 74,
          comment: 'great',
        },
        {
          subject_name: 'Geography',
          assessment1: 70,
          assessment2: 79,
          assessment3: 90,
          final_average: 63.68,
          grade: 'D',
          pos: '8',
          out_of: 10,
          lowest_score: 3,
          highest_score: 89,
          average_score: 74,
          comment: 'great',
        },
      ],
    },

    early_year_results: {
      headers: [
        {
          name: 'Subjects',
        },
        {
          name: 'Score',
        },
      ],
      tableData: [
        {
          trait: 'Literacy 1',
          subtraits: [
            {
              name: 'Can play',
              score: 'B',
            },
            {
              name: 'Can swing',
              score: 'B',
            },
            {
              name: 'Can dance',
              score: 'C',
            },
            {
              name: 'Can sing the alphabet',
              score: 'A',
            },
            {
              name: 'Can pronounce 10 fruits',
              score: 'A',
            },
          ],
        },
        {
          trait: 'Numeracy',
          subtraits: [
            {
              name: 'Can shout',
              score: 'D',
            },
            {
              name: 'Can count',
              score: 'B',
            },
            {
              name: 'Can do additions',
              score: 'C',
            },
          ],
        },
        {
          trait: 'Playtime',
          subtraits: [
            {
              name: 'Can play chess',
              score: 'A',
            },
            {
              name: 'Can play ludo',
              score: 'E',
            },
          ],
        },
      ],
    },

    skills1: {
      headers: [
        {
          name: 'Skill 1',
        },
        {
          name: 'Rating',
        },
      ],
      tableData: [
        {
          skill: 'Leadership',
          rating: 'A',
        },
        {
          skill: 'Programming',
          rating: 'A',
        },
        {
          skill: 'Something',
          rating: 'F',
        },
      ],
    },
    skills2: {
      headers: [
        {
          name: 'Skill 2',
        },
        {
          name: 'Rating',
        },
      ],
      tableData: [
        {
          skill: 'Leadership',
          rating: 'A',
        },
        {
          skill: 'Programming',
          rating: 'A',
        },
        {
          skill: 'Something',
          rating: 'F',
        },
      ],
    },
    scale: {
      headers: [
        {
          name: 'SCALE',
        },
      ],
      tableData: [
        {
          scale: '5 - Excellent Degree of Observable Trait',
        },
        {
          scale: '4 - Good Level of Observable Trait',
        },
        {
          scale: '3 - Fair but Acceptable Level of Observable Trait',
        },
        {
          scale: '2 - Poor Level of Observable Trait',
        },
        {
          scale: '1 - No Observable Trait',
        },
      ],
    },

    attendance: {
      headers: [
        { name: 'Months' },
        { name: 'Present' },
        { name: 'Absent' },
        { name: 'Late' },
        { name: 'Not Recorded' },
      ],
      tableData: [
        {
          month: 'January',
          days_present: 0,
          days_absent: 0,
          days_late: 0,
          days_not_recorded: 3,
        },
        {
          month: 'Febuary',
          days_present: 0,
          days_absent: 0,
          days_late: 0,
          days_not_recorded: 3,
        },
        {
          month: 'March',
          days_present: 0,
          days_absent: 0,
          days_late: 0,
          days_not_recorded: 3,
        },
        {
          month: 'April',
          days_present: 0,
          days_absent: 0,
          days_late: 0,
          days_not_recorded: 3,
        },
      ],
    },
  },
};
