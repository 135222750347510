import { all, call } from 'redux-saga/effects';
import { onAddPayableItem } from './addPayableItem';
import { onAddStudentComment } from './addStudentComment';
import { onFetchTermDefinitionStart } from './allTermDefinition';
import { onAssignStudentStart } from './assignStudent';
import { onCreateArmStart } from './createClassArm';
import { onCreateInfoStart } from './createClassInfo';
import { onCreatingClassStart } from './createClassLevel';
import { onCreateNewSessionStart } from './createNewSession';
import { onCreatingTermDefinitionStart } from './createTermDefinition';
import { onGetcurrentTerm } from './currentTerm';
import { onDeleteArmStart } from './deleteArms';
import { onDeleteClassStart } from './deleteClassLevel';
import { onDeletePayableItemStart } from './deletePayableItem';
import { onDeleteTermStart } from './deleteTerm';
import { onEditArmStart } from './editArms';
import { onEditClassLevelStart } from './editClassLevel';
import { onEditPayableItem } from './editPayableItem';
import { onEditTermDefinitionStart } from './editTermSaga';
import { onFetchActiveArmsStart } from './fetchActiveArms';
import { onFetchAllExistingTermsStart } from './fetchAllExistingTerms';
import { onFetchArmsStart } from './fetchArms';
import { onFetchingChildAttendanceSummaryStart } from './fetchChildAttandanceSummary';
import { onFetchingChildResultStart } from './fetchChildResultSaga';
import { onFetchingChildSkillsResultStart } from './fetchChildSkillsResult';
import { onFetchClassInfoStart } from './fetchClassInfo';
import { onFetchClassInfoArmIDStart } from './fetchClassInfoArmID';
import { onFetchClassLevelStart } from './fetchClassLevel';
import { onFetchClassLevelTraits } from './fetchClassLevelTraits';
import { onFetchHomepageStart } from './fetchHompageInfo';
import { onFetchPayableItems } from './fetchPayableItems';
import { onFetchSchoolConfigStart } from './fetchSchool';
import { onFilterPayableItemsStart } from './filterPayableItems';
import { onGetApproveResults } from './getApproveResults';
import { onGetClassMemberStart } from './getClassMembers';
import { onGetSessionsStart } from './getSession';
import { onGetStudentComments } from './getStudentComments';
import { onGetStudentResults } from './getStudentResults';
import { onGetStudentTraitAssessment } from './getStudentTraitAssessment';
import { onfetchTermStart } from './getTerm';
import { onGetTermProfileAssessmentFormat } from './getTermProfileAssessmentFormat';
import { onGetTermProfileClassInfo } from './getTermProfileClassInfo';
import { onGetTermProfileDateRange } from './getTermProfileDateRange';
import { onGetTermProfileEarlyYears } from './getTermProfileEarlyYears';
import { onGetTermProfileGradeFormat } from './getTermProfileGradeFormat';
import { onGetTermProfileRatings } from './getTermProfileRatings';
import { onGetTermProfileSkillGroup } from './getTermProfileSkillGroup';
import { onFetchLevelsWithArmsStart } from './levelsWithArmsSaga';
import { onRemoveStudentStart } from './removeStudent';
import { onSaveStudentTraitAssessment } from './saveStudentTraitAssessment';
import { onSetCurrentTermStart } from './setCurrentTerm';
import { onConfigureSchoolStart } from './setSchoolSaga';
import { onSetupTermProfile } from './setupTermProfile';
import { onSubmitApprovalResults } from './submitApprovalResults';
import { onTermProfileSetDateRange } from './termProfileDateRange';
import { onTermProfileSetAssessmentFormat } from './termProfileSetAssessmentFormat';
import { onTermProfileSetClassInfo } from './termProfileSetClassInfo';
import { onTermProfileSetEarlyYears } from './termProfileSetEarlyYears';
import { onTermProfileSetGradeFormat } from './termProfileSetGradeFormat';
import { onTermProfileSetRatings } from './termProfileSetRatings';
import { onTransferStudentsAnotherArm } from './transferStudentsToAnotherArm';
import { onUploadSchoolLogoStart } from './uploadLogo';
import { onUploadSignature } from './uploadSignature';

export default function* ConfigSaga() {
  yield all([
    call(onConfigureSchoolStart),
    call(onCreatingClassStart),
    call(onFetchClassLevelStart),
    call(onEditClassLevelStart),
    call(onDeleteClassStart),
    call(onCreateArmStart),
    call(onFetchArmsStart),
    call(onDeleteArmStart),
    call(onEditArmStart),
    call(onFetchSchoolConfigStart),
    call(onCreateInfoStart),
    call(onFetchClassInfoStart),
    call(onFetchPayableItems),
    call(onAddPayableItem),
    call(onEditPayableItem),
    call(onDeletePayableItemStart),
    call(onFilterPayableItemsStart),
    call(onfetchTermStart),
    call(onGetClassMemberStart),
    call(onAssignStudentStart),
    call(onGetSessionsStart),
    call(onRemoveStudentStart),
    call(onUploadSchoolLogoStart),
    call(onFetchHomepageStart),
    call(onGetcurrentTerm),
    call(onFetchActiveArmsStart),
    call(onCreatingTermDefinitionStart),
    call(onFetchTermDefinitionStart),
    call(onEditTermDefinitionStart),
    call(onFetchClassInfoArmIDStart),
    call(onDeleteTermStart),
    call(onCreateNewSessionStart),
    call(onFetchAllExistingTermsStart),
    call(onSetCurrentTermStart),
    call(onFetchClassLevelTraits),
    call(onGetStudentTraitAssessment),
    call(onSaveStudentTraitAssessment),

    call(onGetTermProfileAssessmentFormat),
    call(onGetTermProfileClassInfo),
    call(onGetTermProfileDateRange),
    call(onGetTermProfileEarlyYears),
    call(onGetTermProfileRatings),
    call(onGetTermProfileGradeFormat),

    call(onTermProfileSetDateRange),
    call(onTermProfileSetAssessmentFormat),
    call(onTermProfileSetClassInfo),
    call(onTermProfileSetEarlyYears),
    call(onTermProfileSetGradeFormat),
    call(onTermProfileSetRatings),
    call(onGetTermProfileSkillGroup),

    call(onSetupTermProfile),
    call(onAddStudentComment),
    call(onGetStudentComments),
    call(onGetApproveResults),
    call(onSubmitApprovalResults),
    call(onFetchLevelsWithArmsStart),
    call(onFetchingChildResultStart),
    call(onFetchingChildSkillsResultStart),
    call(onGetStudentResults),
    call(onTransferStudentsAnotherArm),
    call(onFetchingChildAttendanceSummaryStart),
    call(onUploadSignature),
  ]);
}
