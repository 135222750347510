import { put, takeLatest } from 'redux-saga/effects';
import httpClient from 'utils/http-client';
import { handleError } from 'utils/utils';
import { fetchStudentIncoiceSummaryFail, fetchStudentIncoiceSummarySuccess } from '../actions';
import invoiceActionTypes from '../types';

export function* getStudentInvoiceSummary({ payload }) {
  try {
    const response = yield httpClient.get(`/invoice-summaries/${payload}`);
    yield put(fetchStudentIncoiceSummarySuccess(response.data));
  } catch (error) {
    yield put(fetchStudentIncoiceSummaryFail(error?.message));
    const err = handleError(error);
  }
}

export function* onGetStudentInvoiceSummary() {
  yield takeLatest(
    invoiceActionTypes.FETCH_STUDENT_INVOICE_SUMMARY_START,
    getStudentInvoiceSummary,
  );
}
