import { put, takeLatest } from 'redux-saga/effects';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';
import { makeBulkPaymentFail, makeBulkPaymentSuccess } from '../actions';
import invoiceActionTypes from '../types';

export function* makeBulkPayment({ payload }) {
  try {
    const { callback } = payload;
    delete payload.callback;
    const response = yield httpClient.post(`/payments/online/bulk`, payload);
    yield put(makeBulkPaymentSuccess(response.data));
    if (payload.payment_method === 'PAYSTACK') {
      callback({
        reference: response.data.safsims_transaction_ref,
        subaccounts: response.data.fees_payments.map((item) => ({
          subaccount: item.subaccount_ref,
          share: parseInt(item.amount) * 100,
        })),
      });
    } else {
      window.location.href = response.data.link;
    }
  } catch (error) {
    yield put(makeBulkPaymentFail(error?.message));
    const err = handleError(error);
  }
}

export function* onMakeBulkPaymentStart() {
  yield takeLatest(invoiceActionTypes.MAKE_BULK_PAYMENT_START, makeBulkPayment);
}
