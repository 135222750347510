import earlyYearsActionTypes from './types';
import { FIX_ANY } from 'utils/types';

export const fetchAllTraitGroupStart = () => ({
  type: earlyYearsActionTypes.FETCH_TRAIT_GROUP_START,
});

export const fetchAllTraitGroupSuccess = (data) => ({
  type: earlyYearsActionTypes.FETCH_TRAIT_GROUP_SUCCESS,
  payload: data,
});

export const fetchAllTraitGroupFail = (error) => ({
  type: earlyYearsActionTypes.FETCH_TRAIT_GROUP_FAIL,
  payload: error,
});

export const addTraitGroupStart = (data) => ({
  type: earlyYearsActionTypes.ADD_TRAIT_GROUP_START,
  payload: data,
});

export const addTraitGroupSuccess = (data: FIX_ANY[]) => ({
  type: earlyYearsActionTypes.ADD_TRAIT_GROUP_SUCCESS,
  payload: data,
});

export const addTraitGroupFail = (error) => ({
  type: earlyYearsActionTypes.ADD_TRAIT_GROUP_FAIL,
  payload: error,
});

export const editSingleTraitStart = (data) => ({
  type: earlyYearsActionTypes.EDIT_SINGLE_TRAIT_START,
  payload: data,
});

export const editSingleTraitSuccess = (data) => ({
  type: earlyYearsActionTypes.EDIT_SINGLE_TRAIT_SUCCESS,
  payload: data,
});

export const editSingleTraitFail = (error) => ({
  type: earlyYearsActionTypes.EDIT_SINGLE_TRAIT_FAIL,
  payload: error,
});

export const deleteSingleTraitStart = (data) => ({
  type: earlyYearsActionTypes.DELETE_SINGLE_TRAIT_START,
  payload: data,
});

export const deleteSingleTraitSuccess = (data) => ({
  type: earlyYearsActionTypes.DELETE_SINGLE_TRAIT_SUCCESS,
  payload: data,
});

export const deleteSingleTraitFail = (error) => ({
  type: earlyYearsActionTypes.DELETE_SINGLE_TRAIT_FAIL,
  payload: error,
});

export const addSingleTraitStart = (data) => ({
  type: earlyYearsActionTypes.ADD_SINGLE_TRAIT_START,
  payload: data,
});

export const addSingleTraitSuccess = (data) => ({
  type: earlyYearsActionTypes.ADD_SINGLE_TRAIT_SUCCESS,
  payload: data,
});

export const addSingleTraitFail = (error) => ({
  type: earlyYearsActionTypes.ADD_SINGLE_TRAIT_FAIL,
  payload: error,
});

export const handleAddTraitModalAction = (addModal) => ({
  type: earlyYearsActionTypes.HANDLE_ADD_TRAIT_MODAL,
  payload: addModal,
});

export const addSingleDefinitionStart = (data) => ({
  type: earlyYearsActionTypes.ADD_SINGLE_DEFINITION_START,
  payload: data,
});

export const addSingleDefinitionSuccess = (data) => ({
  type: earlyYearsActionTypes.ADD_SINGLE_DEFINITION_SUCCESS,
  payload: data,
});

export const addSingleDefinitionFail = (error) => ({
  type: earlyYearsActionTypes.ADD_SINGLE_DEFINITION_FAIL,
  payload: error,
});

export const editSingleDefinitionStart = (data) => ({
  type: earlyYearsActionTypes.EDIT_SINGLE_DEFINITION_START,
  payload: data,
});

export const editSingleDefinitionSuccess = (data) => ({
  type: earlyYearsActionTypes.EDIT_SINGLE_DEFINITION_SUCCESS,
  payload: data,
});

export const editSingleDefinitionFail = (error) => ({
  type: earlyYearsActionTypes.EDIT_SINGLE_DEFINITION_FAIL,
  payload: error,
});

export const deleteSingleDefinitionStart = (data) => ({
  type: earlyYearsActionTypes.DELETE_SINGLE_DEFINITION_START,
  payload: data,
});

export const deleteSingleDefinitionSuccess = (data) => ({
  type: earlyYearsActionTypes.DELETE_SINGLE_DEFINITION_SUCCESS,
  payload: data,
});

export const deleteSingleDefinitionFail = (error) => ({
  type: earlyYearsActionTypes.DELETE_SINGLE_DEFINITION_FAIL,
  payload: error,
});

export const addRatingStart = (data) => ({
  type: earlyYearsActionTypes.ADD_RATING_START,
  payload: data,
});

export const addRatingSuccess = (data) => ({
  type: earlyYearsActionTypes.ADD_RATING_SUCCESS,
  payload: data,
});

export const addRatingFail = (error) => ({
  type: earlyYearsActionTypes.ADD_RATING_FAIL,
  payload: error,
});

export const handleAddRatingModalAction = (ratingModal) => ({
  type: earlyYearsActionTypes.HANDLE_ADD_RATING_MODAL,
  payload: ratingModal,
});

export const fetchRatingStart = () => ({
  type: earlyYearsActionTypes.FETCH_RATING_START,
});

export const fetchRatingSuccess = (data: FIX_ANY[]) => ({
  type: earlyYearsActionTypes.FETCH_RATING_SUCCESS,
  payload: data,
});

export const fetchRatingFail = (error) => ({
  type: earlyYearsActionTypes.FETCH_RATING_FAIL,
  payload: error,
});

export const editRatingStart = (id: string, data) => ({
  type: earlyYearsActionTypes.EDIT_RATING_START,
  payload: { id, data },
});

export const editRatingSuccess = (data) => ({
  type: earlyYearsActionTypes.EDIT_RATING_SUCCESS,
  payload: data,
});

export const editRatingFail = (error) => ({
  type: earlyYearsActionTypes.EDIT_RATING_FAIL,
  payload: error,
});

export const deleteRatingStart = (id: string, forceDelete: boolean) => ({
  type: earlyYearsActionTypes.DELETE_RATING_START,
  payload: { id, forceDelete },
});

export const deleteRatingSuccess = (data) => ({
  type: earlyYearsActionTypes.DELETE_RATING_SUCCESS,
  payload: data,
});

export const deleteRatingFail = (error) => ({
  type: earlyYearsActionTypes.DELETE_RATING_FAIL,
  payload: error,
});

export const handleDeleteRatingModalAction = (deleteRatingModal: boolean) => ({
  type: earlyYearsActionTypes.HANDLE_DELETE_RATING_MODAL,
  payload: deleteRatingModal,
});

export const handleEditRatingModalAction = (editRating) => ({
  type: earlyYearsActionTypes.HANDLE_EDIT_RATING_MODAL,
  payload: editRating,
});

export const deleteSingleRatingStart = (id: string) => ({
  type: earlyYearsActionTypes.DELETE_SINGLE_RATING_START,
  payload: id,
});

export const deleteSingleRatingSuccess = (data) => ({
  type: earlyYearsActionTypes.DELETE_SINGLE_RATING_SUCCESS,
  payload: data,
});

export const deleteSingleRatingFail = (error) => ({
  type: earlyYearsActionTypes.DELETE_SINGLE_RATING_FAIL,
  payload: error,
});

export const editSingleRatingStart = (id: string, data) => ({
  type: earlyYearsActionTypes.EDIT_SINGLE_RATE_START,
  payload: { id, data },
});

export const editSingleRatingSuccess = (data) => ({
  type: earlyYearsActionTypes.EDIT_SINGLE_RATE_SUCCESS,
  payload: data,
});

export const editSingleRatingFail = (error) => ({
  type: earlyYearsActionTypes.EDIT_SINGLE_RATE_FAIL,
  payload: error,
});
