import { takeLatest, put } from 'redux-saga/effects';
import { fetchStudentInvoicesSuccess, fetchStudentInvoicesFail } from '../actions';
import httpClient from 'utils/http-client';
import invoiceActionTypes from '../types';

export function* fetchStudentInvoices({ payload }) {
  if (!payload['term-id']) {
    yield put(fetchStudentInvoicesFail());
  } else {
    try {
      const id = payload['student-id'];
      delete payload['student-id'];
      const response = yield httpClient.get(`/students-invoice/${id}/`, { params: payload });
      yield put(fetchStudentInvoicesSuccess(response.data));
    } catch (error) {
      yield put(fetchStudentInvoicesFail(error?.message));
    }
  }
}

export function* onFetchStudentInvoices() {
  yield takeLatest(invoiceActionTypes.FETCH_STUDENT_INVOICES_START, fetchStudentInvoices);
}
