import { BugsnagErrorBoundary } from '@bugsnag/plugin-react';
import { ErrorBoundaryComponent } from 'ErrorBoundary';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BugsnagClient } from 'utils/Bugsnag';
import { initializeFullstory } from 'utils/Fullstory';
import { initializeGoogleAnalytics } from 'utils/GoogleAnalytics';
import { initializeHotjar } from 'utils/Hotjar';
import App from './App';
import './assets/fonts/fontello/css/saf.css';
import { persistor, store } from './redux/store';
import { handleAccessToken, instanceInterceptors } from 'utils/http-client';
import { initializeServiceWorker, updateServiceWorker } from './redux/service-workers/actions';
import * as serviceWorker from './serviceWorker';

const ErrorBoundary: BugsnagErrorBoundary & { children?: React.ReactNode } =
  BugsnagClient.getPlugin('react')!.createErrorBoundary(React);

const container = document.getElementById('root');

initializeGoogleAnalytics();
initializeHotjar();
initializeFullstory();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ErrorBoundary FallbackComponent={ErrorBoundaryComponent}>
        <PersistGate persistor={persistor}>
          <App />
        </PersistGate>
      </ErrorBoundary>
    </Provider>
  </React.StrictMode>,
  container,
);

handleAccessToken();
instanceInterceptors(store);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

// serviceWorker.register({
//   onSuccess: ({ registration, serviceWorkerActive }) => {
//     console.log({ success_sw_reg: registration, serviceWorkerActive });
//     store.dispatch(initializeServiceWorker({ registration, serviceWorkerActive }));
//   },
//   onUpdate: (registration) => {
//     console.log({ update_sw_reg: registration });
//     store.dispatch(updateServiceWorker(registration));
//   },
// });
serviceWorker.unregister();
