import { put, takeLatest } from 'redux-saga/effects';
import httpClient from 'utils/http-client';
import { handleError } from 'utils/utils';
import { fetchStudentResultDataFail, fetchStudentResultDataSuccess } from '../actions';
import broadSheetTypes from '../types';

export function* fetchStudentResultData({ payload }) {
  const params = {
    'student-id': payload.studentId,
    'term-id': payload.termId,
    'result-config-id': payload.configId,
    'include-unapproved': true,
  };

  const url = payload.cumulative ? '/student-results/cumulative/report' : '/student-results/report';

  try {
    const response = yield httpClient.get(url, { params: params });
    yield put(fetchStudentResultDataSuccess(response.data));
  } catch (error) {
    yield put(fetchStudentResultDataFail(error?.message));
    const err = handleError(error);
  }
}

export function* onFetchStudentReportDataStart() {
  yield takeLatest(broadSheetTypes.FETCH_STUDENT_RESULT_DATA_START, fetchStudentResultData);
}
