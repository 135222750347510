import { put, takeLatest } from 'redux-saga/effects';
import configActionTypes from '../types';
import httpClient from 'utils/http-client';
import { getCurrentTermSuccess, getCurrentTermFail } from '../actions';

export function* getcurrentTerm() {
  try {
    const response = yield httpClient.get('terms/current-term');
    yield put(getCurrentTermSuccess(response.data));
  } catch (error) {
    yield put(getCurrentTermFail(error?.message));
  }
}

export function* onGetcurrentTerm() {
  yield takeLatest(configActionTypes.GET_CURRENT_TERM_START, getcurrentTerm);
}
