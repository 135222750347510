enum classInformationTypes {
  FETCH_LIST_OF_CLASS_INFORMATION_START = 'FETCH_LIST_OF_CLASS_INFORMATION_START',
  FETCH_LIST_OF_CLASS_INFORMATION_SUCCESS = 'FETCH_LIST_OF_CLASS_INFORMATION_SUCCESS',
  FETCH_LIST_OF_CLASS_INFORMATION_FAILURE = 'FETCH_LIST_OF_CLASS_INFORMATION_FAILURE',

  HANDLE_ASSIGN_FORM_TEACHER_MODAL = 'HANDLE_ASSIGN_FORM_TEACHER_MODAL',

  FETCH_ALL_TEACHERS_START = 'FETCH_ALL_TEACHERS_START',
  FETCH_ALL_TEACHERS_SUCCESS = 'FETCH_ALL_TEACHERS_SUCCESS',
  FETCH_ALL_TEACHERS_FAIL = 'FETCH_ALL_TEACHERS_FAIL',

  ASSIGN_FORM_TEACHERS_START = 'ASSIGN_FORM_TEACHERS_START',
  ASSIGN_FORM_TEACHERS_SUCCCESS = 'ASSIGN_FORM_TEACHERS_SUCCESS',
  ASSIGN_FORM_TEACHERS_FAIL = 'ASSIGN_FORM_TEACHERS_FAIL',

  UPDATE_CLASS_INFORMATION_STATE = 'UPDATE_CLASS_INFORMATION_STATE',
}

export default classInformationTypes;
