import { put, takeLatest } from 'redux-saga/effects';
import staffActionTypes from '../types';
import { fetchStaffsFailure, fetchStaffsSuccess } from '../actions';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';

export function* fetchStaffs({ payload }) {
  try {
    const response = yield httpClient.get('/staff', { params: payload });
    yield put(fetchStaffsSuccess(response.data));
  } catch (error) {
    yield put(fetchStaffsFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onFetchStaffsStart() {
  yield takeLatest(staffActionTypes.FETCH_STAFFS_START, fetchStaffs);
}
