import { OnboardingStepsInfo } from 'components/onboarding/StepsInfo';
import storage from 'localforage';
import { persistReducer } from 'redux-persist';
import { findIndexInArray, InsertBeginning } from 'utils/utils';
import configActionTypes from './types';

const INITIAL_STATE = {
  schoolSettings: {},
  buttonLoading: false,
  error: null,
  deleteModal: false,
  createModal: false,
  classLevelsList: [],
  dataloading: false,
  edit: false,
  classLevelData: {},
  levelId: null,
  editData: {},
  total: 0,
  modal: false,
  deleteArmModal: false,
  classArmList: [],
  totalArms: 0,
  editArm: false,
  classArmData: {},
  armId: null,
  arm: false,
  schoolInfo: {},
  schoolId: '',
  payableItemsList: [],
  payableItemData: {},
  payableItemModal: false,
  editPayableItem: false,
  deletePayableItemModal: false,
  payableItemId: '',
  loading: false,
  termInfo: [],
  classMembers: [],
  students: [],
  classPopulation: 0,
  studentModal: false,
  sessionsInfo: [],
  removeStudentModal: false,
  classInfo: {},
  homePageInfo: {},
  currentTerm: '',
  currentTermUUID: '',
  currentSession: '',
  currentTermDescription: '',
  currentSessionObject: {},
  termStartDate: '',
  listOfArms: [],
  listOfActiveArms: [],
  isLoading: false,
  currentClassLevelId: '',
  classLevelProfileData: {},
  termDefinitionList: [],
  addTermModal: false,
  editTerm: false,
  deleteTermModal: false,
  dataToEdit: {},
  createSessionModal: false,
  allExistingTerms: [],
  path: '',
  classLevelTraits: [],
  classLevelTraitsRating: [],
  gettingAssessment: false,
  currentStudentAssessment: null,
  traits: [],
  parentChildTrait: [],
  studentComments: [],
  approveResults: [],
  getApproveResultLoading: false,
  listOfClassLevelsWithArms: [],
  levelDataLoading: false,
  childResult: {},
  studentResults: {},
  studentSkillsResults: [],
  studentTransferModal: false,
  transferLoading: false,
  termSetupLoading: false,
  loadingClassMembers: false,
  onboardingInfo: OnboardingStepsInfo,
  isOnboarding: false,
  refetchAttendance: 0,
  childAttendanceSummary: {
    monthly_attendance: [],
  },
  bulkModal: false,
  deactivateModal: false,
  currentTermObject: {},
  activityModal: false,
  deleteActivityModal: false,
  activityData: {},
  currentTermDTO: undefined,
  noGradingFormat: false,
  countryList: [],
};

function configReducer(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case configActionTypes.MANAGE_SCHOOL_INFO_START:
      return {
        ...state,
        buttonLoading: true,
      };
    case configActionTypes.MANAGE_SCHOOL_INFO_SUCCESS:
      return {
        ...state,
        schoolSettings: payload,
        buttonLoading: false,
        schoolId: state.schoolSettings.school_id,
      };
    case configActionTypes.MANAGE_SCHOOL_INFO_FAIL:
      return {
        ...state,
        buttonLoading: false,
        error: payload,
      };

    case configActionTypes.FETCH_ACTIVE_ARMS_START:
      return {
        ...state,
        isLoading: true,
      };
    case configActionTypes.FETCH_ACTIVE_ARMS_SUCCESS:
      return {
        ...state,
        listOfActiveArms: payload,
        isLoading: false,
      };
    case configActionTypes.FETCH_ACTIVE_ARMS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };

    case configActionTypes.FETCH_CLASS_INFORMATION_ARM_ID_START:
      return {
        ...state,
        dataloading: true,
        path: payload.path,
      };
    case configActionTypes.FETCH_CLASS_INFORMATION_ARM_ID_SUCCESS:
      return {
        ...state,
        classInfo: payload[0],
        dataloading: false,
      };
    case configActionTypes.FETCH_CLASS_INFORMATION_ARM_ID_FAIL:
      return {
        ...state,
        dataloading: false,
        error: payload,
      };

    case configActionTypes.CREATE_CLASS_INFORMATION_START:
      return {
        ...state,
        currentClassLevelId: payload.class_level_id,
      };
    case configActionTypes.CREATE_CLASS_INFORMATION_SUCCESS:
      return {
        ...state,
        currentClassLevelId: '',
      };
    case configActionTypes.CREATE_CLASS_INFORMATION_FAIL:
      return {
        ...state,
        currentClassLevelId: '',
        error: payload,
      };

    case configActionTypes.HANDLE_CREATE_CLASS_LEVEL_MODAL:
      return {
        ...state,
        createModal: payload,
      };
    case configActionTypes.CREATE_CLASS_LEVEL_START:
      return {
        ...state,
        buttonLoading: true,
        edit: false,
      };
    case configActionTypes.CREATE_CLASS_LEVEL_SUCCESS:
      let { listOfClassLevelsWithArms } = state;
      listOfClassLevelsWithArms.push(payload);
      return {
        ...state,
        buttonLoading: false,
        createModal: false,
        listOfClassLevelsWithArms,
        edit: false,
        classLevelData: {},
      };
    case configActionTypes.CREATE_CLASS_LEVEL_FAIL:
      return {
        ...state,
        buttonLoading: false,
        error: payload,
        createModal: true,
      };
    case configActionTypes.FETCH_CLASS_LEVEL_START:
      return {
        ...state,
        dataloading: true,
      };
    case configActionTypes.FETCH_CLASS_LEVEL_SUCCESS:
      return {
        ...state,
        dataloading: false,
        classLevelsList: payload,
        total: payload.total_elements,
      };
    case configActionTypes.FETCH_CLASS_LEVEL_FAIL:
      return {
        ...state,
        dataloading: false,
        error: payload,
      };

    case configActionTypes.FETCH_ALL_ARMS_START:
      return {
        ...state,
        dataloading: true,
      };
    case configActionTypes.FETCH_ALL_ARMS_SUCCESS:
      return {
        ...state,
        dataloading: false,
        listOfArms: payload,
      };
    case configActionTypes.FETCH_ALL_ARMS_FAIL:
      return {
        ...state,
        dataloading: false,
        error: payload,
      };

    case configActionTypes.CREATE_ARM_START:
      return {
        ...state,
        buttonLoading: true,
      };
    case configActionTypes.CREATE_ARM_SUCCESS:
      const createdEntities = InsertBeginning(state.listOfArms, payload);
      return {
        ...state,
        buttonLoading: false,
        modal: false,
        listOfArms: createdEntities,
      };
    case configActionTypes.CREATE_ARM_FAIL:
      return {
        ...state,
        buttonLoading: false,
        error: payload,
      };

    case configActionTypes.EDIT_ARM_START:
      return {
        ...state,
        buttonLoading: true,
      };
    case configActionTypes.EDIT_ARM_SUCCESS:
      const indexOfEditedArm = findIndexInArray(state.listOfArms, payload, 'id');
      state.listOfArms.splice(indexOfEditedArm, 1, payload);
      return {
        ...state,
        buttonLoading: false,
        modal: false,
        editArm: false,
      };
    case configActionTypes.EDIT_ARM_FAIL:
      return {
        ...state,
        buttonLoading: false,
        error: payload,
      };

    case configActionTypes.DELETE_ARM_START:
      return {
        ...state,
        buttonLoading: true,
      };
    case configActionTypes.DELETE_ARM_SUCCESS:
      const indexOfDeleteArm = findIndexInArray(state.listOfArms, { id: payload }, 'id');
      state.listOfArms.splice(indexOfDeleteArm, 1);
      return {
        ...state,
        deleteArmModal: false,
        buttonLoading: false,
      };

    case configActionTypes.DELETE_ARM_FAIL:
      return {
        ...state,
        buttonLoading: false,
        error: payload,
      };

    case configActionTypes.HANDLE_DELETE_CLASS_LEVEL_MODAL:
      return {
        ...state,
        deleteModal: payload,
        arm: false,
      };
    case configActionTypes.HANDLE_DEACTIVATE_CLASS_LEVEL_MODAL:
      return {
        ...state,
        deactivateModal: payload,
      };
    case configActionTypes.HANDLE_CLASS_LEVEL_DATA:
      return {
        ...state,
        classLevelData: payload,
      };
    case configActionTypes.EDIT_CLASS_LEVEL_START:
      return {
        ...state,
        edit: true,
        buttonLoading: true,
      };
    case configActionTypes.EDIT_CLASS_LEVEL_SUCCESS:
      const indexOfLevel = findIndexInArray(state.classLevelsList, payload, 'id');
      state.classLevelsList.splice(indexOfLevel, 1, payload);
      return {
        ...state,
        createModal: false,
        edit: false,
        classLevelsList: state.classLevelsList,
        classLevelData: payload,
        buttonLoading: false,
        editData: {},
        deactivateModal: false,
      };
    case configActionTypes.EDIT_CLASS_LEVEL_FAIL:
      return {
        ...state,
        createModal: false,
        buttonLoading: false,
        error: payload,
      };
    case configActionTypes.DELETE_CLASS_LEVEL_START:
      return {
        ...state,
        deleteModal: true,
        levelId: payload.id,
        buttonLoading: true,
      };
    case configActionTypes.DELETE_CLASS_LEVEL_SUCCESS:
      const levelToDelete = state.classLevelsList.filter((level) => {
        return level.id === state.levelId;
      })[0];
      const indexOfLevel2 = findIndexInArray(state.classLevelsList, levelToDelete, 'id');
      state.classLevelsList.splice(indexOfLevel2, 1);
      return {
        ...state,
        buttonLoading: false,
        classLevelsList: state.classLevelsList,
        classLevelData: {},
        deleteModal: false,
        levelId: null,
        total: state.total - 1,
      };
    case configActionTypes.DELETE_CLASS_LEVEL_FAIL:
      return {
        ...state,
        deleteModal: true,
        buttonLoading: false,
        error: payload,
      };
    case configActionTypes.HANDLE_EDIT_CLASS_LEVEL:
      return {
        ...state,
        createModal: true,
        edit: true,
        editData: payload,
      };
    case configActionTypes.RESET_MODAL:
      return {
        ...state,
        editData: {},
        buttonLoading: false,
        deleteModal: false,
        createModal: false,
        dataloading: false,
        edit: false,
        levelId: null,
        arm: false,
        editArm: false,
      };
    case configActionTypes.FETCH_CLASS_INFO_START:
      return {
        ...state,
        dataloading: true,
      };
    case configActionTypes.FETCH_CLASS_INFO_SUCCESS:
      return {
        ...state,
        dataloading: false,
        classArmList: payload,
        totalArms: payload.length,
        classMembers: [],
        classPopulation: 0,
      };
    case configActionTypes.FETCH_CLASS_INFO_FAIL:
      return {
        ...state,
        dataloading: false,
        error: payload,
      };
    case configActionTypes.HANDLE_CREATE_ARM_MODAL:
      return {
        ...state,
        modal: payload,
      };
    case configActionTypes.CREATE_CLASS_INFO_START:
      return {
        ...state,
        buttonLoading: true,
        editArm: false,
        classArmData: {},
      };
    case configActionTypes.CREATE_CLASS_INFO_SUCCESS:
      let { classArmList } = state;
      classArmList.push(payload);
      return {
        ...state,
        buttonLoading: false,
        classArmList,
        editArm: false,
        totalArms: state.totalArms + 1,
        modal: false,
      };
    case configActionTypes.CREATE_CLASS_INFO_FAIL:
      return {
        ...state,
        buttonLoading: false,
        modal: true,
        error: payload,
      };

    case configActionTypes.HANDLE_ARM_DATA:
      return {
        ...state,
        classArmData: payload,
      };

    case configActionTypes.HANDLE_DELETE_ARM_MODAL:
      return {
        ...state,
        deleteArmModal: payload,
        arm: payload,
      };

    case configActionTypes.HANDLE_EDIT_ARM:
      return {
        ...state,
        classArmData: payload,
        editArm: true,
        modal: true,
      };
    case configActionTypes.EDIT_ARM_START:
      return {
        ...state,
        editArm: true,
        modal: true,
        buttonLoading: true,
      };
    case configActionTypes.EDIT_ARM_SUCCESS:
      const indexOfArm = findIndexInArray(state.classArmList, payload, 'id');
      state.classArmList.splice(indexOfArm, 1, payload);
      return {
        ...state,
        editArm: false,
        modal: false,
        classArmData: {},
        classArmList: state.classArmList,
        buttonLoading: false,
      };
    case configActionTypes.EDIT_ARM_FAIL:
      return {
        ...state,
        editArm: true,
        modal: true,
        buttonLoading: false,
      };
    case configActionTypes.FETCH_SCHOOL_INFO_START:
      return {
        ...state,
        dataloading: true,
        error: null,
      };
    case configActionTypes.FETCH_SCHOOL_INFO_SUCCESS:
      return {
        ...state,
        dataloading: false,
        schoolInfo: payload,
        error: null,
      };
    case configActionTypes.FETCH_SCHOOL_INFO_FAIL:
      return {
        ...state,
        dataloading: false,
        error: payload,
      };
    case configActionTypes.FETCH_HOMEPAGE_START:
      return {
        ...state,
        dataloading: true,
        error: null,
      };
    case configActionTypes.FETCH_HOMEPAGE_SUCCESS:
      return {
        ...state,
        dataloading: false,
        schoolInfo: payload,
        onboardingInfo: payload.onboarding_steps,
        error: null,
      };
    case configActionTypes.SET_ONBOARDING_INF0:
      return {
        ...state,
        onboardingInfo: payload,
        error: null,
      };
    case configActionTypes.FETCH_HOMEPAGE_FAIL:
      return {
        ...state,
        dataloading: false,
        error: payload,
      };
    case configActionTypes.HANDLE_PAYABLE_ITEM:
      return {
        ...state,
        payableItemData: payload,
        editPayableItem: true,
        payableItemModal: true,
      };
    case configActionTypes.HANDLE_PAYABLE_ITEM_MODAL:
      return {
        ...state,
        payableItemModal: !payload,
        editPayableItem: false,
        payableItemData: {},
      };
    case configActionTypes.EDIT_PAYABLE_ITEM_START:
      return {
        ...state,
        editPayableItem: true,
        payableItemModal: true,
        buttonLoading: true,
      };
    case configActionTypes.EDIT_PAYABLE_ITEM_SUCCESS:
      const indexOfItem = findIndexInArray(state.payableItemsList, payload, 'id');
      state.payableItemsList.splice(indexOfItem, 1, payload);
      return {
        ...state,
        editPayableItem: false,
        payableItemModal: false,
        payableItemData: {},
        payableItemsList: state.payableItemsList,
        buttonLoading: false,
      };
    case configActionTypes.EDIT_PAYABLE_ITEM_FAIL:
      return {
        ...state,
        editPayableItem: true,
        payableItemModal: true,
        buttonLoading: false,
        error: payload,
      };
    case configActionTypes.FETCH_PAYABLE_ITEMS_START:
      return {
        ...state,
        dataloading: true,
        loading: true,
        error: null,
      };

    case configActionTypes.FETCH_PAYABLE_ITEMS_SUCCESS:
      return {
        ...state,
        dataloading: false,
        loading: false,
        payableItemsList: payload,
        totalPayableItems: payload.length,
      };
    case configActionTypes.FETCH_PAYABLE_ITEMS_FAIL:
      return {
        ...state,
        dataloading: false,
        loading: false,
        error: payload,
      };

    case configActionTypes.FILTER_PAYABLE_ITEMS_START:
      return {
        ...state,
        dataloading: true,
        error: null,
      };

    case configActionTypes.FILTER_PAYABLE_ITEMS_SUCCESS:
      return {
        ...state,
        dataloading: false,
        payableItemsList: payload.content,
      };

    case configActionTypes.FILTER_PAYABLE_ITEMS_FAIL:
      return {
        ...state,
        dataloading: false,
        error: payload,
      };
    case configActionTypes.ADD_PAYABLE_ITEM_START:
      return {
        ...state,
        buttonLoading: true,
        error: null,
      };
    case configActionTypes.ADD_PAYABLE_ITEM_SUCCESS:
      return {
        ...state,
        buttonLoading: false,
        error: null,
        payableItemsList: [...state.payableItemsList, payload],
        payableItemModal: false,
      };
    case configActionTypes.ADD_PAYABLE_ITEM_FAIL:
      return {
        ...state,
        buttonLoading: false,
        error: payload,
      };
    case configActionTypes.DELETE_PAYABLE_ITEM_START:
      return {
        ...state,
        buttonLoading: true,
        error: null,
      };
    case configActionTypes.DELETE_PAYABLE_ITEM_SUCCESS:
      const newList = state.payableItemsList.filter(({ id }) => id !== payload);
      return {
        ...state,
        payableItemsList: newList,
        payableItemId: '',
        buttonLoading: false,
        deletePayableItemModal: false,
        error: null,
      };
    case configActionTypes.DELETE_PAYABLE_ITEM_FAIL:
      return {
        ...state,
        buttonLoading: false,
        error: payload,
      };
    case configActionTypes.HANDLE_DELETE_PAYABLE_ITEM:
      return {
        ...state,
        deletePayableItemModal: !payload,
      };
    case configActionTypes.GET_TERM_START:
      return {
        ...state,
        gettingAssessment: true,
      };
    case configActionTypes.GET_TERM_SUCCESS:
      return {
        ...state,
        termInfo: payload,
        gettingAssessment: true,
      };
    case configActionTypes.GET_TERM_FAIL:
      return {
        ...state,
        gettingAssessment: true,
        error: payload,
      };

    case configActionTypes.GET_CLASS_MEMBERS_START:
      return {
        ...state,
        loadingClassMembers: true,
        classMembers: [],
        classPopulation: 0,
      };
    case configActionTypes.GET_CLASS_MEMBERS_SUCCESS:
      return {
        ...state,
        loadingClassMembers: false,
        classMembers: payload,
        classPopulation: payload.length,
      };
    case configActionTypes.GET_CLASS_MEMBERS_FAIL:
      return {
        ...state,
        error: payload,
        loadingClassMembers: false,
      };
    case configActionTypes.HANDLE_ADD_STUDENT_MODAL:
      return {
        ...state,
        studentModal: payload,
      };
    case configActionTypes.ASSIGN_STUDENTS_TO_CLASS_START:
      return {
        ...state,
        buttonLoading: true,
      };
    case configActionTypes.ASSIGN_STUDENTS_TO_CLASS_SUCCESS:
      state.classMembers.push(...payload);
      return {
        ...state,
        buttonLoading: false,
        studentModal: false,
        classPopulation: state.classPopulation + payload.length,
      };
    case configActionTypes.ASSIGN_STUDENTS_TO_CLASS_FAIL:
      return {
        ...state,
        error: payload.error,
        buttonLoading: false,
        studentModal: true,
      };
    case configActionTypes.GET_SESSION_START:
      return {
        ...state,
      };
    case configActionTypes.GET_SESSION_SUCCESS:
      return {
        ...state,
        sessionsInfo: payload,
      };
    case configActionTypes.GET_SESSION_FAIL:
      return {
        ...state,
        error: payload.error,
      };
    case configActionTypes.REMOVE_STUDENT_FROM_CLASS_START:
      return {
        ...state,
        buttonLoading: true,
      };
    case configActionTypes.REMOVE_STUDENT_FROM_CLASS_SUCCESS:
      return {
        ...state,
        buttonLoading: false,
        removeStudentModal: false,
      };
    case configActionTypes.REMOVE_STUDENT_FROM_CLASS_FAIL:
      return {
        ...state,
        buttonLoading: false,
        removeStudentModal: true,
        error: payload,
      };
    case configActionTypes.HANDLE_REMOVE_STUDENT_MODAL:
      return {
        ...state,
        removeStudentModal: payload,
      };
    case configActionTypes.UPLOAD_SCHOOL_LOGO_START:
      return {
        ...state,
      };
    case configActionTypes.UPLOAD_SCHOOL_LOGO_SUCCESS:
      return {
        ...state,
      };
    case configActionTypes.UPLOAD_SCHOOL_LOGO_FAIL:
      return {
        ...state,
      };
    case configActionTypes.VIEW_CLASS_PROFILE:
      return {
        ...state,
        classInfo: payload,
      };
    case configActionTypes.GET_CURRENT_TERM_START:
      return {
        ...state,
      };
    case configActionTypes.GET_CURRENT_TERM_SUCCESS:
      const { session, term_id, start_date, end_date, school_term_definition } = payload;
      return {
        ...state,
        currentTerm: term_id,
        currentTermUUID: term_id,
        currentSession: session.id,
        termStartDate: start_date,
        currentTermObject: {
          id: term_id,
          name: school_term_definition.term_definition.description,
          session: session.id,
          start_date,
          end_date,
          school_term_definition,
        },
        currentTermDescription: school_term_definition.term_definition.description,
        currentTermDTO: payload,
      };
    case configActionTypes.GET_CURRENT_TERM_FAIL:
      return {
        ...state,
      };

    case configActionTypes.HANDLE_CLASS_LEVEL_PROFILE:
      return {
        ...state,
        classLevelProfileData: payload,
      };

    case configActionTypes.CREATE_TERM_DEF_START:
      return {
        ...state,
        buttonLoading: true,
      };
    case configActionTypes.CREATE_TERM_DEF_SUCCESS:
      return {
        ...state,
        buttonLoading: false,
        addTermModal: false,
        dataToEdit: {},
      };
    case configActionTypes.CREATE_TERM_DEF_FAIL:
      return {
        ...state,
        error: payload,
        buttonLoading: false,
      };
    case configActionTypes.FETCH_ALL_TERM_DEF_START:
      return {
        ...state,
      };
    case configActionTypes.FETCH_ALL_TERM_DEF_SUCCESS:
      return {
        ...state,
        termDefinitionList: payload,
      };
    case configActionTypes.FETCH_ALL_TERM_DEF_FAIL:
      return {
        ...state,
        error: payload,
      };
    case configActionTypes.HANDLE_ADD_TERM_MODAL:
      return {
        ...state,
        addTermModal: payload,
        editTerm: false,
        dataToEdit: {},
      };

    case configActionTypes.HANDLE_EDIT_TERM:
      return {
        ...state,
        editTerm: payload,
        addTermModal: payload,
      };
    case configActionTypes.EDIT_TERM_DEF_START:
      return {
        ...state,
        buttonLoading: true,
      };
    case configActionTypes.EDIT_TERM_DEF_SUCCESS:
      const indexOfEditedTerm = findIndexInArray(state.termDefinitionList, payload, 'id');
      state.termDefinitionList.splice(indexOfEditedTerm, 1, payload);
      return {
        ...state,
        buttonLoading: false,
        addTermModal: false,
        editTerm: false,
        dataToEdit: {},
      };
    case configActionTypes.EDIT_TERM_DEF_FAIL:
      return {
        ...state,
        error: payload,
        buttonLoading: false,
      };
    case configActionTypes.HANDLE_EDIT_TERM:
      return {
        ...state,
        editTerm: payload,
        addTermModal: payload,
      };
    case configActionTypes.DELETE_TERM_DEF_START:
      return {
        ...state,
        buttonLoading: true,
      };
    case configActionTypes.DELETE_TERM_DEF_SUCCESS:
      return {
        ...state,
        termDefinitionList: state.termDefinitionList.filter(({ id }) => id !== payload),
        buttonLoading: false,
        deleteTermModal: false,
      };
    case configActionTypes.DELETE_TERM_DEF_FAIL:
      return {
        ...state,
        error: payload,
        buttonLoading: false,
      };
    case configActionTypes.HANDLE_TERM_DATA:
      return {
        ...state,
        dataToEdit: payload,
      };
    case configActionTypes.HANDLE_DELETE_TERM_MODAL:
      return {
        ...state,
        deleteTermModal: payload,
      };
    case configActionTypes.CREATE_NEW_SESSION_START:
      return {
        ...state,
        buttonLoading: true,
      };
    case configActionTypes.CREATE_NEW_SESSION_SUCCESS:
      return {
        ...state,
        buttonLoading: false,
        createSessionModal: false,
        currentSessionObject: payload,
      };
    case configActionTypes.CREATE_NEW_SESSION_FAIL:
      return {
        ...state,
        buttonLoading: false,
        error: payload,
      };
    case configActionTypes.HANDLE_CREATE_SESSION_MODAL:
      return {
        ...state,
        createSessionModal: !payload,
      };
    case configActionTypes.FETCH_ALL_EXISTING_TERMS_START:
      return {
        ...state,
        isLoading: true,
      };
    case configActionTypes.FETCH_ALL_EXISTING_TERMS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        allExistingTerms: payload,
      };
    case configActionTypes.FETCH_ALL_EXISTING_TERMS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case configActionTypes.SET_CURRENT_TERM_START:
      return {
        ...state,
        buttonLoading: true,
      };
    case configActionTypes.SET_CURRENT_TERM_SUCCESS:
      return {
        ...state,
        buttonLoading: false,
        currentTerm: payload.term_id,
      };
    case configActionTypes.SET_CURRENT_TERM_FAIL:
      return {
        ...state,
        buttonLoading: false,
        error: payload,
      };

    case configActionTypes.GET_CLASS_LEVEL_TRAITS_START:
      return {
        ...state,
        buttonLoading: false,
      };
    case configActionTypes.GET_CLASS_LEVEL_TRAITS_SUCCESS:
      return {
        ...state,
        buttonLoading: false,
        classLevelTraits: payload.trait_and_definitions,
        classLevelTraitsRating: payload.trait_rating_and_definitions,
      };
    case configActionTypes.GET_CLASS_LEVEL_TRAITS_FAIL:
      return {
        ...state,
        buttonLoading: false,
        classLevelTraits: [],
        classLevelTraitsRating: [],
        error: payload,
      };

    case configActionTypes.GET_STUDENT_TRAIT_ASSESSMENT_START:
      return {
        ...state,
        gettingAssessment: true,
      };
    case configActionTypes.GET_STUDENT_TRAIT_ASSESSMENT_SUCCESS:
      let currentStudentAssessment = null;
      if (payload.data.length) {
        const subTraits = payload.data.map((item) => {
          return {
            comments: item.comments,
            id: item.trait_definition.id,
            selected: item.trait_rating_definition.rating_order,
            trait_rating_definition_id: item.trait_rating_definition.id,
          };
        });
        currentStudentAssessment = {
          groupComment: payload.data[0].group_comments,
          subTraits,
        };
      }

      return {
        ...state,
        currentStudentAssessment,
        traits: payload.traitId
          ? state.classLevelTraits.filter((item) => item.trait.id === payload.traitId)[0]
              .trait_definitions
          : [],
        parentChildTrait: payload.data,
        childResult: {},
        gettingAssessment: false,
      };
    case configActionTypes.GET_STUDENT_TRAIT_ASSESSMENT_FAIL:
      return {
        ...state,
        error: payload,
        childResult: {},
        traits: [],
        gettingAssessment: false,
      };

    case configActionTypes.GET_STUDENT_RESULTS_START:
      return {
        ...state,
        gettingAssessment: true,
      };
    case configActionTypes.GET_STUDENT_RESULTS_SUCCESS:
      return {
        ...state,
        gettingAssessment: false,
        studentResults: payload,
      };
    case configActionTypes.GET_STUDENT_RESULTS_FAIL:
      return {
        ...state,
        gettingAssessment: false,
        studentResults: {},
        error: payload,
      };

    case configActionTypes.SAVE_STUDENT_TRAIT_ASSESSMENT_START:
      return {
        ...state,
        buttonLoading: true,
      };
    case configActionTypes.SAVE_STUDENT_TRAIT_ASSESSMENT_SUCCESS:
      return {
        ...state,
        buttonLoading: false,
      };
    case configActionTypes.SAVE_STUDENT_TRAIT_ASSESSMENT_FAIL:
      return {
        ...state,
        buttonLoading: false,
        error: payload,
      };

    case configActionTypes.HANDLE_EDIT_CLASS_INFO:
      return {
        ...state,
        classInfo: { ...state.classInfo, ...payload },
      };
    case configActionTypes.TERM_PROFILE_SET_DATE_RANGE_START:
      return {
        ...state,
        buttonLoading: true,
      };
    case configActionTypes.TERM_PROFILE_SET_DATE_RANGE_SUCCESS:
      return {
        ...state,
        buttonLoading: false,
      };
    case configActionTypes.TERM_PROFILE_SET_DATE_RANGE_FAIL:
      return {
        ...state,
        buttonLoading: false,
        error: payload,
      };
    case configActionTypes.TERM_PROFILE_SET_CLASS_INFO_START:
      return {
        ...state,
        buttonLoading: true,
      };
    case configActionTypes.TERM_PROFILE_SET_CLASS_INFO_SUCCESS:
      return {
        ...state,
        buttonLoading: false,
      };
    case configActionTypes.TERM_PROFILE_SET_CLASS_INFO_FAIL:
      return {
        ...state,
        buttonLoading: false,
        error: payload,
      };
    case configActionTypes.TERM_PROFILE_SET_EARLY_YEARS_START:
      return {
        ...state,
        buttonLoading: true,
      };
    case configActionTypes.TERM_PROFILE_SET_EARLY_YEARS_SUCCESS:
      return {
        ...state,
        buttonLoading: false,
      };
    case configActionTypes.TERM_PROFILE_SET_EARLY_YEARS_FAIL:
      return {
        ...state,
        buttonLoading: false,
        error: payload,
      };
    case configActionTypes.TERM_PROFILE_SET_GRADE_FORMAT_START:
      return {
        ...state,
        buttonLoading: true,
      };
    case configActionTypes.TERM_PROFILE_SET_GRADE_FORMAT_SUCCESS:
      return {
        ...state,
        buttonLoading: false,
      };
    case configActionTypes.TERM_PROFILE_SET_GRADE_FORMAT_FAIL:
      return {
        ...state,
        buttonLoading: false,
        error: payload,
      };
    case configActionTypes.TERM_PROFILE_SET_ASSESSMENT_FORMAT_START:
      return {
        ...state,
        buttonLoading: true,
      };
    case configActionTypes.TERM_PROFILE_SET_ASSESSMENT_FORMAT_SUCCESS:
      return {
        ...state,
        buttonLoading: false,
      };
    case configActionTypes.TERM_PROFILE_SET_ASSESSMENT_FORMAT_FAIL:
      return {
        ...state,
        buttonLoading: false,
        error: payload,
      };
    case configActionTypes.TERM_PROFILE_SET_RATINGS_START:
      return {
        ...state,
        buttonLoading: true,
      };
    case configActionTypes.TERM_PROFILE_SET_RATINGS_SUCCESS:
      return {
        ...state,
        buttonLoading: false,
      };
    case configActionTypes.TERM_PROFILE_SET_RATINGS_FAIL:
      return {
        ...state,
        buttonLoading: false,
        error: payload,
      };
    case configActionTypes.GET_TERM_PROFILE_DATE_RANGE_START:
      return {
        ...state,
        dataloading: true,
      };
    case configActionTypes.GET_TERM_PROFILE_DATE_RANGE_SUCCESS:
      return {
        ...state,
        dataloading: false,
      };
    case configActionTypes.GET_TERM_PROFILE_DATE_RANGE_FAIL:
      return {
        ...state,
        dataloading: false,
        error: payload,
      };
    case configActionTypes.GET_TERM_PROFILE_CLASS_INFO_START:
      return {
        ...state,
        dataloading: true,
      };
    case configActionTypes.GET_TERM_PROFILE_CLASS_INFO_SUCCESS:
      return {
        ...state,
        dataloading: false,
      };
    case configActionTypes.GET_TERM_PROFILE_CLASS_INFO_FAIL:
      return {
        ...state,
        dataloading: false,
        error: payload,
      };
    case configActionTypes.GET_TERM_PROFILE_EARLY_YEARS_START:
      return {
        ...state,
        dataloading: true,
      };
    case configActionTypes.GET_TERM_PROFILE_EARLY_YEARS_SUCCESS:
      return {
        ...state,
        dataloading: false,
      };
    case configActionTypes.GET_TERM_PROFILE_EARLY_YEARS_FAIL:
      return {
        ...state,
        dataloading: false,
        error: payload,
      };
    case configActionTypes.GET_TERM_PROFILE_GRADE_FORMAT_START:
      return {
        ...state,
        dataloading: true,
      };
    case configActionTypes.GET_TERM_PROFILE_GRADE_FORMAT_SUCCESS:
      return {
        ...state,
        dataloading: false,
      };
    case configActionTypes.GET_TERM_PROFILE_GRADE_FORMAT_FAIL:
      return {
        ...state,
        dataloading: false,
        error: payload,
      };
    case configActionTypes.GET_TERM_PROFILE_SKILL_GROUP_START:
      return {
        ...state,
        dataloading: true,
      };
    case configActionTypes.GET_TERM_PROFILE_SKILL_GROUP_SUCCESS:
      return {
        ...state,
        dataloading: false,
      };
    case configActionTypes.GET_TERM_PROFILE_SKILL_GROUP_FAIL:
      return {
        ...state,
        dataloading: false,
        error: payload,
      };
    case configActionTypes.GET_TERM_PROFILE_SKILL_RATINGS_START:
      return {
        ...state,
        dataloading: true,
      };
    case configActionTypes.GET_TERM_PROFILE_SKILL_RATINGS_SUCCESS:
      return {
        ...state,
        dataloading: false,
      };
    case configActionTypes.GET_TERM_PROFILE_SKILL_RATINGS_FAIL:
      return {
        ...state,
        dataloading: false,
        error: payload,
      };
    case configActionTypes.GET_TERM_PROFILE_ASSESSMENT_FORMAT_START:
      return {
        ...state,
        dataloading: true,
      };
    case configActionTypes.GET_TERM_PROFILE_ASSESSMENT_FORMAT_SUCCESS:
      return {
        ...state,
        dataloading: false,
      };
    case configActionTypes.GET_TERM_PROFILE_ASSESSMENT_FORMAT_FAIL:
      return {
        ...state,
        dataloading: false,
        error: payload,
      };
    case configActionTypes.GET_TERM_PROFILE_RATINGS_START:
      return {
        ...state,
        dataloading: true,
      };
    case configActionTypes.GET_TERM_PROFILE_RATINGS_SUCCESS:
      return {
        ...state,
        dataloading: false,
      };
    case configActionTypes.GET_TERM_PROFILE_RATINGS_FAIL:
      return {
        ...state,
        dataloading: false,
        error: payload,
      };

    case configActionTypes.GET_STUDENT_COMMENTS_START:
      return {
        ...state,
        gettingAssessment: true,
      };
    case configActionTypes.GET_STUDENT_COMMENTS_SUCCESS:
      return {
        ...state,
        studentComments: payload,
      };
    case configActionTypes.GET_STUDENT_COMMENTS_FAIL:
      return {
        ...state,
        error: payload,
        gettingAssessment: false,
      };

    case configActionTypes.FETCH_APPROVE_RESULTS_START:
      return {
        ...state,
        getApproveResultLoading: true,
      };
    case configActionTypes.FETCH_APPROVE_RESULTS_SUCCESS:
      return {
        ...state,
        approveResults: payload,
        getApproveResultLoading: false,
      };
    case configActionTypes.FETCH_APPROVE_RESULTS_FAIL:
      return {
        ...state,
        getApproveResultLoading: false,
        approveResults: [],
        error: payload,
      };

    case configActionTypes.SUBMIT_APPROVAL_RESULTS_START:
      return {
        ...state,
        buttonLoading: true,
      };
    case configActionTypes.SUBMIT_APPROVAL_RESULTS_SUCCESS:
      return {
        ...state,
        // approveResults: payload,
        buttonLoading: false,
      };
    case configActionTypes.SUBMIT_APPROVAL_RESULTS_FAIL:
      return {
        ...state,
        buttonLoading: false,
        error: payload,
      };

    case configActionTypes.ADD_STUDENT_COMMENT_START:
      return {
        ...state,
        buttonLoading: true,
        studentComments: [],
      };
    case configActionTypes.ADD_STUDENT_COMMENT_SUCCESS:
      return {
        ...state,
        buttonLoading: false,
      };
    case configActionTypes.ADD_STUDENT_COMMENT_FAIL:
      return {
        ...state,
        buttonLoading: false,
        error: payload,
      };
    case configActionTypes.FETCH_CLASS_LEVEL_WITH_ARMS_START:
      return {
        ...state,
        levelDataLoading: true,
      };
    case configActionTypes.FETCH_CLASS_LEVEL_WITH_ARMS_SUCCESS:
      return {
        ...state,
        listOfClassLevelsWithArms: payload,
        levelDataLoading: false,
      };
    case configActionTypes.FETCH_CLASS_LEVEL_WITH_ARMS_FAIL:
      return {
        ...state,
        levelDataLoading: false,
        error: payload,
      };

    case configActionTypes.FETCH_CHILD_RESULT_START:
      return {
        ...state,

        gettingAssessment: true,
      };
    case configActionTypes.FETCH_CHILD_RESULT_SUCCESS:
      return {
        ...state,
        childResult: payload,
        traits: [],
        gettingAssessment: false,
      };
    case configActionTypes.FETCH_CHILD_RESULT_FAIL:
      return {
        ...state,
        error: payload,
        childResult: {},
        traits: [],
        gettingAssessment: false,
      };

    case configActionTypes.GET_CHILD_ATTENDANCE_SUMMARY_START:
      return {
        ...state,
        gettingAssessment: true,
      };
    case configActionTypes.GET_CHILD_ATTENDANCE_SUMMARY_SUCCESS:
      return {
        ...state,
        childAttendanceSummary: payload,
      };
    case configActionTypes.GET_CHILD_ATTENDANCE_SUMMARY_FAIL:
      return {
        ...state,
        error: payload,
        gettingAssessment: false,
      };

    case configActionTypes.FETCH_CHILD_SKILLS_RESULT_START:
      return {
        ...state,
        gettingAssessment: true,
      };
    case configActionTypes.FETCH_CHILD_SKILLS_RESULT_SUCCESS:
      return {
        ...state,
        studentSkillsResults: payload.assessments ? payload.assessments : [],
      };
    case configActionTypes.FETCH_CHILD_SKILLS_RESULT_FAIL:
      return {
        ...state,
        error: payload,
        studentSkillsResults: [],
        gettingAssessment: false,
      };

    case configActionTypes.TRANSFER_STUDENTS_ANOTHER_ARM_START:
      return {
        ...state,
        transferLoading: true,
      };
    case configActionTypes.TRANSFER_STUDENTS_ANOTHER_ARM_SUCCESS:
      const filtered = state.classMembers.filter(function (e) {
        return this.indexOf(e.student_dto.id) < 0;
      }, payload);

      return {
        ...state,
        transferLoading: false,
        studentTransferModal: false,
        classMembers: filtered,
      };
    case configActionTypes.TERM_PROFILE_SET_ASSESSMENT_FORMAT_FAIL:
      return {
        ...state,
        error: payload,
        transferLoading: false,
      };

    case configActionTypes.OPEN_STUDENT_TRANSFER_MODAL:
      return {
        ...state,
        studentTransferModal: payload,
      };
    case configActionTypes.SETUP_TERM_PROFILE:
      return {
        ...state,
        termSetupLoading: true,
      };
    case configActionTypes.SETUP_TERM_PROFILE_COMPLETE:
      return {
        ...state,
        termSetupLoading: false,
      };
    case configActionTypes.SET_IS_ONBOARDING:
      return {
        ...state,
        isOnboarding: payload,
      };
    case configActionTypes.SET_REFETCH_ATTENDANCE:
      return {
        ...state,
        refetchAttendance: payload,
      };
    case configActionTypes.HANDLE_BULK_STUDENT_MODAL:
      return {
        ...state,
        bulkModal: payload,
      };
    case configActionTypes.UPLOAD_SIGNATURE_START:
      return {
        ...state,
      };
    case configActionTypes.UPLOAD_SIGNATURE_SUCCESS:
      return {
        ...state,
      };
    case configActionTypes.UPLOAD_SIGNATURE_FAIL:
      return {
        ...state,
      };
    case configActionTypes.HANDLE_ACTIVITY_MODAL:
      return {
        ...state,
        activityModal: payload,
      };
    case configActionTypes.HANDLE_DELETE_ACTIVITY_MODAL:
      return {
        ...state,
        deleteActivityModal: payload,
      };
    case configActionTypes.HANDLE_ACTIVITY_DATA:
      return {
        ...state,
        activityData: payload,
      };

    case configActionTypes.HANDLE_NO_GRADING_FORMAT:
      return {
        ...state,
        noGradingFormat: payload,
      };
    case configActionTypes.UPDATE_APP_CONFIG_STATE:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}

const persistConfig = {
  key: 'config',
  storage,
  blacklist: [
    'buttonLoading',
    'refetchAttendance',
    'gettingAssessment',
    'error',
    'dataloading',
    'edit',
    'createModal',
    'deleteModal',
    'deleteArmModal',
    'modal',
    'payableItemData',
    'classArmData',
    'arm',
    'deletePayableItemModal',
    'payableItemModal',
    'editPayableItem',
    'payableItemId',
    'loading',
    'studentModal',
    'removeStudentModal',
    'classPopulation, homePageInfo',
    'addTermModal',
    'editTerm',
    'isLoading',
    'currentClassLevelId',
    'deleteTermModal',
    'createSessionModal',
    'getApproveResultLoading',
    'levelDataLoading',
    'studentTransferModal',
    'transferLoading',
    'termSetupLoading',
    'loadingClassMembers',
    'bulkModal',
    'deactivateModal',
    'noGradingFormat',
  ],
};

export default persistReducer(persistConfig, configReducer);
