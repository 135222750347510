export enum OnboardingStepsEnum {
  SCHOOL_INFORMATION = 'SCHOOL_INFORMATION',
  CLASS_LEVEL = 'CLASS_LEVEL',
  CLASS_ARM = 'CLASS_ARM',
  TERM_DEFINITION = 'TERM_DEFINITION',
  // BANK_ACCOUNT = 'BANK_ACCOUNT',
  EARLY_YEARS_ASSESSMENT = 'EARLY_YEARS_ASSESSMENT',
  EARLY_YEARS_RATING = 'EARLY_YEARS_RATING',
  GRADING = 'GRADING',
  ASSESSMENT = 'ASSESSMENT',
  SKILL = 'SKILL',
  SKILL_RATING = 'SKILL_RATING',
  SESSION = 'SESSION',
}

export const OnboardingStepsInfo = [
  {
    name: 'SCHOOL_INFORMATION',
    index: 1,
    description: 'School Information Configuration',
    path: '/school-information',
    module: 'general',
    status: false,
    required: true,
    text: [
      'Update School Name',
      'Upload School Logo',
      'Set School Colour',
      'Update School Contact Information',
      'Update Technical Contact',
      'Click on Save',
    ],
    helperText: `Kindly update the required school information. When you are done, click on the Next button to proceed to the next step.`,
  },
  {
    name: 'CLASS_LEVEL',
    index: 2,
    description: 'Class Level Configuration',
    path: '/class-level',
    module: 'general',
    required: true,
    status: false,
    text: [
      'Click on Create Level button',
      'Fill in level name and shortname',
      'Enable early years if applicable',
      'Select order',
      'Click on Save',
    ],
    helperText:
      "Kindly create all class level in your school. When you're done, Click on Next button to proceed.",
  },
  {
    name: 'CLASS_ARM',
    index: 3,
    description: 'Class Arm Configuration',
    path: '/arms',
    module: 'general',
    status: false,
    required: true,
    text: [
      'Click on Create Arm button',
      'Enter the Arm Name in the field provided',
      'Click on the Save Arm button',
    ],
    helperText: "Kindly create a class arm. When you're done, Click on Next button to proceed.",
  },
  {
    name: 'SESSION',
    index: 4,
    description: 'Session Configuration',
    path: '/sessions-and-terms',
    module: 'general',
    status: false,
    required: true,
    text: [
      'Click on Create Session',
      'Select the session from the dropdown',
      'Enter the "term start date, term end date, and next term start date"',
      'Click on Create Session',
    ],
    helperText: 'Kindly create a new session by clicking on the Create Session button.',
  },
  // {
  //   name: 'BANK_ACCOUNT',
  //   index: 6,
  //   description: 'Bank Account Configuration',
  //   path: '/bank-accounts',
  //   module: 'FEES_MANAGEMENT',
  //   status: false,
  //   required: false,
  //   text: [
  //     'Click on Create Bank Account button',
  //     'Fill all compulsory fields',
  //     'Click on default Bank Account Toggle button to create a default Bank Account',
  //     'Click on the Save button',
  //   ],
  //   helperText:
  //     "Kindly create a default bank account. Create Specific Bank accounts for each level if applicable. When you're done, Click on Next button to proceed.",
  // },
  {
    name: 'EARLY_YEARS_ASSESSMENT',
    index: 5,
    description: 'Early Years Assessment Configuration',
    path: '/early-years-grading',
    module: 'ACADEMICS',
    status: false,
    required: false,
    text: [
      'Click on Add EY Assessment button',
      'Fill in the EY Assessment Name, Subject Name, and the Topics under that subject',
      'Click on  Add Topic to add more than one topic for that subject',
      'To add more subjects, click on Add Another Subject',
      'Click on the Preview to view the list of added subjects and their corresponding topics',
      'Click on Save',
    ],
    helperText:
      'Kindly create an early years assessment by clicking on the Add EY Assessment button. Click on the Next button to proceed. ',
  },
  {
    name: 'EARLY_YEARS_RATING',
    index: 6,
    description: 'Early Years Rating Configuration',
    path: '/early-years-grading',
    module: 'ACADEMICS',
    status: false,
    required: false,
    text: [
      'Click on Add Rating button',
      'Add rating Name, select the Scale, type (Number Rating or Letter Rating)',
      'For Number Rating type, only fill the Remark',
      'For Letter Rating type, fill in the Rating and Remark',
      'Click the Save button',
    ],
    helperText: 'Kindly create an early years rating by clicking on the Add Rating button.',
  },
  {
    name: 'GRADING',
    index: 7,
    description: 'Grading Configuration',
    path: '/grading-format',
    module: 'ACADEMICS',
    status: false,
    required: false,
    text: [
      'Click on Add Grade Format',
      'Fill in the Grade Name, Score Range, Grade and Remark',
      'Select Grade Color',
      'Click on Add Next Grade. Repeat the previous 2 steps',
      'When the grade range gets to 100, click on Preview',
      'Click on Save and Apply',
    ],
    helperText: 'Kindly create a grading format by clicking on the Add Grading Format button.',
  },
  {
    name: 'ASSESSMENT',
    index: 8,
    description: 'Assessment Configuration',
    path: '/assessments',
    module: 'ACADEMICS',
    status: false,
    required: false,
    text: [
      'Click on Add Assessment',
      'Enter the Assessment Format Name',
      'Under the Assessment Breakdown, Enter the assessment name, percentage, and assessment type',
      'Each assessment can further be broken down further into sub-assessments. Leave the sub-assessment empty if there is none',
      'Enter the sub Assessment Name and the percentage',
      'The sub-assessment added is converted to a percentage(Green part) that can add up to the parent assessment',
      'Click the Preview button',
      'Click on Save and Apply',
    ],
    helperText: 'Kindly create assessments by clicking on the Add Assessment button.',
  },
  {
    name: 'SKILL',
    index: 9,
    description: 'Skill Configuration',
    path: '/skill-assessment',
    module: 'ACADEMICS',
    status: false,
    required: false,
    text: [
      'Click on the Add Skill Group button',
      'Enter the required Skill Group Name and save',
      'Enter the skill name and save',
      'Repeat process to add all the skills',
    ],
    helperText:
      'Kindly create a skill group by clicking on the Add skill group button then click on the Add Skill button to add skills to that group.',
  },
  {
    name: 'SKILL_RATING',
    index: 10,
    description: 'Skill Rating Configuration',
    path: '/skill-assessment',
    module: 'ACADEMICS',
    status: false,
    required: false,
    text: [
      'Click on Add Rating',
      'Fill in the rating name, grade, remark and select color',
      'Click on Add Another to add the rest of the grades',
      'Click on Save',
    ],
    helperText: 'Kindly create a skill rating by clicking on the Add Rating button.',
  },
  {
    name: 'TERM_DEFINITION',
    index: 11,
    description: 'Term Initialization Configuration',
    path: '/sessions-and-terms',
    module: 'general',
    status: false,
    required: true,
    text: [
      'Under Sessions, scroll down to the session and term of your choice and click Configure',
      'Set the start and end dates',
      'Click on next and follow the wizard until you get to last step',
      'Click on Set as a current term',
    ],
    helperText:
      'Kindly initialize a term by clicking on the Configure button,  go through the steps and click on set as current term.',
  },
];
