import { Box } from '@flexisaf/flexibull2';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export default function Skeletonloader() {
  return (
    <Box width="100%" vAlign>
      <Box width="100%" margin="30px" align="left">
        <SkeletonTheme color="#d0d5d933" highlightColor="#c2cad133">
          <Skeleton count={15} height={30} />
        </SkeletonTheme>
      </Box>
    </Box>
  );
}
