import { put, takeLatest } from 'redux-saga/effects';
import httpClient from 'utils/http-client';
import { fetchClassLevelFail, fetchClassLevelSuccess } from '../actions';
import configActionTypes from '../types';

export function* fetchClassLevel({ payload }) {
  try {
    const response = yield httpClient.get('/class-levels');
    yield put(fetchClassLevelSuccess(response.data));
  } catch (error) {
    yield put(fetchClassLevelFail(error?.message));
  }
}

export function* onFetchClassLevelStart() {
  yield takeLatest(configActionTypes.FETCH_CLASS_LEVEL_START, fetchClassLevel);
}
