import { persistReducer } from 'redux-persist';
import storage from 'localforage';
import classInformationTypes from './types';
import { v4 as uuidv4 } from 'uuid';
import { findIndexInArray } from 'utils/utils';
import { FIX_ANY } from 'utils/types';

const INITIAL_STATE = {
  isLoading: false,
  listOfClassInformation: [],
  total: 0,
  formTeacherModal: false,
  teachers: [],
  buttonLoading: false,
};

export interface IClassInformationState {
  isLoading: boolean;
  listOfClassInformation: FIX_ANY[];
  total: number;
  formTeacherModal: boolean;
  teachers: FIX_ANY[];
  buttonLoading: boolean;
}

interface IParam {
  type: classInformationTypes;
  payload: any;
}

function classInformationReducer(
  state: IClassInformationState = INITIAL_STATE,
  { type, payload }: IParam,
) {
  switch (type) {
    case classInformationTypes.FETCH_LIST_OF_CLASS_INFORMATION_START:
      return {
        ...state,
        isLoading: true,
      };
    case classInformationTypes.FETCH_LIST_OF_CLASS_INFORMATION_SUCCESS:
      const ref = payload.map((item) => ({
        ...item,
        frontendId: uuidv4(),
      }));
      return {
        ...state,
        isLoading: false,
        listOfClassInformation: ref,
        total: ref?.length,
      };
    case classInformationTypes.FETCH_LIST_OF_CLASS_INFORMATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    case classInformationTypes.HANDLE_ASSIGN_FORM_TEACHER_MODAL:
      return {
        ...state,
        formTeacherModal: payload,
      };
    case classInformationTypes.FETCH_ALL_TEACHERS_START:
      return {
        ...state,
        isLoading: true,
      };
    case classInformationTypes.FETCH_ALL_TEACHERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        teachers: payload,
      };
    case classInformationTypes.FETCH_ALL_TEACHERS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case classInformationTypes.ASSIGN_FORM_TEACHERS_START:
      return {
        ...state,
        buttonLoading: true,
      };
    case classInformationTypes.ASSIGN_FORM_TEACHERS_SUCCCESS:
      const { main_form_teacher, assistant_form_teacher } = payload;
      const updatedClass = state.listOfClassInformation.find(
        ({ frontendId }) => frontendId === payload.frontendId,
      );

      const newList = state.listOfClassInformation;
      const indexOfEditedClass = findIndexInArray(newList, payload, 'frontendId');
      newList.splice(indexOfEditedClass, 1, {
        ...updatedClass,
        main_form_teacher,
        assistant_form_teacher,
      });
      return {
        ...state,
        buttonLoading: false,
        formTeacherModal: false,
        listOfClassInformation: newList,
      };
    case classInformationTypes.ASSIGN_FORM_TEACHERS_FAIL:
      return {
        ...state,
        buttonLoading: false,
        error: payload,
      };
    case classInformationTypes.UPDATE_CLASS_INFORMATION_STATE:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
}

const persistConfig = {
  key: 'class-information',
  storage,
  blacklist: ['error', 'isLoading', 'buttonLoading', 'formTeacherModal', 'subjectTeacherModal'],
};

export default persistReducer(persistConfig, classInformationReducer);
