import { takeLatest, put } from 'redux-saga/effects';
import { fetchAllTraitGroupSuccess, fetchAllTraitGroupFail } from '../actions';
import httpClient from 'utils/http-client';
import earlyYearsActionTypes from '../types';
import { Notify } from '@flexisaf/flexibull2';
import { handleError } from 'utils/utils';

function* fetchAllTraits() {
  try {
    const response = yield httpClient.get('/trait-configuration');
    yield put(fetchAllTraitGroupSuccess(response.data));
  } catch (error) {
    yield put(fetchAllTraitGroupFail(error.fail));
    const err = handleError(error);
  }
}

export function* onFetchAllTraitsStart() {
  yield takeLatest(earlyYearsActionTypes.FETCH_TRAIT_GROUP_START, fetchAllTraits);
}
