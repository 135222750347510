import { all, call } from 'redux-saga/effects';
import { onFetchAllTraitsStart } from './fetchAllTraitsSaga';
import { onAddTraitGroupStart } from './addTraitSaga';
import { onAddSingleTraitStart } from './addSingleTraitSaga';
import { onEditSingleTraitStart } from './editSingleTraitSaga';
import { onDeleteSingleTraitStart } from './deleteSingleTraitSaga';
import { onAddSingleDefinitionStart } from './addSingleDefinitionSaga';
import { onEditSingleDefinitionStart } from './editSingleDefinitionSaga';
import { onDeleteSingleDefinitionStart } from './deleteSingleDefinitionSaga';
import { onAddRatingStart } from './addRatingSaga';
import { onFetchAllRatingsStart } from './fetchRatingSaga';
import { onEditRatingNameStart } from './editRatingNameSaga';
import { onDeleteRatingStart } from './deleteRatingSaga';
import { onDeleteSingleRatingStart } from './deleteSingleSubRaiting';
import { onEditRateRemarkStart } from './editSingleRateDefinition';

export default function* EarlyYearsGradingSaga() {
  yield all([
    call(onFetchAllTraitsStart),
    call(onAddSingleTraitStart),
    call(onAddTraitGroupStart),
    call(onEditSingleTraitStart),
    call(onDeleteSingleTraitStart),
    call(onAddSingleDefinitionStart),
    call(onEditSingleDefinitionStart),
    call(onDeleteSingleDefinitionStart),
    call(onAddRatingStart),
    call(onFetchAllRatingsStart),
    call(onEditRatingNameStart),
    call(onDeleteRatingStart),
    call(onDeleteSingleRatingStart),
    call(onEditRateRemarkStart),
  ]);
}
