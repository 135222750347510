import { takeLatest, put } from 'redux-saga/effects';
import { addStudentCommentFail, addStudentCommentSuccess } from '../actions';
import { handleFeedBackAction } from 'redux/parent/actions';
import httpClient from 'utils/http-client';
import { Notify } from '@flexisaf/flexibull2';
import configActionTypes from '../types';
import { handleError } from '../../../utils/utils';

export function* addStudentComment({ payload }) {
  try {
    const response = yield httpClient.post(`/term-comments`, payload);
    yield put(addStudentCommentSuccess(response.data));
    Notify('Comment updated successfully', { position: 'top-right' });
    yield put(handleFeedBackAction(true)); // Open Feedback modal
  } catch (error) {
    yield put(addStudentCommentFail(error?.message));
    const err = handleError(error);
  }
}

export function* onAddStudentComment() {
  yield takeLatest(configActionTypes.ADD_STUDENT_COMMENT_START, addStudentComment);
}
