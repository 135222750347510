import { setSiteTheme } from 'config/theme';
import {
  BasicSchoolInformationResponse,
  BasicSchoolInformationRestControllerService,
} from 'generated';
import { useCallback, useEffect, useState } from 'react';
import { school_id } from 'utils/constants';
import { apiWrapper } from 'utils/http-client';
import { handleError } from 'utils/utils';

const useSchoolInfoGet = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [schoolInfo, setSchoolInfo] = useState<BasicSchoolInformationResponse | undefined>(
    undefined,
  );

  const fetchSchoolInfo = useCallback(async () => {
    setLoading(true);
    try {
      const data = await apiWrapper(() =>
        BasicSchoolInformationRestControllerService.getSchoolInformationUsingGet({
          schoolId: school_id,
        }),
      );
      setSchoolInfo(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  }, []);

  useEffect(() => {
    fetchSchoolInfo();
  }, [fetchSchoolInfo]);

  useEffect(() => {
    if (schoolInfo?.basic_school_information?.color) {
      setSiteTheme(schoolInfo.basic_school_information.color);
    }
  }, [schoolInfo]);

  return {
    loadingSchoolInfo: loading,
    fetchSchoolInfo,
    schoolInfo,
  };
};

export default useSchoolInfoGet;
