import { put, takeLatest } from 'redux-saga/effects';
import invoiceActionTypes from '../types';
import { makePaymentFail, makePaymentSuccess } from '../actions';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';

export function* makePayment({ payload }) {
  try {
    const { callback } = payload;
    delete payload.callback;
    const response = yield httpClient.post(`/payments/online`, payload);
    yield put(makePaymentSuccess(response.data));
    if (payload.payment_method === 'PAYSTACK') {
      const configObj = {
        reference: response.data?.data?.safsims_transaction_ref,
        subaccount: response?.data?.data?.subaccount_ref,
      };
      callback(configObj);
    } else {
      window.location.href = response.data.data.link;
    }
  } catch (error) {
    yield put(makePaymentFail(error?.message));
    const err = handleError(error);
  }
}

export function* onMakePaymentStart() {
  yield takeLatest(invoiceActionTypes.MAKE_PAYMENT_START, makePayment);
}
