import { put, takeLatest } from 'redux-saga/effects';
import userActionTypes from '../types';
import httpClient from '../../../utils/http-client';
import { fetchUserDocsStart, userDetailsFetchFailure, userDetailsFetchSuccess } from '../actions';

export function* fetchUserDetails({ payload }) {
  try {
    const response = yield httpClient.get(`${payload.link}/${payload.id}`);
    yield put(userDetailsFetchSuccess(response.data));
  } catch (error) {
    yield put(userDetailsFetchFailure(error?.message));
  }
}

export function* onFetchUserDetails() {
  yield takeLatest(userActionTypes.FETCH_CURRENT_USER_DETAILS_START, fetchUserDetails);
}
