import { takeLatest, put } from 'redux-saga/effects';
import {
  createNewSessionSuccess,
  createNewSessionFail,
  fetchAllExistingTermsStart,
} from '../actions';
import httpClient from 'utils/http-client';
import { Notify } from '@flexisaf/flexibull2';
import configActionTypes from '../types';
import { handleError } from 'utils/utils';

export function* createNewSession({ payload }) {
  try {
    let onboardingCallback = () => {};
    if (payload.hasOwnProperty('onboardingCallback')) {
      onboardingCallback = payload.onboardingCallback;
      delete payload.onboardingCallback;
    }
    const response = yield httpClient.post(`/terms/multiple`, payload?.terms);
    yield put(createNewSessionSuccess(response.data));
    Notify('Session started successfully. Set the current term.', { position: 'top-right' });
    yield put(fetchAllExistingTermsStart());
    payload.callback();
    onboardingCallback();
  } catch (error) {
    yield put(createNewSessionFail(error.fail));
    const err = handleError(error);
  }
}

export function* onCreateNewSessionStart() {
  yield takeLatest(configActionTypes.CREATE_NEW_SESSION_START, createNewSession);
}
