import { all, call } from 'redux-saga/effects';
import { onCreateStaffStart } from './createStaff';
import { onFetchStaffsStart } from './fetchStaffs';
import { onFetchStaffsStatsStart } from './fetchStaffsStats';
import { onEditStaffStart } from './editStaff';
import { onDownloadXLSXTempleteStart } from './downloadXLSXTemplete';
import { onBulkStaffUploadStart } from './bulkStaffUpload';
import { onStaffStatusStart } from './staffStatus';
import { onFilterStaffsStart } from './filterStaffs';
import { onSaveAndCreateAnotherStaff } from './saveAndCreateAnotherStaff';
import { onUpdateStaffPictureStart } from './updateStaffPicture';
import { onAcceptProfileEdit } from './acceptStaffProfileEdit';
import { onRejectProfileEdit } from './rejectStaffProfileEdit';
import { onRequestProfileEdit } from './requestStaffProfileEdit';
import { onFetchEditRequests } from './fetchProfileEditRequests';
import { onDeleteStaffStart } from './deleteStaff';
import { onFilterEditRequestsStart } from './filterProfileEditRequests';
import { onDocumentUploadStart } from './documentUpload';
import { onFetchListOfStaffDocuments } from './fetchListOfStaffDocuments';
import { onDownloadStaffDocumentStart } from './downloadStaffDocument';
import { onDeleteStaffDocumentStart } from './deleteStaffDocument';
import { onAdminResetPasswordStart } from './adminResetPasswordSagas';
import { onSendPasswordResetLinkStart } from './sendPasswordResetLinkSagas';

export function* StaffSagas() {
  yield all([
    call(onCreateStaffStart),
    call(onFetchStaffsStart),
    call(onFetchStaffsStatsStart),
    call(onEditStaffStart),
    call(onDownloadXLSXTempleteStart),
    call(onBulkStaffUploadStart),
    call(onStaffStatusStart),
    call(onFilterStaffsStart),
    call(onSaveAndCreateAnotherStaff),
    call(onUpdateStaffPictureStart),
    call(onAcceptProfileEdit),
    call(onRejectProfileEdit),
    call(onRequestProfileEdit),
    call(onFetchEditRequests),
    call(onDeleteStaffStart),
    call(onFilterEditRequestsStart),
    call(onDocumentUploadStart),
    call(onFetchListOfStaffDocuments),
    call(onDownloadStaffDocumentStart),
    call(onDeleteStaffDocumentStart),
    call(onAdminResetPasswordStart),
    call(onSendPasswordResetLinkStart),
  ]);
}
