import broadSheetTypes from './types';

export const fetchClassArmBroadsheetStart = (
  termId: string,
  classLevelId: string,
  armId: string,
) => ({
  type: broadSheetTypes.FETCH_CLASS_ARM_BROADSHEET_START,
  payload: { termId, classLevelId, armId },
});

export const fetchClassArmBroadsheetSuccess = (data) => ({
  type: broadSheetTypes.FETCH_CLASS_ARM_BROADSHEET_SUCCESS,
  payload: data,
});

export const fetchClassArmBroadsheetFail = (error) => ({
  type: broadSheetTypes.FETCH_CLASS_ARM_BROADSHEET_FAIL,
  payload: error,
});

export const fetchClassLevelBroadsheetStart = (termId: string, levelId: string) => ({
  type: broadSheetTypes.FETCH_CLASS_LEVEL_BROADSHEET_START,
  payload: { termId, levelId },
});

export const fetchClassLevelBroadsheetSuccess = (data) => ({
  type: broadSheetTypes.FETCH_CLASS_LEVEL_BROADSHEET_SUCCESS,
  payload: data,
});

export const fetchClassLevelBroadsheetFail = (error) => ({
  type: broadSheetTypes.FETCH_CLASS_LEVEL_BROADSHEET_FAIL,
  payload: error,
});

export const studentResultApprovalStart = (data) => ({
  type: broadSheetTypes.SINGLE_STUDENT_RESULT_APPROVAL_START,
  payload: data,
});

export const studentResultApprovalSuccess = (data) => ({
  type: broadSheetTypes.SINGLE_STUDENT_RESULT_APPROVAL_SUCCESS,
  payload: data,
});

export const studentResultApprovalFail = (error) => ({
  type: broadSheetTypes.SINGLE_STUDENT_RESULT_APPROVAL_FAIL,
  payload: error,
});

export const getCumulativeArmBroadsheetStart = (
  termId: string,
  classLevelId: string,
  armId: string,
) => ({
  type: broadSheetTypes.FETCH_CUMMULATIVE_ARM_BROADSHEET_START,
  payload: { termId, classLevelId, armId },
});

export const getCumulativeArmBroadsheetSuccess = (data) => ({
  type: broadSheetTypes.FETCH_CUMMULATIVE_ARM_BROADSHEET_SUCCESS,
  payload: data,
});

export const getCumulativeArmBroadsheetFail = (error) => ({
  type: broadSheetTypes.FETCH_CUMMULATIVE_ARM_BROADSHEET_FAIL,
  payload: error,
});

export const getCumulativeLevelBroadsheetStart = (termId: string, classLevelId: string) => ({
  type: broadSheetTypes.FETCH_CUMULATIVE_LEVEL_BROADSHEET_START,
  payload: { termId, classLevelId },
});

export const getCumulativeLevelBroadsheetSuccess = (data) => ({
  type: broadSheetTypes.FETCH_CUMULATIVE_LEVEL_BROADSHEET_SUCCESS,
  payload: data,
});

export const getCumulativeLevelBroadsheetFail = (error) => ({
  type: broadSheetTypes.FETCH_CUMULATIVE_LEVEL_BROADSHEET_FAIL,
  payload: error,
});

export const fetchStudentResultDataStart = (studentId, termId, configId, cumulative) => ({
  type: broadSheetTypes.FETCH_STUDENT_RESULT_DATA_START,
  payload: { studentId, termId, configId, cumulative },
});

export const fetchStudentResultDataSuccess = (data) => ({
  type: broadSheetTypes.FETCH_STUDENT_RESULT_DATA_SUCCESS,
  payload: data,
});

export const fetchStudentResultDataFail = (error) => ({
  type: broadSheetTypes.FETCH_STUDENT_RESULT_DATA_FAIL,
  payload: error,
});

export const fetchBulkStudentsResultDataStart = (
  classLevelId,
  armId,
  termId,
  configId,
  cumulative,
) => ({
  type: broadSheetTypes.FETCH_BULK_STUDENTS_RESULT_DATA_START,
  payload: { classLevelId, armId, termId, configId, cumulative },
});

export const fetchBulkStudentsResultDataSuccess = (data) => ({
  type: broadSheetTypes.FETCH_BULK_STUDENTS_RESULT_DATA_SUCCESS,
  payload: data,
});

export const fetchBulkStudentsResultDataFail = (error) => ({
  type: broadSheetTypes.FETCH_BULK_STUDENTS_RESULT_DATA_FAIL,
  payload: error,
});

export const setDefaultTemplateStart = (classLevelId, termId) => ({
  type: broadSheetTypes.SET_DEFAULT_TEMPLATE_CLASS_START,
  payload: { classLevelId, termId },
});

export const setDefaultTemplateSuccess = (data, from) => ({
  type: broadSheetTypes.SET_DEFAULT_TEMPLATE_CLASS_SUCCESS,
  payload: { data, from },
});

export const setDefaultTemplateFail = (error) => ({
  type: broadSheetTypes.SET_DEFAULT_TEMPLATE_CLASS_FAIL,
  payload: error,
});
