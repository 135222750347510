import { persistReducer } from 'redux-persist';
import storage from 'localforage';
import invoiceActionTypes from './types';
import { InsertBeginning, findIndexInArray } from 'utils/utils';
import { FIX_ANY } from 'utils/types';

const INITIAL_STATE = {
  isLoading: false,
  gettingAssessment: false,
  isFetchingTerms: false,
  buttonLoading: false,
  deleteButtonLoading: false,
  invoiceModal: false,
  editModal: false,
  deleteModal: false,
  listOfInvoices: [],
  listOfClassLevel: [],
  listOfSessions: [],
  listOfTerms: [],
  allTerms: [],
  listOfItems: [],
  total: 0,
  currentInvoice: {},
  studentInvoices: [],
  addInvoiceItemModal: false,
  invoiceDiscountModal: false,
  editDiscountModal: false,
  deleteItemModal: false,
  editDiscountItem: {},
  invoiceDiscount: [],
  paymentRegistrationModal: false,
  makePaymentModal: false,
  receiptModal: false,
  outstandingBillModal: false,
  checkout: {},
  invoiceSummary: [],
  receipts: [],
  activeReceipt: {},
  paymentLink: '',
  paymentStatus: null,
  transactionLogDetails: [],
  logLoader: false,
  editItemQuantities: false,
  revenueSummary: {},
  revenueStatistics: {},
  total_logs: 0,
  singlePageCheckout: {},
  directPayLink: '',
  directPayModal: false,
  bulkCheckoutInfo: [],
  makeBulkPaymentModal: false,
};

export interface IInvoiceState {
  isLoading: boolean;
  isFetchingTerms: boolean;
  buttonLoading: boolean;
  deleteButtonLoading: boolean;
  invoiceModal: boolean;
  editModal: boolean;
  deleteModal: boolean;
  listOfInvoices: FIX_ANY[];
  listOfClassLevel: FIX_ANY[];
  listOfSessions: FIX_ANY[];
  listOfTerms: FIX_ANY[];
  allTerms: FIX_ANY[];
  listOfItems: FIX_ANY[];
  total: number;
  currentInvoice: FIX_ANY;
  studentInvoices: FIX_ANY[];
  addInvoiceItemModal: boolean;
  invoiceDiscountModal: boolean;
  editDiscountModal: boolean;
  deleteItemModal: boolean;
  editDiscountItem: FIX_ANY;
  invoiceDiscount: FIX_ANY[];
  paymentRegistrationModal: boolean;
  makePaymentModal: boolean;
  receiptModal: boolean;
  outstandingBillModal: boolean;
  checkout: FIX_ANY;
  invoiceSummary: FIX_ANY[];
  receipts: FIX_ANY[];
  activeReceipt: FIX_ANY;
  paymentLink: string;
  paymentStatus: FIX_ANY;
  transactionLogDetails: FIX_ANY[];
  logLoader: boolean;
  editItemQuantities: boolean;
  revenueSummary: FIX_ANY;
  revenueStatistics: FIX_ANY;
  total_logs: number;
  singlePageCheckout: FIX_ANY;
  directPayLink: string;
  directPayModal: boolean;
  bulkCheckoutInfo: FIX_ANY[];
  makeBulkPaymentModal: boolean;
}

interface IParam {
  type: invoiceActionTypes;
  payload: any;
}

function invoiceReducer(state: IInvoiceState = INITIAL_STATE, { type, payload }: IParam) {
  switch (type) {
    case invoiceActionTypes.FETCH_INVOICES_START:
      return {
        ...state,
        isLoading: true,
      };
    case invoiceActionTypes.FETCH_INVOICES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        // listOfInvoices: payload.content,
        // total: payload.total_elements,
        listOfInvoices: payload,
      };
    case invoiceActionTypes.FETCH_INVOICES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: null,
      };

    case invoiceActionTypes.FETCH_INVOICES_CLASS_LEVEL_START:
      return {
        ...state,
      };
    case invoiceActionTypes.FETCH_INVOICES_CLASS_LEVEL_SUCCESS:
      return {
        ...state,
        listOfClassLevel: payload,
      };
    case invoiceActionTypes.FETCH_INVOICES_CLASS_LEVEL_FAILURE:
      return {
        ...state,
        error: null,
      };

    case invoiceActionTypes.FETCH_INVOICES_SESSIONS_START:
      return {
        ...state,
      };
    case invoiceActionTypes.FETCH_INVOICES_SESSIONS_SUCCESS:
      return {
        ...state,
        listOfSessions: payload,
      };
    case invoiceActionTypes.FETCH_INVOICES_SESSIONS_FAILURE:
      return {
        ...state,
        error: null,
      };

    case invoiceActionTypes.FETCH_INVOICES_TERMS_START:
      return {
        ...state,
        isFetchingTerms: true,
      };
    case invoiceActionTypes.FETCH_INVOICES_TERMS_SUCCESS:
      return {
        ...state,
        listOfTerms: payload,
        isFetchingTerms: false,
      };
    case invoiceActionTypes.FETCH_INVOICES_TERMS_FAILURE:
      return {
        ...state,
        error: null,
        isFetchingTerms: false,
      };

    case invoiceActionTypes.FETCH_ALL_TERMS_START:
      return {
        ...state,
      };
    case invoiceActionTypes.FETCH_ALL_TERMS_SUCCESS:
      return {
        ...state,
        allTerms: payload,
      };
    case invoiceActionTypes.FETCH_ALL_TERMS_FAILURE:
      return {
        ...state,
        error: null,
      };

    case invoiceActionTypes.FETCH_INVOICES_ITEMS_START:
      return {
        ...state,
      };
    case invoiceActionTypes.FETCH_INVOICES_ITEMS_SUCCESS:
      return {
        ...state,
        listOfItems: payload.content,
      };
    case invoiceActionTypes.FETCH_INVOICES_ITEMS_FAILURE:
      return {
        ...state,
        error: null,
      };

    case invoiceActionTypes.CREATE_INVOICE_START:
      return {
        ...state,
        buttonLoading: true,
      };
    case invoiceActionTypes.CREATE_INVOICE_SUCCESS:
      const saveModifiedEntities = InsertBeginning(state.listOfInvoices, payload);
      return {
        ...state,
        buttonLoading: false,
        invoiceModal: false,
        listOfInvoices: saveModifiedEntities,
      };
    case invoiceActionTypes.CREATE_INVOICE_FAILURE:
      return {
        ...state,
        buttonLoading: false,
        error: null,
      };

    case invoiceActionTypes.EDIT_INVOICE_START:
      return {
        ...state,
        buttonLoading: true,
      };
    case invoiceActionTypes.EDIT_INVOICE_SUCCESS:
      // const indexOfEditedInvoice = findIndexInArray(state.listOfInvoices, payload, 'id');
      // state.listOfInvoices.splice(indexOfEditedInvoice, 1, payload);
      return {
        ...state,
        buttonLoading: false,
        editModal: false,
      };
    case invoiceActionTypes.EDIT_INVOICE_FAILURE:
      return {
        ...state,
        buttonLoading: false,
        error: null,
      };

    case invoiceActionTypes.DELETE_INVOICE_START:
      return {
        ...state,
        buttonLoading: true,
      };
    case invoiceActionTypes.DELETE_INVOICE_SUCCESS:
      const indexOfDeleteInvoice = state.listOfInvoices.findIndex(
        (x) =>
          x.class_level.class_level_id === payload.class_level_id &&
          x.term.term_id === payload.term_id,
      );
      state.listOfInvoices.splice(indexOfDeleteInvoice, 1);
      return {
        ...state,
        buttonLoading: false,
        deleteModal: false,
      };
    case invoiceActionTypes.DELETE_INVOICE_FAILURE:
      return {
        ...state,
        buttonLoading: false,
        error: null,
      };

    case invoiceActionTypes.HANDLE_CREATE_INVOICE_MODAL:
      return {
        ...state,
        invoiceModal: !payload,
      };

    case invoiceActionTypes.HANDLE_EDIT_INVOICE_MODAL:
      return {
        ...state,
        editModal: !payload.editModal,
        currentInvoice: payload.data,
      };
    case invoiceActionTypes.HANDLE_CLOSE_EDIT_INVOICE_MODAL:
      return {
        ...state,
        editModal: !payload.editModal,
      };

    case invoiceActionTypes.HANDLE_DELETE_MODAL:
      return {
        ...state,
        deleteModal: !payload,
      };
    case invoiceActionTypes.FETCH_STUDENT_INVOICES_START:
      return {
        ...state,
        isLoading: true,
      };
    case invoiceActionTypes.FETCH_STUDENT_INVOICES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        studentInvoices: payload,
      };
    case invoiceActionTypes.FETCH_STUDENT_INVOICES_FAIL:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case invoiceActionTypes.HANDLE_ADD_INVOICE_ITEM_MODAL:
      return {
        ...state,
        addInvoiceItemModal: !payload,
      };
    case invoiceActionTypes.HANDLE_INVOICE_DISCOUNT_MODAL:
      return {
        ...state,
        invoiceDiscountModal: !payload,
      };
    case invoiceActionTypes.HANDLE_EDIT_DISCOUNT_MODAL:
      return {
        ...state,
        editDiscountModal: !payload,
      };
    case invoiceActionTypes.ADD_ITEM_TO_INVOICE_START:
      return {
        ...state,
        buttonLoading: true,
        error: null,
      };
    case invoiceActionTypes.ADD_ITEM_TO_INVOICE_SUCCESS:
      return {
        ...state,
        buttonLoading: false,
        addInvoiceItemModal: false,
        studentInvoices: [...state.studentInvoices, ...payload],
        error: null,
      };
    case invoiceActionTypes.ADD_ITEM_TO_INVOICE_FAIL:
      return {
        ...state,
        buttonLoading: false,
        error: payload,
      };
    case invoiceActionTypes.FETCH_INVOICE_DISCOUNT_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case invoiceActionTypes.FETCH_INVOICE_DISCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        invoiceDiscount: payload,
        error: null,
      };
    case invoiceActionTypes.FETCH_INVOICE_DISCOUNT_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case invoiceActionTypes.ADD_INVOICE_DISCOUNT_START:
      return {
        ...state,
        buttonLoading: true,
        error: null,
      };
    case invoiceActionTypes.ADD_INVOICE_DISCOUNT_SUCCESS:
      return {
        ...state,
        buttonLoading: false,
        invoiceDiscountModal: false,
        editDiscountModal: false,
        error: null,
      };
    case invoiceActionTypes.ADD_INVOICE_DISCOUNT_FAIL:
      return {
        ...state,
        buttonLoading: false,
        error: payload,
      };
    case invoiceActionTypes.DELETE_INVOICE_DISCOUNT_START:
      return {
        ...state,
        deleteButtonLoading: true,
        error: null,
      };
    case invoiceActionTypes.DELETE_INVOICE_DISCOUNT_SUCCESS:
      return {
        ...state,
        deleteButtonLoading: false,
        invoiceDiscountModal: false,
        editDiscountModal: false,
        error: null,
      };
    case invoiceActionTypes.DELETE_INVOICE_DISCOUNT_FAIL:
      return {
        ...state,
        deleteButtonLoading: false,
        error: payload,
      };
    case invoiceActionTypes.SET_EDIT_DISCOUNT_ITEM:
      return {
        ...state,
        editDiscountItem: payload,
      };
    case invoiceActionTypes.HANDLE_DELETE_INVOICE_ITEM_MODAL:
      return {
        ...state,
        deleteItemModal: !payload,
      };
    case invoiceActionTypes.DELETE_ITEM_FROM_INVOICE_START:
      return {
        ...state,
        buttonLoading: true,
        error: null,
      };
    case invoiceActionTypes.DELETE_ITEM_FROM_INVOICE_SUCCESS:
      const newArr = state.studentInvoices.filter(
        ({ payable_item }) => payable_item.id !== payload,
      );
      return {
        ...state,
        studentInvoices: newArr,
        buttonLoading: false,
        deleteItemModal: false,
        error: null,
      };
    case invoiceActionTypes.DELETE_ITEM_FROM_INVOICE_FAIL:
      return {
        ...state,
        buttonLoading: false,
        error: payload,
      };
    case invoiceActionTypes.MAKE_PAYMENT_START:
      return {
        ...state,
        buttonLoading: true,
        error: null,
      };
    case invoiceActionTypes.MAKE_PAYMENT_SUCCESS:
      return {
        ...state,
        buttonLoading: false,
        paymentLink: payload.data.link,
        error: null,
      };
    case invoiceActionTypes.MAKE_PAYMENT_FAIL:
      return {
        ...state,
        buttonLoading: false,
        error: payload,
      };
    case invoiceActionTypes.HANDLE_PAYMENT_REGISTRATION_MODAL:
      return {
        ...state,
        paymentRegistrationModal: !payload,
      };
    case invoiceActionTypes.HANDLE_MAKE_PAYMENT_MODAL:
      return {
        ...state,
        makePaymentModal: !payload,
      };
    case invoiceActionTypes.HANDLE_OUTSTANDING_BILL_MODAL:
      return {
        ...state,
        outstandingBillModal: !payload,
      };
    case invoiceActionTypes.HANDLE_RECEIPT_MODAL:
      return {
        ...state,
        receiptModal: !payload,
      };
    case invoiceActionTypes.EDIT_INVOICE_ITEMS_START:
      return {
        ...state,
        buttonLoading: true,
        error: null,
      };
    case invoiceActionTypes.EDIT_INVOICE_ITEMS_SUCCESS:
      return {
        ...state,
        buttonLoading: false,
        makePaymentModal: true,
        error: null,
      };
    case invoiceActionTypes.EDIT_INVOICE_ITEMS_FAIL:
      return {
        ...state,
        buttonLoading: false,
        error: payload,
      };
    case invoiceActionTypes.PARENT_ADD_INVOICE_ITEM_START:
      return {
        ...state,
        buttonLoading: true,
        error: null,
      };
    case invoiceActionTypes.PARENT_ADD_INVOICE_ITEM_SUCCESS:
      return {
        ...state,
        buttonLoading: false,
        addInvoiceItemModal: false,
        error: null,
      };
    case invoiceActionTypes.PARENT_ADD_INVOICE_ITEM_FAIL:
      return {
        ...state,
        buttonLoading: false,
        error: payload,
      };
    case invoiceActionTypes.ADMIN_EDIT_INVOICE_ITEMS_START:
      return {
        ...state,
        buttonLoading: true,
        editItemQuantities: true,
        error: null,
      };
    case invoiceActionTypes.ADMIN_EDIT_INVOICE_ITEMS_SUCCESS:
      return {
        ...state,
        buttonLoading: false,
        editItemQuantities: false,
        error: null,
      };
    case invoiceActionTypes.ADMIN_EDIT_INVOICE_ITEMS_FAIL:
      return {
        ...state,
        buttonLoading: false,
        editItemQuantities: false,
        error: payload,
      };
    case invoiceActionTypes.SET_QUANTITY_EDIT:
      return {
        ...state,
        editItemQuantities: !payload,
      };
    case invoiceActionTypes.RECEIVE_PAYMENT_START:
      return {
        ...state,
        buttonLoading: true,
        error: null,
      };
    case invoiceActionTypes.RECEIVE_PAYMENT_SUCCESS:
      return {
        ...state,
        buttonLoading: false,
        paymentRegistrationModal: false,
        error: null,
      };
    case invoiceActionTypes.RECEIVE_PAYMENT_FAIL:
      return {
        ...state,
        buttonLoading: false,
        error: payload,
      };
    case invoiceActionTypes.SEND_OUTSTANDING_BILL_EMAIL_START:
      return {
        ...state,
        buttonLoading: true,
        error: null,
      };
    case invoiceActionTypes.SEND_OUTSTANDING_BILL_EMAIL_SUCCESS:
      return {
        ...state,
        buttonLoading: false,
        outstandingBillModal: false,
        error: null,
      };
    case invoiceActionTypes.SEND_OUTSTANDING_BILL_EMAIL_FAIL:
      return {
        ...state,
        buttonLoading: false,
        error: payload,
      };
    case invoiceActionTypes.GET_CHECKOUT_START:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case invoiceActionTypes.GET_CHECKOUT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        checkout: payload,
        error: null,
      };
    case invoiceActionTypes.GET_CHECKOUT_FAIL:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };

    case invoiceActionTypes.FETCH_STUDENT_INVOICE_SUMMARY_START:
      return {
        ...state,
        isLoading: true,
        invoiceSummary: [],
      };
    case invoiceActionTypes.FETCH_STUDENT_INVOICE_SUMMARY_SUCCESS:
      return {
        ...state,
        invoiceSummary: payload,
        isLoading: false,
      };
    case invoiceActionTypes.FETCH_STUDENT_INVOICE_SUMMARY_FAIL:
      return {
        ...state,
        error: payload,
        isLoading: false,
      };

    case invoiceActionTypes.FETCH_PAYMENT_RECEIPT_START:
      return {
        ...state,
        isLoading: true,
      };
    case invoiceActionTypes.FETCH_PAYMENT_RECEIPT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        receipts: payload,
      };
    case invoiceActionTypes.FETCH_PAYMENT_RECEIPT_FAIL:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case invoiceActionTypes.SET_ACTIVE_RECEIPT:
      return {
        ...state,
        activeReceipt: payload,
      };
    case invoiceActionTypes.VERIFY_PAYMENT_START:
      return {
        ...state,
        isLoading: true,
      };
    case invoiceActionTypes.VERIFY_PAYMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        paymentStatus: payload,
      };
    case invoiceActionTypes.VERIFY_PAYMENT_FAIL:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case invoiceActionTypes.GET_TRANSACTION_LOG_START:
      return {
        ...state,
        logLoader: true,
      };
    case invoiceActionTypes.GET_TRANSACTION_LOG_SUCCESS:
      return {
        ...state,
        transactionLogDetails: payload.content,
        total_logs: payload.total_elements,
        logLoader: false,
      };
    case invoiceActionTypes.GET_TRANSACTION_LOG_FAIL:
      return {
        ...state,
        logLoader: false,
      };
    case invoiceActionTypes.FETCH_REVENUE_SUMMARY_START:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case invoiceActionTypes.FETCH_REVENUE_SUMMARY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        revenueSummary: payload,
        error: null,
      };
    case invoiceActionTypes.FETCH_REVENUE_SUMMARY_FAIL:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case invoiceActionTypes.FETCH_REVENUE_STATISTICS_START:
      return {
        ...state,
        error: null,
      };
    case invoiceActionTypes.FETCH_REVENUE_STATISTICS_SUCCESS:
      return {
        ...state,
        revenueStatistics: payload,
        error: null,
      };
    case invoiceActionTypes.FETCH_REVENUE_STATISTICS_FAIL:
      return {
        ...state,
        error: payload,
      };
    case invoiceActionTypes.GET_SINGLE_PAGE_CHECKOUT_START:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case invoiceActionTypes.GET_SINGLE_PAGE_CHECKOUT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        singlePageCheckout: payload,
        error: null,
      };
    case invoiceActionTypes.GET_SINGLE_PAGE_CHECKOUT_FAIL:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case invoiceActionTypes.MAKE_DIRECT_PAY_START:
      return {
        ...state,
        buttonLoading: true,
        error: null,
      };
    case invoiceActionTypes.MAKE_DIRECT_PAY_SUCCESS:
      return {
        ...state,
        buttonLoading: false,
        directPayLink: payload.data.link,
        error: null,
      };
    case invoiceActionTypes.MAKE_DIRECT_PAY_FAIL:
      return {
        ...state,
        buttonLoading: false,
        error: payload,
      };
    case invoiceActionTypes.HANDLE_DIRECT_PAY_MODAL:
      return {
        ...state,
        directPayModal: !payload,
      };
    case invoiceActionTypes.GET_BULK_PAYMENT_CHECKOUT_START:
      return {
        ...state,
        error: null,
      };
    case invoiceActionTypes.GET_BULK_PAYMENT_CHECKOUT_SUCCESS:
      return {
        ...state,
        bulkCheckoutInfo: payload,
        error: null,
      };
    case invoiceActionTypes.GET_BULK_PAYMENT_CHECKOUT_FAIL:
      return {
        ...state,
        error: payload,
      };
    case invoiceActionTypes.HANDLE_MAKE_BULK_PAYMENT_MODAL:
      return {
        ...state,
        makeBulkPaymentModal: !payload,
      };
    case invoiceActionTypes.MAKE_BULK_PAYMENT_START:
      return {
        ...state,
        buttonLoading: true,
        error: null,
      };
    case invoiceActionTypes.MAKE_BULK_PAYMENT_SUCCESS:
      return {
        ...state,
        buttonLoading: false,
        paymentLink: payload.link,
        makeBulkPaymentModal: false,
        error: null,
      };
    case invoiceActionTypes.MAKE_BULK_PAYMENT_FAIL:
      return {
        ...state,
        buttonLoading: false,
        error: payload,
      };
    case invoiceActionTypes.UPDATE_INVOICE_STATE:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
}

const persistConfig = {
  key: 'invoices',
  storage,
  blacklist: [
    'error',
    'isLoading',
    'buttonLoading',
    'invoiceModal',
    'editModal',
    'deleteModal',
    'isFetchingTerms',
    'listOfTerms',
    'addInvoiceItemModal',
    'invoiceDiscountModal',
    'editDiscountModal',
    'editDiscountItem',
    'deleteItemModal',
    'studentInvoices',
    'paymentRegistrationModal',
    'makePaymentModal',
    'receiptModal',
    'outstandingBillModal',
    'receipts',
    'activeReceipt',
    'logLoader',
    'editItemQuantities',
    'directPayModal',
    'makeBulkPaymentModal',
  ],
};

export default persistReducer(persistConfig, invoiceReducer);
