import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { selectAccessToken, selectIsLoggedIn } from 'redux/users/selectors';
import { Loadable } from 'utils/ComponentLoader';

const Dashboard = Loadable(() => import('pages/Dashboard'), 'dashboard');

const PrivateDashboard = () => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const accessToken = useSelector(selectAccessToken);
  const auth = !!(isLoggedIn && accessToken);
  return auth ? <Dashboard /> : <Navigate to="/" />;
};

export default PrivateDashboard;
