import { OnboardingStepsInfo } from 'components/onboarding/StepsInfo';
import { put, takeLatest } from 'redux-saga/effects';
import { school_id } from 'utils/constants';
import httpClient from 'utils/http-client';
import { fetchHomePageFail, fetchHomePageSuccess } from '../actions';
import configActionTypes from '../types';

export function* fetchHomepageInfo() {
  try {
    let { data } = yield httpClient.get(`/school-info/${school_id}`);
    const steps = data.onboarding_steps;
    const updatedSteps = OnboardingStepsInfo.map((x) => ({
      ...x,
      status: steps.find((y) => y?.name?.toLowerCase() === x.name.toLowerCase()) ? true : false,
    }));
    data.onboarding_steps = updatedSteps;
    yield put(fetchHomePageSuccess(data));
  } catch (error) {
    yield put(fetchHomePageFail(error.info));
    window.location.href = '/unauthorized';
  }
}

export function* onFetchHomepageStart() {
  yield takeLatest(configActionTypes.FETCH_HOMEPAGE_START, fetchHomepageInfo);
}
