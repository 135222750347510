import { put, takeLatest } from 'redux-saga/effects';
import invoiceActionTypes from '../types';
import {
  addInvoiceDiscountFail,
  addInvoiceDiscountSuccess,
  fetchInvoiceDiscountStart,
  fetchStudentIncoiceSummaryStart,
} from '../actions';
import { Notify } from '@flexisaf/flexibull2';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';

export function* addDiscount({ payload }) {
  const callback = payload.callback;
  delete payload.callback;
  let payload2 = {
    'session-id': payload.session_id,
    'term-id': payload.term_id,
    student_id: payload.student_id,
  };
  try {
    const response = yield httpClient.post(`/school-fees-discounts`, payload);
    Notify('Invoice discount updated successfully.', { position: 'top-right' });
    yield put(addInvoiceDiscountSuccess(response.data));
    yield put(fetchInvoiceDiscountStart(payload2));
    yield put(fetchStudentIncoiceSummaryStart(payload.student_id));
    callback();
  } catch (error) {
    yield put(addInvoiceDiscountFail(error?.message));
    const err = handleError(error);
  }
}

export function* onAddInvoiceDiscount() {
  yield takeLatest(invoiceActionTypes.ADD_INVOICE_DISCOUNT_START, addDiscount);
}
