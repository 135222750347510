import localforage from 'localforage';
import { put, takeLatest } from 'redux-saga/effects';
import { setSiteConfig } from 'redux/global-store/actions';
import { setSchoolDirector, setSuperAdmin } from 'redux/privileges/actions';
import httpClient from '../../../utils/http-client';
import { DIRECTOR_ROLE, handleError, ONBOARDING_ROLE } from '../../../utils/utils';
import {
  getUserDetailsFailure,
  getUserDetailsSuccess,
  setAccessToken,
  setActiveUserType,
  setCurrentStaffItems,
  setRefreshToken,
  setUserTypes,
  userLoginSuccess,
} from '../actions';
import authActionTypes from '../types';

export function* getUserDetails({ data }) {
  const callback = data.callback;
  const config = data.config;
  const history = data.history;
  const checkOnboardingStatus = data.checkOnboardingStatus;
  const verifyUserPrivileges = data.verifyUserPrivileges;
  delete data.callBack;
  delete data.history;
  delete data.verifyUserPrivileges;
  delete data.config;
  delete data.checkOnboardingStatus;
  try {
    const response = yield httpClient.post(`auth/google/transfer`, data);

    const ref = response.data;
    const ums = ref.ums_login_response;
    const roles = ums.user.roles;

    const isSuperAdmin = !!roles.find((item) => item.role.name === ONBOARDING_ROLE);
    const isDirector = !!roles.find((item) => item.role.name === DIRECTOR_ROLE);

    yield put(setSuperAdmin(isSuperAdmin));
    yield put(setSchoolDirector(isDirector));

    let userTypes = [];
    ref.user_types.map((type) => {
      let str = type.toLowerCase();
      userTypes.push({ user_type: str, ...ref[str] });
    });

    verifyUserPrivileges(roles);
    yield put(setAccessToken(ums.access_token));
    yield put(setUserTypes(userTypes));

    yield put(setRefreshToken(ums.refresh_token));
    yield put(setSiteConfig({ ...config, accessToken: ums.access_token }));

    localforage.setItem('access_token', ums.access_token);
    localforage.setItem('refresh_token', ums.refresh_token);

    if (ref.user_types.find((x) => x.toLowerCase() === 'staff')) {
      const form_teachers = ref.form_teachers || [];
      const subject_teachers = ref.subject_teachers || [];

      const currentStaffClassView = [
        ...form_teachers.map((x) => ({
          class_level: x.class_level.id,
          arm: x.arm.id,
        })),
        ...subject_teachers.map((x) => ({
          class_level: x.class_information.class_level.id,
          arm: x.class_information.arm.id,
        })),
      ];

      yield put(
        setCurrentStaffItems({
          currentStaffClasses: form_teachers,
          currentStaffSubjects: subject_teachers,
          currentStaffClassView,
        }),
      );
    }

    yield put(userLoginSuccess(userTypes[0]));
    yield put(setActiveUserType(userTypes[0].user_type));
    localStorage.setItem('access_token', ums.access_token);

    if (isSuperAdmin && userTypes[0].user_type.toLowerCase() === 'staff') {
      checkOnboardingStatus();
    } else {
      history(isDirector ? '/director-dashboard' : '/user-profile');
    }

    yield put(getUserDetailsSuccess(response.data));
  } catch (error) {
    callback();
    yield put(getUserDetailsFailure(err));
    const err = handleError(error);
  }
}

export function* onGetUserDetailsStart() {
  yield takeLatest(authActionTypes.GET_USER_DETAILS_START, getUserDetails);
}
