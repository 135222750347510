import { takeLatest, put } from 'redux-saga/effects';
import httpClient from 'utils/http-client';
import { fetchClassLevelBroadsheetFail, fetchClassLevelBroadsheetSuccess } from '../actions';
import { Notify } from '@flexisaf/flexibull2';
import broadSheetTypes from '../types';
import { handleError } from 'utils/utils';

export function* fetchClassLevelBroadsheet({ payload }) {
  const params = {
    'class-level-id': payload.levelId,
    'term-id': payload.termId,
  };
  try {
    const response = yield httpClient.get(`/student-results/class-level-broad-sheet`, { params });
    yield put(fetchClassLevelBroadsheetSuccess(response.data));
  } catch (error) {
    yield put(fetchClassLevelBroadsheetFail(error?.message));
    const obj = {
      code: 404,
      message: 'No Grading Format defined for class. Please contact your school admin',
    };
    const err = handleError(error, obj);
  }
}

export function* onFetchClassLevelBroadsheetStart() {
  yield takeLatest(broadSheetTypes.FETCH_CLASS_LEVEL_BROADSHEET_START, fetchClassLevelBroadsheet);
}
