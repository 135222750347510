import { takeLatest, put } from 'redux-saga/effects';
import { fetchRatingSuccess, fetchRatingFail } from '../actions';
import httpClient from 'utils/http-client';
import earlyYearsActionTypes from '../types';
import { Notify } from '@flexisaf/flexibull2';
import { handleError } from 'utils/utils';

function* fetchAllRatings() {
  try {
    const response = yield httpClient.get('/trait-configuration/trait-ratings');
    yield put(fetchRatingSuccess(response.data));
  } catch (error) {
    yield put(fetchRatingFail(error.fail));
    const err = handleError(error);
  }
}

export function* onFetchAllRatingsStart() {
  yield takeLatest(earlyYearsActionTypes.FETCH_TRAIT_GROUP_START, fetchAllRatings);
}
