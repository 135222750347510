enum staffActionTypes {
  FETCH_COUNTRIES_START = 'FETCH_COUNTRIES_START',
  FETCH_COUNTRIES_SUCCESS = 'FETCH_COUNTRIES_SUCCESS',
  FETCH_COUNTRIES_FAILURE = 'FETCH_COUNTRIES_FAILURE',
  FETCH_STATES_START = 'FETCH_STATES_START',
  FETCH_STATES_SUCCESS = 'FETCH_STATES_SUCCESS',
  FETCH_STATES_FAILURE = 'FETCH_STATES_FAILURE',
  FETCH_LGA_START = 'FETCH_LGA_START',
  FETCH_LGA_SUCCESS = 'FETCH_LGA_SUCCESS',
  FETCH_LGA_FAILURE = 'FETCH_LGA_FAILURE',
  CREATE_STAFF_START = 'CREATE_STAFF_START',
  CREATE_STAFF_SUCCESS = 'CREATE_STAFF_SUCCESS',
  CREATE_STAFF_FAILURE = 'CREATE_STAFF_FAILURE',
  SAVE_AND_CREATE_ANOTHER_STAFF_START = 'SAVE_AND_CREATE_ANOTHER_STAFF_START',
  SAVE_AND_CREATE_ANOTHER_STAFF_SUCCESS = 'SAVE_AND_CREATE_ANOTHER_STAFF_SUCCESS',
  SAVE_AND_CREATE_ANOTHER_STAFF_FAILURE = 'SAVE_AND_CREATE_STAFF_FAILURE',
  EDIT_STAFF_START = 'EDIT_STAFF_START',
  EDIT_STAFF_SUCCESS = 'EDIT_STAFF_SUCCESS',
  EDIT_STAFF_FAILURE = 'EDIT_STAFF_FAILURE',
  FETCH_STAFFS_START = 'FETCH_STAFFS_START',
  FETCH_STAFFS_SUCCESS = 'FETCH_STAFFS_SUCCESS',
  FETCH_STAFFS_FAILURE = 'FETCH_STAFFS_FAILURE',
  FETCH_STAFFS_STATS_START = 'FETCH_STAFFS_STATS_START',
  FETCH_STAFFS_STATS_SUCCESS = 'FETCH_STAFFS_STATS_SUCCESS',
  FETCH_STAFFS_STATS_FAILURE = 'FETCH_STAFFS_STATS_FAILURE',
  FILTER_STAFFS_START = 'FILTER_STAFFS_START',
  FILTER_STAFFS_SUCCESS = 'FILTER_STAFFS_SUCCESS',
  FILTER_STAFFS_FAILURE = 'FILTER_STAFFS_FAILURE',
  DOWNLOAD_XLSX_STAFF_TEMPLATE_START = 'DOWNLOAD_XLSX_STAFF_TEMPLATE_START',
  DOWNLOAD_XLSX_STAFF_TEMPLATE_SUCCESS = 'DOWNLOAD_XLSX_STAFF_TEMPLATE_SUCCESS',
  DOWNLOAD_XLSX_STAFF_TEMPLATE_FAILURE = 'DOWNLOAD_XLSX_STAFF_TEMPLATE_FAILURE',
  BULK_STAFF_UPLOAD_START = 'BULK_STAFF_UPLOAD_START',
  BULK_STAFF_UPLOAD_SUCCESS = 'BULK_STAFF_UPLOAD_SUCCESS',
  BULK_STAFF_UPLOAD_FAILURE = 'BULK_STAFF_UPLOAD_FAILURE',
  STAFF_STATUS_START = 'STAFF_STATUS_START',
  STAFF_STATUS_SUCCESS = 'STAFF_STATUS_SUCCESS',
  STAFF_STATUS_FAILURE = 'STAFF_STATUS_FAILURE',
  RESET_LGA = 'RESET_LGA',
  SAVE_STAFF_BIO = 'SAVE_STAFF_BIO',
  SAVE_OTHER_INFO = 'SAVE_OTHER_INFO',
  OPEN_WIZARD = 'OPEN_WIZARD',
  RESET_EVERYTHING = 'RESET_EVERYTHING',
  HANDLE_STAFF_PROFILE_DATA = 'HANDLE_STAFF_PROFILE_DATA',
  HANDLE_EDIT = 'HANDLE_EDIT',
  HANDLE_BULK_MODAL = 'HANDLE_BULK_MODAL',
  HANDLE_DELETE_MODAL = 'HANDLE_DELETE_MODAL',
  HANDLE_EDIT_PICTURE_ON_PROFILE = 'HANDLE_EDIT_PICTURE_ON_PROFILE',
  UPDATE_STAFF_PICTURE_START = 'UPDATE_STAFF_PICTURE_START',
  UPDATE_STAFF_PICTURE_SUCCESS = 'UPDATE_STAFF_PICTURE_SUCCESS',
  UPDATE_STAFF_PICTURE_FAILURE = 'UPDATE_STAFF_PICTURE_FAILURE',
  CREATE_STAFF_PROFILE_EDIT_REQUEST = 'CREATE_STAFF_PROFILE_EDIT_REQUEST',
  REJECT_STAFF_EDIT_REQUEST = 'REJECT_STAFF_EDIT_REQUEST',
  ACCEPT_STAFF_EDIT_REQUEST = 'ACCEPT_STAFF_EDIT_REQUEST',
  FETCH_EDIT_REQUESTS_START = 'FETCH_EDIT_REQUESTS_START',
  FETCH_EDIT_REQUESTS_SUCCESS = 'FETCH_EDIT_REQUESTS_SUCCESS',
  FETCH_EDIT_REQUESTS_FAILURE = 'FETCH_EDIT_REQUESTS_FAILURE',
  HANDLE_EDIT_REQUEST_ACTION = 'HANDLE_EDIT_REQUEST_ACTION',
  HANDLE_DECLINE_MODAL = 'HANDLE_DECLINE_MODAL',
  DELETE_STAFF_START = 'DELETE_STAFF_START',
  DELETE_STAFF_SUCCESS = 'DELETE_STAFF_SUCCESS',
  DELETE_STAFF_FAILURE = 'DELETE_STAFF_FAILURE',
  FILTER_EDIT_REQUEST_START = 'FILTER_EDIT_REQUEST_START',
  FILTER_EDIT_REQUEST_SUCCESS = 'FILTER_EDIT_REQUEST_SUCCESS',
  FILTER_EDIT_REQUEST_FAILURE = 'FILTER_EDIT_REQUEST_FAILURE',
  UPLOAD_STAFF_DOCUMENT_START = 'UPLOAD_STAFF_DOCUMENT_START',
  UPLOAD_STAFF_DOCUMENT_SUCCESS = 'UPLOAD_STAFF_DOCUMENT_SUCCESS',
  UPLOAD_STAFF_DOCUMENT_FAILURE = 'UPLOAD_STAFF_DOCUMENT_FAILURE',
  FETCH_LIST_OF_STAFF_DOCUMENTS_START = 'FETCH_LIST_OF_STAFF_DOCUMENTS_START',
  FETCH_LIST_OF_STAFF_DOCUMENTS_SUCCESS = 'FETCH_LIST_OF_STAFF_DOCUMENTS_SUCCESS',
  FETCH_LIST_OF_STAFF_DOCUMENTS_FAILURE = 'FETCH_LIST_OF_STAFF_DOCUMENTS_FAILURE',
  HANDLE_DOCUMENT_UPLOAD_MODAL = 'HANDLE_DOCUMENT_UPLOAD_MODAL',
  STOP_UPLOAD_STAFF_BULK_LOADING = 'STOP_UPLOAD_STAFF_BULK_LOADING',

  DOWNLOAD_STAFF_DOCUMENTS_START = 'DOWNLOAD_STAFF_DOCUMENTS_START',
  DOWNLOAD_STAFF_DOCUMENTS_SUCCESS = 'DOWNLOAD_STAFF_DOCUMENTS_SUCCESS',
  DOWNLOAD_STAFF_DOCUMENTS_FAILURE = 'DOWNLOAD_STAFF_DOCUMENTS_FAILURE',

  DELETE_STAFF_DOCUMENTS_START = 'DELETE_STAFF_DOCUMENTS_START',
  DELETE_STAFF_DOCUMENTS_SUCCESS = 'DELETE_STAFF_DOCUMENTS_SUCCESS',
  DELETE_STAFF_DOCUMENTS_FAILURE = 'DELETE_STAFF_DOCUMENTS_FAILURE',

  HANDLE_DELETE_DOCUMENT_MODAL = 'HANDLE_DELETE_DOCUMENT_MODAL',

  HANDLE_RESET_MODAL = 'HANDLE_RESET_MODAL',
  RESET_SET_PASSWORD_START = 'RESET_SET_PASSWORD_START',
  RESET_SET_PASSWORD_SUCCESS = 'RESET_SET_PASSWORD_SUCCESS',
  RESET_SET_PASSWORD_FAILURE = 'RESET_SET_PASSWORD_FAILURE',
  ADMIN_RESET_PASSWORD_START = 'ADMIN_RESET_PASSWORD_START',
  ADMIN_RESET_PASSWORD_SUCCESS = 'ADMIN_RESET_PASSWORD_SUCCESS',
  ADMIN_RESET_PASSWORD_FAILURE = 'ADMIN_RESET_PASSWORD_FAILURE',
  SEND_PASSWORD_RESET_LINK_START = 'SEND_PASSWORD_RESET_LINK_START',
  SEND_PASSWORD_RESET_LINK_SUCCESS = 'SEND_PASSWORD_RESET_LINK_SUCCESS',
  SEND_PASSWORD_RESET_LINK_FAILURE = 'SEND_PASSWORD_RESET_LINK_FAILURE',

  UPDATE_STAFF_STATE = 'UPDATE_STAFF_STATE',
}

export default staffActionTypes;
