import { put, takeLatest } from 'redux-saga/effects';
import cognitiveSkillsActionTypes from '../types';
import { fetchCognitiveSkillsSuccess, fetchCognitiveSkillsFailure } from '../actions';
import httpClient from '../../../utils/http-client';
import { handleError } from '../../../utils/utils';

export function* fetchCognitiveSkills({ payload }) {
  try {
    const response = yield httpClient.get('/cognitive-skill-config', { params: payload });
    yield put(fetchCognitiveSkillsSuccess(response.data));
  } catch (error) {
    yield put(fetchCognitiveSkillsFailure(error?.message));
    const err = handleError(error);
  }
}

export function* onfetchCognitiveSkillsStart() {
  yield takeLatest(cognitiveSkillsActionTypes.FETCH_COGNITIVE_SKILLS_START, fetchCognitiveSkills);
}
